import React,{ useState , useEffect } from "react";
import { 
Grid ,
Card,
CardContent,
Typography,
} from "@mui/material";
import axios from "axios";

const Dashboard=()=>{

    let user = JSON.parse(sessionStorage.getItem("userType"));
    let id = user.id;
    let type = user.type;

    const [dashboardDetails , setDashboardDetails] = useState({
        subAdmins:0,
        customers:0,
        bookings:0,
        earnings:0,
        empApproved:0,
        empToBeApproved:0,
        hsptlApproved:0,
        hsptlToBeApproved:0,
        drvrApproved:0,
        drvrToBeApproved:0
    })


    useEffect(()=>{
        getDashboardDetails()
    } ,[])

    const getDashboardDetails = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}dashboard`,{type:type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 0){
                }else if(responce.data.status === 1){
                    let data = responce.data.dashboardData;
                    setDashboardDetails({
                        subAdmins:data.subAdminCount,
                        customers:data.customers,
                        bookings:data.bookings,
                        earnings:data.earningCount,
                        empApproved:data.employeesApproved,
                        empToBeApproved:data.employeesUnapproved,
                        hsptlApproved:data.hospitalApproved,
                        hsptlToBeApproved:data.hospitalUnapproved,
                        drvrApproved:data.driverApproved,
                        drvrToBeApproved:data.driverUnapproved
                    })


                }
            }
        ).catch((error) => {console.log(error)})
    }



    return(
        <>
            <Grid className={'outletBody'} sx={{px:3,py:2}}>
                <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                     <Typography sx={{fontSize:'18px'}}>Dashboard</Typography>
                </Grid>
                <Grid className={'outletDashboardBody'} container   rowSpacing={4} columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
                    {type === 1 &&
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#9E0142'}}>
                                <Typography fontWeight={700} sx={{ fontSize: 34}}  gutterBottom>    {dashboardDetails.subAdmins}</Typography>
                                <Typography sx={{ fontSize: 12 }}>Total sub admins</Typography>
                            </CardContent>
                        </Card>
                    </Grid>}
                    {(type === 1 || type === 2) && <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#D53E4F'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}}  gutterBottom>{dashboardDetails.empApproved}</Typography>
                                <Typography sx={{fontSize:12 }}>Total employees approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>}
                    {(type === 1 || type === 2) && <Grid item xs={3}>
                        
                        <Card className="cardShadow">
                            <CardContent className="cardPadding" sx={{textAlign:'center',color:'#F46D43'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}} gutterBottom>{dashboardDetails.empToBeApproved}</Typography>
                                <Typography sx={{fontSize:12}}>Total employees to be approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>}
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#FDAE61',fontWeight:'700'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}}  gutterBottom>{dashboardDetails.hsptlApproved}</Typography>
                                <Typography sx={{ fontSize: 12 }}>Total hospitals approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#5E4FA2'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}}  gutterBottom>{dashboardDetails.hsptlToBeApproved}</Typography>
                                <Typography sx={{fontSize: 12}}>Total hospitals to be approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#3288BD'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}}  gutterBottom>{dashboardDetails.drvrApproved}</Typography>
                                <Typography sx={{fontSize: 12}}>Total drivers approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#66C2A5'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}} gutterBottom>{dashboardDetails.drvrToBeApproved}</Typography>
                                <Typography sx={{fontSize: 12}}>Total drivers to be approved</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {(type === 1 || type === 2) &&  <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#ABDDA4'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}}  gutterBottom>{dashboardDetails.customers}</Typography>
                                <Typography sx={{fontSize: 12}}>Total customers</Typography>
                            </CardContent>
                        </Card>
                    </Grid>}
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#608BFB'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}} gutterBottom>{dashboardDetails.bookings}</Typography>
                                <Typography sx={{ fontSize: 12 }}>Total bookings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card className="cardShadow">
                            <CardContent sx={{textAlign:'center',color:'#FF4572'}}>
                                <Typography fontWeight={700} sx={{fontSize: 34}} gutterBottom>{dashboardDetails.earnings}</Typography>
                                <Typography sx={{fontSize: 12}}>Total earnings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            
        </>
    )
}
export default Dashboard


