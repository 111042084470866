import {React, useEffect, useState} from "react";
import { 
  Grid,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TextField ,
  Pagination,
  Stack
} from "@mui/material";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';

const NoAmbulance = () => {
    const [noAmbulanceData,setNoAmbulanceData] = useState([])
    const [page, setPage] = useState(0);
    const [nodata, setNodata] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [timerSetting, setTimerSetting] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("userType"));
    let id = user.id;
    let type = user.type;

    const getNoAmbulanceData=()=>{
     axios.get(`${process.env.REACT_APP_BASE_URL}getUnreachableUsers/0/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
            if(responce.data.status === 1){
                if(responce.data.details == ""){
                  setNodata(true)
                }
                else{
                  setNodata(false)
                }
                setNoAmbulanceData(responce.data.details)
                setTotalPages(responce.data.totalPages)
              }
              else {
                setNoAmbulanceData([])
              }
        })
    }

    // to handle pagination

     const handleChange = (event, value) => {
        setPage(value-1);
        axios.get(`${process.env.REACT_APP_BASE_URL}getUnreachableUsers/${value-1}/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {setNoAmbulanceData(responce.data.details)}
        ).catch((error) => {console.log(error)})
      };
  
    const table_row = noAmbulanceData.map((row , index) => <TableRow key={row.id}
    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >{}
    <TableCell align="center" style={{maxWidth: 100}} component="td" >{(page)*10+index+1}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.userName}>{row.userName}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.userPhone}>{row.userPhone}</TableCell>
    {row.bookingType === 2 ?<TableCell align="center" style={{maxWidth: 130}} component="td" title={"SOS"}>SOS</TableCell>:<TableCell align="center" style={{maxWidth: 130}} component="td" title={"General"}>General</TableCell>}
    <TableCell align="center" style={{maxWidth: 130}} component="p" title={row.fromLocation}>{row.fromLocation}</TableCell>
    
    </TableRow>);

    const searchCall = (text)=> {

      if(text === ""){
        setNodata(false);
        getNoAmbulanceData()
       }
       
      else{
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}unreachableUserSearch/0/10`,
        headers: {
          Authorization:process.env.REACT_APP_AUTHORIZATION,
        },
        data:{
          search:text
        }
      })
        .then((responce) => {
          if(responce.data.status === 0){
            setNodata(true)
        }else if(responce.data.status === 1){
            setNodata(false);
            setNoAmbulanceData(responce.data.details)
            setTotalPages(responce.data.totalPages)
        }
      })
        .catch((error) => {
          alert("Error occured");
          console.log(error);
        });
      }
      
    }
    const Search = (e) =>{
          clearTimeout(timerSetting);
         const newTimer = setTimeout(() => {
           searchCall(e.target.value)
         }, 500);
         setTimerSetting(newTimer);
    }
    
    useEffect(()=>{
        getNoAmbulanceData()
    },[])
    return(
        <>
          <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}}>No ambulance available</Typography>
                </Grid>
                <Grid>
              <TextField className="text_field" placeholder="search"
                onChange={(e)=>{Search(e)}}
                fullWidth InputProps={{
                  startAdornment: (
                <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                ),
              }}/>
          </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className='headerBg'>
                        <TableCell className='tabel_cell tableHead' align="center">S.NO</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">USER NAME</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">PHONE NUMBER</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">TYPE</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">ADDRESS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        nodata?<TableRow>
                        <TableCell colSpan={8} align="center">
                          <h2> No data found</h2>
                        </TableCell>
                        </TableRow>:table_row
                      }
                    </TableBody>                
                  </Table>
                </TableContainer>
               
            </Grid>
            <Grid className={'outletBodyFooter'} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                  </Stack>
                </Grid>
          </Grid>
        </>
    )

}
export default NoAmbulance