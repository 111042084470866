import React,{useEffect, useState} from "react"
import axios from "axios";
import { 
    Grid , 
    TableContainer, 
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead, 
    TableBody,
    Box,
    Button,
    Modal,
    TextField,
    FormLabel,
    Select,
    MenuItem
} from "@mui/material"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';

export const AdminPlansHistory = () => {
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId , setDeleteId] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [plan, setPlan] = useState("");
    const [planNames, setPlanNames] = useState([]);
    const [filterId,setFilterId] = useState("");
    const [serachData,setSearchData] = useState("");

    const handleCloseDelete = () => setOpenDelete(false);

    const ErrorClose = () => {
        setEmessage(false)
    }
     const SuccessClose=() => {
        setSmessage(false);
    }

    const handleOpenDelete = (id) => {
        setDeleteId(id)
        setOpenDelete(true);
    }
    const handleChangePlan = (event) => {
        setPlan(event.target.value);
        setFilterId(event.target.value)
        gettingUserSubscriptionList()
    }
    const handleSearchData = (event) => {
      setSearchData(event.target.value)
      gettingUserSubscriptionList()
    }
    const DeleteStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline:'none',
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
    };

    const GetPlans = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getPlanNames`, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
            .then((response) => {
                setPlanNames(response.data.getPlanNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const TotalPlans = planNames.map((plan, index) => (
        <MenuItem key={index} value={plan._id}>{plan.name}</MenuItem>
    ));

    const handleChange = (event, value) => {
        const payload = {
            page: value-1,
            size: 10,
            type:1
        }
        setPage(value-1);
        axios.post(`${process.env.REACT_APP_BASE_URL}userSubscriptionList/`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setSubscriptionList(responce.data.details)}
        ).catch((error) => {console.log(error)})
    };

    const gettingUserSubscriptionList = async () => {
        const payload = {
            page: 0,
            size: 10,
            type:1
        }
        if (filterId !== "") {
            payload.filterBy = filterId;
        }
        if (serachData !== "") {
            payload.searchBy = serachData;
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userSubscriptionList`, payload, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } }).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSubscriptionList(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                    // setSmessage(true)
                    // setSuccessMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const TableBodyData = subscriptionList.map((row, index) => (
            <TableRow key={row.id}>
                <TableCell align="center">{(page)*10+index+1}</TableCell>
                <TableCell align="center" title={row.userName}>{row.userName}</TableCell>
                <TableCell align="center" title={row.phone}>{row.phone}</TableCell>
                <TableCell align="center" title={row.userEmail}>{row.userEmail}</TableCell>
                <TableCell align="center" title={row.planName}>{row.planName}</TableCell>
                <TableCell align="center" title={row.subscriptionExpiry}>{row.subscriptionExpiry}</TableCell>
                <TableCell align="center">
                    <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}} onClick={() => {handleOpenDelete(row._id)}}/>
                </TableCell>
            </TableRow>
        
    ))
    const Delete = () =>{
        const payload = {
            id: deleteId
        }
        axios.delete(`${process.env.REACT_APP_BASE_URL}deleteUserSubscription`, {data: payload,
            headers: {
                Authorization: process.env.REACT_APP_AUTHORIZATION
            },
            
        }).then(responce => {
                if(responce.data.status === 1){
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setOpenDelete(false)
                    gettingUserSubscriptionList();
                }else{
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }

useEffect(() => {
    gettingUserSubscriptionList();
    GetPlans()
}, [])

useEffect(() => {
    gettingUserSubscriptionList();
    GetPlans()
}, [filterId])

    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}} >Plan History</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                    <Grid item md={6} sx={{marginRight:'20px'}}>
                        {/* <FormLabel>Plan</FormLabel> */}
                        <Select
                            disableUnderline
                            className='selectOptions'
                            displayEmpty
                            fullWidth
                            value={plan}
                            placeholder="Select plan"
                            onChange={handleChangePlan}
                        >
                            <MenuItem value="">
                                <em>Select Plan</em>
                            </MenuItem>
                            {TotalPlans}
                        </Select>
                    </Grid>
                    <Grid sx={{marginRight:'20px'}}>
                        <TextField className="text_field" placeholder="search"
                         onChange={handleSearchData} 
                         fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                          ),
                        }}/>
                    </Grid>
                    
                    
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'} sx={{paddingTop:'3rem'}}>
                <TableContainer className="outletBodyTableContainer">
                    <Table  stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">USER NAME</TableCell>
                                <TableCell className="tableHead">PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">SUBSCRIPTION PLAN</TableCell>
                                <TableCell className="tableHead">EXPIRE DATE</TableCell>
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscriptionList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <h2>No data Found</h2>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                TableBodyData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
        </Grid> 
        <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                 Do you want to delete ?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" 
                onClick={Delete} 
                >Yes</Button>
                <Button className="noButton" onClick={handleCloseDelete}>No</Button>
            </Grid>
        </Box>
    </Modal>
    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
        </>
    )
}