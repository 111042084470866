import React from "react";
import Header from "../../Layouts/header";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import SideNav from "../../Layouts/sidenav";
import { Outlet } from "react-router-dom";

//const Outlet=useOutlet()


const Pharmacy=()=>{
    return(
        <>
            <Grid className="adminLayout">
            <Grid className="headerHeight">
                <Header/>
            </Grid>
            <Grid className="bodyHeight">
                <Container className="widthSet">
                    <Grid container sx={{pt:4}} className="listItem_pad">
                        <Grid item className="listItem_pad" md={2}>
                            <SideNav/>
                        </Grid>
                        <Grid item  className="outLetHeight dashboard_bg" md={10}>
                            <Outlet />
                        </Grid>
                        
                    </Grid>
                </Container>
            </Grid>
          </Grid>

        </>
    )
}

export default Pharmacy;