import React, { useEffect, useState } from "react"
import axios from "axios";
import { Grid,Button,FormLabel,TextField,Select,MenuItem, FormControl } from "@mui/material"
import Container from '@mui/system/Container';
import Icu from '../../../Assets/icons/icu.png';
import { useForm ,Controller} from 'react-hook-form';
import { Typography } from "ui-neumorphism";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'react-lottie'
import AlertJson from '../../../Assets/icons/alert.json'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export const PreBooking = () => {
    
    const [hrs, setHrs] = useState(0);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [districts , setDistricts] = useState([]);
    const [states , setStates] = useState([]);
    const [cities , setCities] = useState([]);
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const {register,handleSubmit,reset,formState: { errors },setValue,control} = useForm();
    
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [logOutopen , setLogOutopen] = useState(false)
    const [logCheck , setLogCheck] = useState("")
    const [logError , setLogError] = useState(false);
    const authKey = localStorage.getItem("Key");
    const [secureKey , setSecureKey] = useState("")
    
    const navigate =  useNavigate();
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
      }
      const ErrorClose = () => setEmessage(false);
      const SuccessClose=() => setSmessage(false);
        const handleClose = () => setOpen(false);
        const LogOuthandleClose = () => {setLogOutopen(false)}
        const showLogInMsg = () => {
            console.log("Please Login First")
            logCheck && setLogError(true)
        }
        const logErrorhandleClose = () => {
            setLogError(false)
        }
    const handleHrsChange = ({ target: { value } }) => {
        setHrs(value);
        setValue("duration", value, { shouldValidate: true });
    }

    const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:userlogCheck?.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
        }
        const statehandleChange = (event) => {
            setState(event.target.value);
            setDistrict('')
            setCity('')
            axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:userlogCheck?.id,stateId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                    setDistricts(responce.data.result)
                    }else{
                        setDistricts([])
                    }
                }
            ).catch((error) => {console.log(error)})
         };
         const districthandleChange = (event) => {
            setDistrict(event.target.value);
            setCity('')
            axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:userlogCheck?.id,districtId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        setCities(responce.data.result)
                    }else{
                        setCities([])
                    }
                }
            ).catch((error) => {console.log(error)})
        };
        const cityhandleChange = (event) => {
            setCity(event.target.value);
        
        };

        const stateOptions = states.map(menuItem =><MenuItem  key={menuItem._id} value={menuItem._id}>{menuItem.state}</MenuItem>)
        const DistrictOptions = districts.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.district}</MenuItem>)
         const citiesOptions = cities.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.city}</MenuItem>)


         const onSubmit = (data) => {
            const selectedDate = data.date;
            let payload ;
            if (selectedDate && typeof selectedDate.toDate === 'function') {
                const dateObject = selectedDate.toDate();
                const year = dateObject.getFullYear();
                const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
                const day = dateObject.getDate();
        
                 payload = {
                    name: data.name,
                    email: data.email,
                    mobile: data.phone,
                    date: `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`,
                    duration: hrs,
                    state: state,
                    district: district,    
                    city: city,
                    booked_by: userlogCheck?.id
                };
        
            }
            setLoaderOpen(true);
            
            axios.post(`${process.env.REACT_APP_BASE_URL}addPreBooking/`, payload, {
                headers:{Authorization:localStorage.getItem("Key")}
             }).then((response) => {
                    if(response.data.status === 1){
                        setLoaderOpen(false);
                        setOpen(true);
                        setSmessage(true);
                        setSuccessMsg(response.data.message)
                        reset();
                        setCity("");
                        setDistrict("");
                        setState("");
                        setHrs(0);
                        reset({ 
                            date: null 
                        })  
                    }else{
                        setLoaderOpen(false);
                        setOpen(true);
                        setEmessage(true)
                        setErrorMsg(response.data.message)
                    }
                })
                .catch((error) => {if(error.response.status === 401){
                    setErrorMsg("Your acoount was logged in from another device")
                    setEmessage(true)
                    setTimeout(() => {
                        userLogOut();
                    }, 2000);
                    
                }})
            }

            const userLogOut = () =>{
                sessionStorage.removeItem("userType");
                localStorage.removeItem("latitude");
                localStorage.removeItem("longitude");
                setLogOutopen(false);
                navigate('/homepage');
                window.location.reload();
            }
            const logging = () =>{
                if(userlogCheck !== null){
                    setLogCheck(false);
                    if(authKey !== null){
                      setSecureKey(authKey);
                    }
                }else{
                    setLogCheck(true);
                }
            }
    useEffect(() => {
        allStates();
        logging();
    }, [])
    return(
        <>
        <Grid className='aboutBg' py={5}>
            <Container className="widthSet"> 
                <Grid component={'h2'} pb={3} className="subscriptionText">Pre booking</Grid>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item md={6} lg={6}>
                        <img src={Icu} alt="ambulance" className="ambulanceImg"/>
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Grid component={'form'} onSubmit={handleSubmit(onSubmit)}>
                            <Grid md={12} container columnSpacing={5}>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Full Name</FormLabel>
                                            <TextField 
                                            fullWidth 
                                            className={'userTextField'} 
                                            type={'text'}
                                            // disabled={logCheck}
                                            onClick={showLogInMsg}
                                            autoComplete="off"
                                            placeholder={'Enter Full Name'}
                                            {...register('name',{required: true})} 
                                            />
                                        {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Email</FormLabel>
                                        <TextField 
                                        className={'userTextField'}
                                        placeholder={'Enter Email'} 
                                        {...register('email',{required: true, pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                        onClick={showLogInMsg}
                                        autoComplete="off"
                                        />
                                        {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter Email </p>}
                                        {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>Please enter valid Email</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Phone Number</FormLabel>
                                        <TextField  
                                        className={'userTextField'}
                                        placeholder={'Enter Phone Number'} 
                                        {...register('phone',{required: true,pattern:/^\d{10}$/})} 
                                        onClick={showLogInMsg}
                                        autoComplete="off"
                                        />
                                        {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                        {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>State</FormLabel>
                                        <Grid>
                                        <Select 
                                            fullWidth 
                                            className='preUserSelectOption'
                                            value={state}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{'aria-label': 'Without label'}}
                                            {...register('state',{required: true})}
                                            onChange={statehandleChange}
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select State</em>
                                            </MenuItem>
                                            {stateOptions}
                                        </Select>
                                    </Grid>
                                    
                                    </FormControl>
                                    {state.length === 0 &&errors.state &&
                                     <Typography className='error'>please select state</Typography>
                                     }
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>District</FormLabel>
                                        <Grid>
                                        <Select 
                                            fullWidth 
                                            className='preUserSelectOption'
                                            value={district}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{'aria-label': 'Without label'}}
                                            {...register('district',{required: true})}
                                            onChange={districthandleChange}
                                           
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select District</em>
                                            </MenuItem>
                                            {DistrictOptions}
                                        </Select>
                                    </Grid>
                                    
                                    </FormControl>
                                    {district.length === 0 &&errors.district &&
                                     <Typography className='error'>please select district</Typography>
                                     }
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>City</FormLabel>
                                        <Grid>
                                        <Select 
                                            fullWidth 
                                            className='preUserSelectOption'
                                            value={city}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{'aria-label': 'Without label'}}
                                            {...register('city',{required: true})}
                                            onChange={cityhandleChange}
                                           
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select City</em>
                                            </MenuItem>
                                            {citiesOptions}
                                        </Select>
                                    </Grid>
                                    
                                    </FormControl>
                                    {city.length === 0 &&errors.city &&
                                     <Typography className='error'>please select city</Typography>
                                     }
                                </Grid>
                                <Grid item  md={12} lg={6} mt={3} width={'100%'}>
                                    <FormLabel className={'userFormLabel'}>Calender</FormLabel>
                                    <Controller
                                        name="date"
                                        control={control}
                                        
                                        rules={{ required: 'Please select date' }}
                                        render={({ field }) => (
                                            <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']} sx={{ overflowX: 'hidden', overflowY: 'hidden', padding: '0px' }}>
                                                <div onClick={showLogInMsg}>
                                                <DatePicker 
                                                {...field} 
                                                className="preUserSelectOption userdatePicker"
                                                minDate={dayjs()} 
                                                 />
                                                </div>
                                                
                                            </DemoContainer>
                                            </LocalizationProvider>
                                        )}
                                        />
                                    {errors.date && errors.date.type === "required" && <p className='error mt-0'>Please select date</p>}

                                </Grid>
                                <Grid item md={12} lg={6} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Hours</FormLabel>
                                        <Select 
                                            fullWidth 
                                            className='preUserSelectOption'
                                            value={hrs}
                                            displayEmpty
                                            disableUnderline
                                           
                                            onClick={showLogInMsg}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            {...register("duration", {
                                                required: { value: true, message: "duration is required" },
                                                validate: (value) => value !== "0" || "Please select a duration",
                                              })}
                                            onChange={handleHrsChange}
                                        >
                                            <MenuItem disabled className='optoins' value="0">
                                                <em>Select hrs</em>
                                            </MenuItem>
                                            <MenuItem value={1}>1 hr</MenuItem>
                                            {[...Array(23).keys()].map((hour) => ( 
                                                <MenuItem key={hour + 2} value={hour + 2}>{hour + 2} hrs</MenuItem>
                                            ))}
                                        </Select>
                                        {errors.duration  && <p className='error mt-0'>Please select hour</p>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                        {logCheck?(<Button className='userBuyButton' size="medium" onClick={showLogInMsg} >Book</Button>):(<Button className='userBuyButton' type="submit" size="medium"  >Book</Button>)}
                    
                                <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loaderOpen}
                                onClick={handleClose}
                                invisible={true}
                              >
                              <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                              />
                              </Backdrop>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </Grid>
        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error" className="snackError">
          {errorMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={logError} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }}   onClose={logErrorhandleClose} >
            <Alert onClose={logErrorhandleClose} severity="error" className="snackError">
                Please login for booking
            </Alert>
    </Snackbar>
        </>
    )
}