import React,{useEffect, useState} from "react"
import axios from "axios";
import { 
    Grid , 
    TableContainer, 
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead, 
    TableBody,
    Box,
    Button,
    Modal,
    TextField,
    FormLabel,
    Backdrop,
} from "@mui/material"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AlertJson from './../../Assets/icons/alert.json'
import Lottie from 'react-lottie'
import { useForm } from 'react-hook-form';
export const PharmacyCustomers = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [ClaimId , setClaimId] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [serachData,setSearchData] = useState("");
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [discount,setDiscount] = useState(0)
    const [totalAmount, setTotalAmount] = useState('');
    const [purchasedAmount, setPurchasedAmount] = useState('');
    const handleClose = () => setOpenModal(false);

    const user = JSON.parse(sessionStorage.getItem("userType"));
    const ErrorClose = () => {
        setEmessage(false)
    }
     const SuccessClose=() => {
        setSmessage(false);
    }

    const handleOpenModal = (id) => {
        setClaimId(id)
        setOpenModal(true);
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
       }
    const handleSearchData = (event) => {
      setSearchData(event.target.value)
      gettingUserSubscriptionList()
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: '#F1F3F6 !important',
        border: '2px solid white !important',
        boxShadow: 24,
        outline:'none',
        p: 4,
        borderRadius:'30px'
    };




    const handleChange = (event, value) => {
        setPage(value-1);
        axios.post(`${process.env.REACT_APP_BASE_URL}userSubscriptionList/${value-1}/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setSubscriptionList(responce.data.details)}
        ).catch((error) => {console.log(error)})
    };

    const gettingUserSubscriptionList = async () => {
        const payload = {
            page: 0,
            size: 10,
            type:0
        }
        if (serachData !== "") {
            payload.searchBy = serachData;
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userSubscriptionList`, payload, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } }).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSubscriptionList(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const TableBodyData = subscriptionList.map((row, index) => (
            <TableRow key={index}>
                <TableCell align="center">{index+1}</TableCell>
                <TableCell align="center" title={row.userName}>{row.userName}</TableCell>
                <TableCell align="center" title={row.phone}>{row.phone}</TableCell>
                <TableCell align="center" title={row.userEmail}>{row.userEmail}</TableCell>
                <TableCell align="center">
                    <Button className="notificationView" onClick={() => {handleOpenModal(row.userId)}}>Claim</Button>
                </TableCell>
            </TableRow>
        
    ))
    const addData = (data) =>{
        const purchasedAmount = parseInt(data.purchasedAmount);
        // const totalAmount = parseInt(data.totalAmount);
        const payload = {
            userId: ClaimId,
            id: user.id,
            purchaseAmount: purchasedAmount,
            totalAmount: totalAmount,
            type:"pharmacy",

        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userClaim`,  payload, {headers: {
                Authorization: process.env.REACT_APP_AUTHORIZATION
            }},
            
        ).then(responce => {
            if(responce.data.status === 1){
                setSuccessMsg(responce.data.message)
                setSmessage(true)
                gettingUserSubscriptionList()
                setOpenModal(false)
                reset()
                setTotalAmount('')
            }else if(responce.data.status === 0){
                setErrorMsg(responce.data.message)
                setEmessage(true)
               
            }
    }).catch((error) => {console.log(error)})
    }

useEffect(() => {
    gettingUserSubscriptionList();
}, [])

useEffect(() => {
    if( serachData !== null){
        gettingUserSubscriptionList();
    }
}, [serachData])

useEffect(() => {
    if(openModal){
        setDiscount(user.discount)   
    }
    
},[openModal])

useEffect(() => {
    const discountedAmount = purchasedAmount - (purchasedAmount * discount) / 100;
    setTotalAmount(discountedAmount);
}, [purchasedAmount, discount]);


    return(
        <>
         
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}} >Customers</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                    <Grid sx={{marginRight:'20px'}}>
                        <TextField className="text_field" placeholder="search"
                         onChange={handleSearchData} 
                         fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                          ),
                        }}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'} sx={{paddingTop:'3rem'}}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">User Name</TableCell>
                                <TableCell className="tableHead">Phone Number</TableCell>
                                <TableCell className="tableHead">Email</TableCell>
                                <TableCell className="tableHead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscriptionList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <h2>No data Found</h2>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                TableBodyData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                </Stack>
            </Grid>
        </Grid> 
    
              
<Modal
  open={openModal}
  // onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description">
    <Box sx={style}>
      <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
      <Grid container direction="row" justifyContent="center" alignItems="center" id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography className="titleName" id="modal-modal-title" variant="h6" align="center" component="h2">
                Amount Details
            </Typography>
        <Grid container justifyContent='center'>
        
          <Grid md={10} item sx={{mt:2}}  >
            <FormLabel>Purchased Amount</FormLabel>
            <TextField name="purchasedAmount" type="number"  className={'text_field'} {...register('purchasedAmount',{required: true})} onChange={(e)=>{setPurchasedAmount(e.target.value)}}/>
            {errors.purchasedAmount && errors.purchasedAmount.type === "required" && <p className='error mt-0'>Please enter purchased amount</p>}
          </Grid>
          <Grid md={10} item sx={{mt:2}}  >
            <FormLabel>Discount</FormLabel>
            <TextField sx={{mt:2}} name="discount" disabled className={'viewBox'} value={discount+'%'} fullWidth/>
          </Grid>
          <Grid item md={10} sx={{mt:2}}>
            <FormLabel>Total Amount</FormLabel>
            <TextField name="totalAmount" value={totalAmount} type= "number" className={'text_field'} {...register('totalAmount',{required: true})}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' sx={{my:3}}>
        <Button className="customerLogin" bgColor='#129547' onClick={handleSubmit(addData)}>Add</Button>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
            invisible={true}
          >
            <Lottie 
              options={defaultOptions}
              height={100}
              width={100}
            />
          </Backdrop>
      </Grid>
    </Box>
</Modal>
    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
        </>
    )
}