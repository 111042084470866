import  React,{ useEffect , useState } from 'react';
import { 
  Grid ,
  Box,
  Typography,
  TextField,
  Button,
  FormLabel,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Stack,
  Select,
  Alert,
  Snackbar,
  MenuItem,
  Backdrop,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from "axios";
import './ambulance.css'
import { useForm } from 'react-hook-form';
import PriseEdit from '../../../Assets/icons/basepriseedit.svg'
import Lottie from 'react-lottie'
import AlertJson from '../../../Assets/icons/alert.json'

const TypePrice = () => {


    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [data ,setData] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const handleOpen = () =>{
      setopenModal(true);
      setState("")
      setDistrict("")
    } 
    const handleClose = () => setopenModal(false);
    const [openModalView, setopenModalView] = useState(false);
    const [buttonDis , setButtonDis] = useState('none');
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));

    const [priceValues, setPriceValues] = useState({
      district:'',
      icuBaseFairKm: '',
      icuBaseFair: '',
      icuPricePerKm: '',
      semiIcuBaseFairKm: '',
      semiIcuBaseFair: '',
      semiIcuPricePerKm: '',
      GeneralBaseFairKm: '',
      GeneralBaseFair: '',
      GeneralPricePerKm: '',
      
   });
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] =useState();
    const[nodata , setNodata] = useState(false);
    const[states , setStates] = useState([])
    const[district , setDistrict] = useState("");
    const[state , setState] = useState("")
    const [districts , setDistricts] = useState([]);
    const {register,handleSubmit,formState: { errors } ,reset} = useForm();
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const[smessage , setSmessage] =useState(false);
    const[emessage , setEmessage] =useState(false);
    const[makeEditOpt , setMakeEditOpt] = useState(true);
    const[makeEditCls , setMakeEditcls] = useState('textField')
    const [edit,setEdit]=useState(true)
    const handleOpenView = (id) => {
      setMakeEditOpt(true);
      setMakeEditcls('textField');
      setButtonDis('none')
      setEdit(true)
      axios.get(`${process.env.REACT_APP_BASE_URL}getPriceByDistrict/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.priceData){
            let respData = responce.data.priceData[0];
            setPriceValues({ ...priceValues , district: respData._id, icuBaseFairKm: respData.icubasefarekm , icuBaseFair: respData.icubasefare , icuPricePerKm: respData.icuperkm , semiIcuBaseFairKm: respData.semiicubasefarekm , semiIcuBaseFair: respData.semiicubasefare , semiIcuPricePerKm: respData.semiicuperkm ,GeneralBaseFairKm: respData.generalbasefarekm , GeneralBaseFair: respData.generalbasefare , GeneralPricePerKm: respData.generalperkm ,})
          }
      }
      ).catch((error) => {console.log(error)})
      setopenModalView(true);
    
    }
    const changeHandler = (e) => {
      let{name,value} =e.target
      setPriceValues({...priceValues, [name]:value});
    }

    const updatePrice = () =>{
        setMakeEditOpt(true);
        setMakeEditcls('textField');
        axios.post(`${process.env.REACT_APP_BASE_URL}editBaseFare`,{district:priceValues.district, icukm:priceValues.icuBaseFairKm , icufare:priceValues.icuBaseFair ,icuperkm:priceValues.icuPricePerKm , semiicukm:priceValues.semiIcuBaseFairKm , semiicufare:priceValues.semiIcuBaseFair , semiicuperkm:priceValues.semiIcuPricePerKm , basickm:priceValues.GeneralBaseFairKm , basicfare:priceValues.GeneralBaseFair , basicperkm:priceValues.GeneralPricePerKm },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {let status = responce.data.status;
            if(status === 1){
              setSmessage(true);
              setSuccessMsg(responce.data.message)
              setEdit(true)
            }else if(status === 0){
              setMakeEditOpt(false);
              setMakeEditcls('');
              setButtonDis('block');
                setEmessage(true)
                setErrorMsg(responce.data.message)
            }}
        ).catch((error) => {console.log(error)})
        setButtonDis('none')
    }

    const handleCloseView = () => setopenModalView(false);
    
    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 4% 0% 4%',
        outline:0,
        borderRadius:'10px'
      };
      const priceStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 4% 2% 4%',
        outline:0,
        borderRadius:'10px'
      };
      const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
      }
      const stateChange = (event) => {
        setState(event.target.value)
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event.target.value},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                }else{
                    setDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
      }
      const districtChange = (event) => {
        setDistrict(event.target.value);
      };

      const loadTypePriceTable = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getPriceList/0/10`,{id:userlogCheck.id , type:userlogCheck.type },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
                if(responce.data.details === ""){
                  setNodata(true)
                }else{
                  setNodata(false)
                }
                setData(responce.data.details)
                setTotalPages(responce.data.totalPages)
            }else if(responce.data.status === 0){
              setData([])
              setNodata(true)
            }
          }
      ).catch((error) => {console.log(error)})
      }

      useEffect(() => { 
        loadTypePriceTable();
        allStates();
    },[]);
    useEffect(() => { reset() },[reset]);

    const handleChange = (event, value) => {
      setPage(value-1);
    axios.post(`${process.env.REACT_APP_BASE_URL}getPriceList/${value-1}/10`,{id:userlogCheck.id , type:userlogCheck.type },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {setData(responce.data.details)}
      ).catch((error) => {console.log(error)})
      };
    
      const ErrorClose = () => {
        setEmessage(false)
      }

      const SuccessClose=() => {
        setSmessage(false);
      }

      const makeEdit = () =>{
        setMakeEditOpt(false);
        setMakeEditcls('');
        setButtonDis('block');
        setEdit(false)
      }

    const table_row = data.map((row , index) =>
    <>
    <TableRow>
      <TableCell component="th" scope="row" align="center">{(page)*10+index+1}</TableCell>
      <TableCell align="center">{row.district}</TableCell>
      <TableCell align="center">{row.icuCount}</TableCell>
      {/* <TableCell align="center">{row.semiIcuCount}</TableCell> */}
      <TableCell align="center">{row.generalCount}</TableCell>
      {/* {(userlogCheck.type === 1 || userlogCheck.type === 2) &&  */}
      <TableCell>
        <Button className="notificationView"  onClick={()=>{handleOpenView(row._id)}}>Price</Button>
      </TableCell>
      {/* } */}
  </TableRow>


</>);


const searchFilter = (e) =>{
  if(e === ""){
    setNodata(false)
    loadTypePriceTable();
  }else{
    axios.post(`${process.env.REACT_APP_BASE_URL}searchPriceList/0/10`,{ search:e },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 0){
            setNodata(true)
        }else if(responce.data.status === 1){
            setNodata(false);
          setData(responce.data.details)
          setTotalPages(responce.data.totalPages)
        }
      }
  ).catch((error) => {console.log(error)})
  }
  
}

const [loaderOpen, setLoaderOpen] = React.useState(false);

        const defaultOptions = {
          loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
        }


const onSubmit =  (Form_data) => {
  setLoaderOpen(true)
  axios.post(`${process.env.REACT_APP_BASE_URL}addBaseFare`,{district:Form_data.district , state:Form_data.state , icukm:Form_data.icuBaseFareKm, icufare:Form_data.icuBaseFare, icuperkm:Form_data.icuPricePerKm, semiicukm:Form_data.SemiicuBaseFareKm, semiicufare:Form_data.SemiicuBaseFare, semiicuperkm:Form_data.SemiicuPricePerKm, basickm:Form_data.genBaseFareKm, basicfare:Form_data.genBaseFare, basicperkm:Form_data.genPricePerKm},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
    let status = res.data.status;
    if(status === 1){
      setLoaderOpen(false)
      setSmessage(true);
      setSuccessMsg(res.data.message)
      setOpen(false);
      loadTypePriceTable();
      handleClose();
      reset();
    }else if(status === 0){
      setLoaderOpen(false)
        setEmessage(true)
        setErrorMsg(res.data.message)
    }

  }).catch((error) => {console.log(error)})
  }

      const DistrictOptions = districts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
      const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)

 
  return (
  <>

    <Grid className={'outletBody'} sx={{px:3,py:2}}>
      <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid> 
            <Typography sx={{fontSize:'18px'}} >Type - Price</Typography>
        </Grid>
            <Grid sx={{ display: 'flex' , mb:1 }}>
                {(userlogCheck.type === 1 || userlogCheck.type === 2) && <Grid>
                    <TextField className="text_field" placeholder="search"
                    onChange={e => {searchFilter(e.target.value)}}  fullWidth InputProps={{
                        startAdornment: (
                        <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                       
                        ),
                    }}/>
                </Grid>}
                {(userlogCheck.type === 1 || userlogCheck.type === 2) &&
                    <Grid  sx={{ display: 'flex',alignItems:'center',mx:3 }}>
                        <Typography className="add_text">Add </Typography>
                        <AddCircleIcon className="Add_icon"  onClick={handleOpen}  />
                    </Grid>
                }
                 </Grid>
      </Grid>
      <Grid className={'outletBodyBody'}>
          <TableContainer className="outletBodyTableContainer">
            <Table className='pricetable' stickyHeader aria-label="sticky table" >
              <TableHead>
                <TableRow className='headerBg'>
                  <TableCell className="tableHead" align="center">S.NO</TableCell>
                  <TableCell className="tableHead" align="center">DISTRICT</TableCell>
                  <TableCell className="tableHead" align="center">ICU</TableCell>
                  {/* <TableCell className="tableHead" align="center">SEMI ICU</TableCell> */}
                  <TableCell className="tableHead" align="center">GENERAL</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  nodata?<TableRow>
                  
                  <TableCell colSpan={8} align="center">
                      <h2> No Price Data Found</h2>
                  </TableCell>
                
                  </TableRow>:table_row
                  }
              </TableBody>
          </Table>
        </TableContainer>
        
      </Grid>
      <Grid className={'outletBodyFooter'} sx={{pt:1}} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
            <Stack spacing={2}>
                <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
            </Stack>
        </Grid>
    </Grid>

    {/* modal */}
    <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={style}>
            <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
            <Grid>
              <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                Add Base Fare Price
              </Typography>
            </Grid>
            <Box sx={{ width: '100%',mt:5 }} className="modalscroll">
              <Grid container className="modalscrollwidth"  >
                <Grid container item md={12} justifyContent='center' alignItems="center">
                    <Grid sx={{textAlign:"center"}}  item md={4}   >
                      <FormLabel >State</FormLabel>
                      </Grid>
                      <Grid item md={8}>
                        <Select disableUnderline
                            className='selectOptions'
                            fullWidth
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            displayEmpty
                            value={state}
                            {...register('state',{required: true})}
                            onChange={stateChange} >
                            <MenuItem value="">
                              <em>Select State</em>
                            </MenuItem>
                              {stateOptions}
                        </Select>
                    </Grid>
                    {errors.state && state.length === 0 && <p className='error mt-0'>Please enter state</p>}
                </Grid>
                <Grid sx={{mt:3}} container item md={12} justifyContent='center' alignItems="center">
                    <Grid sx={{textAlign:"center"}}  item md={4}   >
                      <FormLabel >District</FormLabel>
                    </Grid>
                    <Grid item md={8}>
                      <Select disableUnderline
                            className='selectOptions'
                            fullWidth
                            defaultValue={30}
                            displayEmpty
                            value={district}
                            {...register('district',{required: true})}
                            onChange={districtChange} >
                            <MenuItem value="">
                              <em>Select District</em>
                            </MenuItem>
                            {DistrictOptions}
                      </Select>
                    </Grid>
                    {errors.district && district.length === 0 && <p className='error mt-0'>Please enter district</p>}
                </Grid>
                <Grid container item md={12} sx={{my:3}} justifyContent='center'              alignItems="center" >
                    <Grid  item md={4}>
                      {/* <FormLabel>Driver name</FormLabel> */}
                    </Grid>
                    <Grid  item md={2.5}>
                      <Typography className="price_base">BASE FARE KM</Typography>
                    </Grid>
                    <Grid item md={2.5}>
                      <Typography className="price_base">BASE FARE</Typography>
                    </Grid>
                    <Grid item md={2.5}>
                      <Typography className="price_base">PRICE PER KM</Typography>
                    </Grid>
                </Grid>
                <Grid container item md={12} alignItems="center" rowSpacing={1} columnSpacing={5}>
                    <Grid sx={{textAlign:"center"}}  item md={4}   >
                      <FormLabel className='lableName'>ICU</FormLabel>
                    </Grid>
                    <Grid item md={2.5}>
                    <TextField name="icuBaseFareKm" type={'number'} className={'text_field'} {...register('icuBaseFareKm',{required: true})}
                    onWheel={(e) => e.target.blur()}
                    />
                    {errors.icuBaseFareKm && errors.icuBaseFareKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}>
                    <TextField  className={'text_field'} type={'number'} {...register('icuBaseFare',{required: true})}
                    onWheel={(e) => e.target.blur()}
                    />
                    {errors.icuBaseFare && errors.icuBaseFare.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}>
                    <TextField  className={'text_field'} type={'number'} {...register('icuPricePerKm',{required: true})}
                    onWheel={(e) => e.target.blur()}
                    />
                    {errors.icuPricePerKm && errors.icuPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
              </Grid>
              {/* <Grid container item md={12} alignItems="center" rowSpacing={1} columnSpacing={5}>
                    <Grid sx={{textAlign:"center"}} item md={4} >
                      <FormLabel className='lableName'>Semi ICU</FormLabel>
                    </Grid>
                    <Grid item md={2.5}>
                        <TextField  className={'text_field'} type={'number'} {...register('SemiicuBaseFareKm',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.SemiicuBaseFareKm && errors.SemiicuBaseFareKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}>
                        <TextField  className={'text_field'} type={'number'} {...register('SemiicuBaseFare',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.SemiicuBaseFare && errors.SemiicuBaseFare.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}>
                        <TextField  className={'text_field'} type={'number'} {...register('SemiicuPricePerKm',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.SemiicuPricePerKm && errors.SemiicuPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
              </Grid> */}
                <Grid container item md={12} alignItems="center" rowSpacing={1} columnSpacing={5}>
                    <Grid sx={{textAlign:"center"}}  item md={4}>
                        <FormLabel className='lableName'>General</FormLabel>
                    </Grid>
                    <Grid item md={2.5}   >
                        <TextField  className={'text_field'} type={'number'} {...register('genBaseFareKm',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.genBaseFareKm && errors.genBaseFareKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}   >
                        <TextField  className={'text_field'} type={'number'} {...register('genBaseFare',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.genBaseFare && errors.genBaseFare.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                    <Grid item md={2.5}   >
                        <TextField  className={'text_field'} type={'number'} {...register('genPricePerKm',{required: true})}
                        onWheel={(e) => e.target.blur()}
                        />
                        {errors.genPricePerKm && errors.genPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                    </Grid>
                  </Grid>
              </Grid>
              <Grid container justifyContent='center'>
                  <Grid item sx={{my:3}} >
                    <Button  className="customerLogin" bgColor='#129547' onClick={handleSubmit(onSubmit)}>Add</Button>
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loaderOpen}
                      onClick={handleClose}
                      invisible={true}
                    >
                      <Lottie 
                        options={defaultOptions}
                        height={100}
                        width={100}
                      />
                    </Backdrop>
                  </Grid> 
              </Grid>
            </Box>
          </Box>
      </Modal>
      <Modal
        open={openModalView}
        // onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
           <Box sx={priceStyle}>
                          <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
                          <Grid container justifyContent={'center'} alignItems="center">
                              <Grid item md={2}></Grid>
                              <Grid item>
                                   <Typography className="titleName"  align="center" variant="h4"  component="div">
                                   Base Fare Price
                                   </Typography>
                              </Grid>
                               {(userlogCheck.type === 1 || userlogCheck.type === 2) && <Grid>
                              {edit && <Grid ml={5} item md={12} onClick={makeEdit} className="editBtn" >
                                  <img src={PriseEdit} alt="icon"/>&nbsp;&nbsp;&nbsp;&nbsp;Edit
                               </Grid>
                               
                              }
                              </Grid>
                              }
                          </Grid>
                            <Box sx={{ width: '100%',mt:5 }}>
                              <Grid container item md={12} sx={{my:3}} justifyContent='center' alignItems="center" >
                                  <Grid item md={4}>
                                    {/* <FormLabel>Driver name</FormLabel> */}
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <Typography className="price_base">BASE FARE KM</Typography>
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <Typography className="price_base">BASE FARE</Typography>
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <Typography className="price_base">PRICE PER KM</Typography>
                                  </Grid>
                              </Grid>
                              <Grid container  item md={12} alignItems="center" rowSpacing={3} columnSpacing={5}>
                                    <Grid sx={{textAlign:"center"}}  item md={4}   >
                                      <FormLabel className='lableName'>ICU</FormLabel>
                                    </Grid>
                                    <Grid  item md={2.5}>
                                      <TextField name="icuBaseFairKm" type={'number'} value={priceValues.icuBaseFairKm} 
                                      className={`text_field ${makeEditCls}`}
                                      // {...register('icuBaseFairKm',{required: true})}
                                      onChange={changeHandler}
                                      disabled={makeEditOpt}
                                      />
                                    
                                    </Grid>
                                    <Grid  item md={2.5}>
                                    <TextField name="icuBaseFair" type={'number'} value={priceValues.icuBaseFair} className={`text_field ${makeEditCls}`} 
                                    // {...register('icuBaseFair',{required: true})}
                                    onChange={changeHandler}
                                    disabled={makeEditOpt}
                                    />
                                    {/* {errors.icuBaseFare && errors.icuBaseFare.type === "required" && <p className='error mt-0'>Please enter value</p>} */}
                                    </Grid>
                                    <Grid  item md={2.5}>
                                    <TextField name="icuPricePerKm" type={'number'} value={priceValues.icuPricePerKm} className={`text_field ${makeEditCls}`}
                                    //  {...register('icuPricePerKm',{required: true})}
                                    onChange={changeHandler}
                                    disabled={makeEditOpt}
                                    />
                                    {/* {errors.icuPricePerKm && errors.icuPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>} */}
                                    </Grid>
                                   </Grid>
                                   {/* <Grid container item md={12} alignItems="center" rowSpacing={3} columnSpacing={5}>
                                        <Grid sx={{textAlign:"center"}} item md={4} >
                                          <FormLabel className='lableName'>Semi ICU</FormLabel>
                                        </Grid>
                                        <Grid  item md={2.5}>
                                        <TextField name="semiIcuBaseFairKm" type={'number'} value={priceValues.semiIcuBaseFairKm} className={`text_field ${makeEditCls}`} 
                                        {...register('semiIcuBaseFairKm',{required: true})}
                                        onChange={changeHandler}
                                        disabled={makeEditOpt}
                                        />
                                        
                                        </Grid>
                                        <Grid  item md={2.5}>
                                        <TextField name="semiIcuBaseFair" type={'number'} value={priceValues.semiIcuBaseFair} className={`text_field ${makeEditCls}`} 
                                        onChange={changeHandler}
                                        disabled={makeEditOpt}
                                        />
                                        </Grid>
                                        <Grid item md={2.5}>
                                          <TextField name="semiIcuPricePerKm" type={'number'} value={priceValues.semiIcuPricePerKm} className={`text_field ${makeEditCls}`} 
                                          onChange={changeHandler}
                                          disabled={makeEditOpt}
                                          />
                                        </Grid>
                                   </Grid> */}
                                   <Grid container mt={3} item md={12} alignItems="center" rowSpacing={1} columnSpacing={5}>
                                        <Grid sx={{textAlign:"center"}}  item md={4}>
                                          <FormLabel className='lableName'>General</FormLabel>
                                        </Grid>
                                        <Grid item md={2.5}>
                                          <TextField name="GeneralBaseFairKm" type={'number'} value={priceValues.GeneralBaseFairKm} className={`text_field ${makeEditCls}`} 
                                          onChange={changeHandler}
                                          disabled={makeEditOpt}
                                          />
                                        </Grid>
                                        <Grid item md={2.5}>
                                          <TextField name="GeneralBaseFair" type={'number'} value={priceValues.GeneralBaseFair} className={`text_field ${makeEditCls}`} 
                                            onChange={changeHandler}
                                            disabled={makeEditOpt}
                                            />
                                        </Grid>
                                        <Grid item md={2.5}>
                                            <TextField name="GeneralPricePerKm" type={'number'} value={priceValues.GeneralPricePerKm} className={`text_field ${makeEditCls}`}
                                            onChange={changeHandler}
                                            disabled={makeEditOpt}
                                            />
                                        </Grid>
                                   </Grid>
                                   <Grid container justifyContent='center'>
                                        <Grid item sx={{my:3}} >
                                          <Button style={{display:`${buttonDis}`}} onClick={updatePrice} className="customerLogin"bgColor='#129547' >Update</Button>
                                        </Grid> 
                                    </Grid>
                          
                            </Box>
              </Box>
      </Modal>
      <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
          <Alert onClose={SuccessClose} severity="success" className="snackBar">
            {successMsg}
          </Alert>
      </Snackbar>
      <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
          <Alert onClose={ErrorClose} severity="error" className="snackError">
            {errorMsg}
          </Alert>
      </Snackbar>
          
    </>
  );
}

export default TypePrice; 