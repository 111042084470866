import axios from "axios";

const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
const RazorpayPayment = {
  initPayment: async function(data,subscriptionPlan,type,bookingId) {
    const razorpayKey = `${process.env.REACT_APP_RAZORPAY_KEY}`;
    const options = {
      key: razorpayKey,
      amount: data.amount,
      currency: "INR",
      name: "Testing",
      description: "Test Transaction",
      image: "https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg",
      order_id: data.id,
      handler: async (response) => { 
        const payload = {
          userId: userlogCheck?.id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        }
        if (type === 1){
          payload.subscriptionPlan = subscriptionPlan._id
        }
        else if(type === 2){
          payload.bookingId = bookingId;
          payload.payType = 1
        }
        const self = this;
        const endpoint = self.getEndpoint(type);
        axios.post(`${process.env.REACT_APP_BASE_URL}${endpoint}`, payload, {headers:{Authorization:localStorage.getItem("Key")}})
          .then((res) => {
            if(res.data.status === 1){
              window.location.reload();
            }
          })
          .catch((err) => console.log(err));
      },
      theme: {
        color: "#686CFD",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  },

  handlePayment: async function(data,type,bookingId) {
    const payload = {
      userId: userlogCheck?.id,
      type: type,
      loginType:1
    }
    if(type === 1){
      payload.subscriptionPlan = data._id
    }
   else if(type === 2 || type === 3){
      payload.amount = data.price;
    }
    const orderUrl = `${process.env.REACT_APP_BASE_URL}createOrder`;
    await axios.post(orderUrl,payload, {headers:{Authorization:localStorage.getItem("Key")}})
      .then((res) => {
        if(res.status === 200){
          const {id,amount,currency} = res.data.data
          if(res.data.data){
            RazorpayPayment.initPayment({id,amount,currency},data,type,bookingId);
          }
        }
      })
      .catch((err) => console.log(err));
  },
  getEndpoint: function(type) {
    switch (type) {
      case 1:
        return "userSubscription";
      case 2:
        return "updatePayType";
      case 3:
        return "anotherEndpoint"; 
      default:
        return "";
    }
  }
};

export default RazorpayPayment;
