import React, { useEffect , useState } from "react";
import { 
Grid ,
Typography,
TextField,
Button,
FormLabel,
Modal,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow,
Pagination,
Stack,
Alert,
Snackbar,
Box,
Select,
MenuItem,
Backdrop,
} from "@mui/material";
import axios from "axios";
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie'
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AlertJson from '../../../Assets/icons/alert.json'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const AdminEmployees=()=>{

//const declaration for state management  
const [openView, setOpenView] = useState(false);
const [deleteId , setDeleteId] = useState("");
const [openEdit, setOpenEdit] = useState(false);
const [openDelete, setOpenDelete] = useState(false);
const [openBlock, setOpenBlock] = useState(false);
const [openunBlock, setOpenunBlock] = useState(false);
const [data ,setData] = useState([])
const [districts , setDistricts] = useState([])
const [vertical] =useState('top');
const [horizontal] =useState('right');
const [district , setDistrict] = useState("");
const [editDistrict , setEditDistrict] = useState("");
const [states , setStates] = useState([])
const [state , setState] = useState("")
const [page , setPage] = useState(0);
const [totalPages , setTotalPages] =useState();
const [successMsg , setSuccessMsg] = useState("");
const [errorMsg , setErrorMsg] = useState("");
const [loaderOpen, setLoaderOpen] = useState(false);
const [smessage , setSmessage] =useState(false);
const [emessage , setEmessage] =useState(false);
const [open, setOpen] = useState(false);
const [searchData , setSearchData ] = useState('')
const [stateChanges , setStateChanges] = useState(true);
const {register,handleSubmit,formState: { errors } , reset} = useForm();
const {register:register2,handleSubmit:handleSubmit2,formState: { errors:errors2 } } = useForm();
const [viewEmployee , setViewEmployee] = useState({
  name:"",
  phoneNumber:"",
  email:"",
  password:"",
  state:"",
  district:"",
  stateId:"",
  districtId:""
})

//lottie defined options
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: AlertJson,
  renderer: 'svg'
}


  const [approvalOpen, setApprovalOpen] = React.useState(false);
    const approvalHandleOpen = (id) => 
    {
      setDeleteId(id)
      setApprovalOpen(true);
    }
   
    const approvalHandleClose = (id) => setApprovalOpen(false);
    const [rejectOpen, setRejectOpen] = React.useState(false);
    const rejectHandleOpen = (id) =>
    {
      setDeleteId(id)
      setRejectOpen(true);
    }
   
    const rejectHandleClose = () => setRejectOpen(false);

    //some styles for modals options

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid white !important',
    boxShadow: 24,
    outline:'none',
    p:'2% 2% 0% 2%',
    borderRadius:'30px'
  };

  const DeleteStyle = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F1F3F6 !important;',
    border: '2px solid white',
    boxShadow: 24,
    outline:'none',
    p: 5,
    textAlign:'center',
    borderRadius:'10px'
  };
  
  const approvalStyle = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: ' #F1F3F6 !important;',
    border: '2px solid white',
    boxShadow: 24,
    p: 5,
    outline:0,
    textAlign:'center',
    borderRadius:'10px'
  };

//logged person details

  let user = JSON.parse(sessionStorage.getItem("userType"));
  let id = user.id;
  let type = user.type;

//default data loading when page was loaded

  useEffect(() => { 
    loadEmployeeTable(); 
    allStates();

  },[]);
  useEffect(() => { reset() },[reset]);




//functions to handle modals

  const handleCloseView = () => setOpenView(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseBlock = () => setOpenBlock(false);
  const handleCloseunBlock = () => setOpenunBlock(false);
  const handleClose = () => setOpen(false);
  const ErrorClose = () => setEmessage(false);
  const SuccessClose=() => setSmessage(false);


  const handleOpenDelete = (id) => {
    setDeleteId(id)
    setOpenDelete(true)
  }

//to handle district change

  const districtChange = (event) => setDistrict(event.target.value);

//to handle district change

  const editDistrictChange = (event) => {
    setViewEmployee({...viewEmployee , districtId :event.target.value})
  }


//functionality to view employee data modal

  const handleOpenView = (id) => {
    setDeleteId(id)
    getEmployeeData(id)
    setOpenView(true)
  }

//functionalty to open edit modal

  const handleOpenEdit = (row) => {
    setEditDistrict(row.district)
    setDistrict("")
    setState("")
    setDeleteId(row._id)
    getEmployeeData(row._id)
    setOpenEdit(true)

  }


//to get employee data from id

  const getEmployeeData = (id) =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}getEmployeeById/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
      let status = res.data.status;
      if(status === 1){
        setViewEmployee({...viewEmployee ,name:res.data.details.name , phoneNumber:res.data.details.phone , email:res.data.details.email , password:res.data.details.password , district:res.data.details.districtName , state:res.data.details.stateName , stateId:res.data.details.state , districtId:res.data.details.district})
      }else if(status === 0){
        
      }
    }).catch((error) => {console.log(error)})
  }
  
//functionality for deleting employee

  const Delete = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}deleteEmployee/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 1){
          setSmessage(true)
          setSuccessMsg(responce.data.message)
          setOpenDelete(false)
          searchFilter(searchData)
        }else{
          setEmessage(true)
          setErrorMsg(responce.data.message)
        }
      }
    ).catch((error) => {console.log(error)})
  }


//functionality to Block Employee

  const Block = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockEmployee/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setSmessage(true);
            setSuccessMsg(responce.data.message)
            setOpenBlock(false)
            loadEmployeeTable()
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
    ).catch((error) => {console.log(error)})
  }

//functionality to unBlock employee

  const unBlock = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockEmployee/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setSmessage(true);
            setSuccessMsg(responce.data.message)
            setOpenunBlock(false)
            loadEmployeeTable()
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
    ).catch((error) => {console.log(error)})
  }

//functionality to approve/reject employee

  const approveReject = ( id , status ) =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}approveRejectEmployee/${id}/${status}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setSmessage(true);
            setSuccessMsg(responce.data.message)
            loadEmployeeTable()
            setApprovalOpen(false);
            setRejectOpen(false);
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
    ).catch((error) => {console.log(error)})
  }

//functionality to open block or unblock modals

  const handleOpenBlock = (id , type) => {
    if(type === "block"){
      setOpenBlock(true)
      setDeleteId(id)
    }else if(type === "Unblock"){
      setOpenunBlock(true)
      setDeleteId(id)
    }
  }

// to get all employees data onLoad

  const loadEmployeeTable = () => {
      axios.post(`${process.env.REACT_APP_BASE_URL}employeeList/0/10`,{type:type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 0){
            }else if(responce.data.status === 1){
              setData(responce.data.details)
              setTotalPages(responce.data.totalPages)
            }
          }
      ).catch((error) => {console.log(error)})
  }

// to get all states 

  const allStates = () =>{
    axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {setStates(responce.data.result)}
    ).catch((error) => {console.log(error)})
  }

// to get district based on state

  const stateChange = (event) => {
    setState(event.target.value)
    getDistricts(event.target.value)
    
      
  }

const getDistricts=(state)=>{
  axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:state},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
    (responce) => {
        if(responce.data.status === 1){
        setDistricts(responce.data.result)
        setDistrict("");
        }else{
            setDistricts([])
        }
    }
).catch((error) => {console.log(error)})
}

// to get district based on Editstate  

  const editStateChange =async (event) => {
    const check = await setViewEmployee({...viewEmployee , districtId:''})
    setStateChanges(false);
    getDistricts(event.target.value)
    if(viewEmployee.districtId === ''){
      setViewEmployee({...viewEmployee , stateId:event.target.value})
    }
    axios.get(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event.target.value},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
              if(responce.data.status === 1){
              setDistricts(responce.data.result)
              setDistrict("");
              //setViewEmployee({...viewEmployee , districtId:""})
              }else{
                  setDistricts([])
              }
          }
      ).catch((error) => {console.log(error)})
  }

// to handle pagination
 
  const handleChange = (event, value) => {
    setPage(value-1);
      axios.post(`${process.env.REACT_APP_BASE_URL}employeeList/${value-1}/10`,{type:1,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {setData(responce.data.details)}
      ).catch((error) => {console.log(error)})
  };

// to open addEmployee modal

  const handleClickOpen = () => {
    setDistrict("")
    setState("")
   
      setOpen(true);
    
  };

// to handle edit employee

  const onEditSubmit = (Form_data) => {
    // console.log(viewEmployee)
    axios.post(`${process.env.REACT_APP_BASE_URL}editEmployee`,{name:viewEmployee.name , phone:viewEmployee.phoneNumber , email:viewEmployee.email , password:viewEmployee.password , id:deleteId , state :viewEmployee.stateId , district:editDistrict},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 1){
          setSmessage(true)
          setSuccessMsg(responce.data.message)
          handleCloseEdit()
          loadEmployeeTable();
        }else if(responce.data.status === 0){
          setEmessage(true)
          setErrorMsg(responce.data.message)
        }
        
      }
    ).catch((error) => {console.log(error)})
  }

// to add new employee functionality

  const onSubmit = async (Form_data)=>{
    setLoaderOpen(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}addEmployee`,{name:Form_data.name , phone:Form_data.phone, email:Form_data.email , state: Form_data.state , district:district , addedBy:type , addedById:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
          let status = res.data.status;
              if(status === 1){
                setSmessage(true);
                setSuccessMsg(res.data.message)
                setOpen(false);
                loadEmployeeTable();
                setLoaderOpen(false)
              }else if(status === 0){
                setEmessage(true)
                setErrorMsg(res.data.message)
                setLoaderOpen(false)
              }
              reset()
      }).catch((error) => {console.log(error)})
  }

// functionality for search
  
        
  const searchFilter = (e) =>{
    if(e === ""){
      loadEmployeeTable();
    }else{
      setSearchData(e)
      axios.post(`${process.env.REACT_APP_BASE_URL}employeeSearch/0/10`,{search:e , type:1 , id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 0){
            }else if(responce.data.status === 1){
              setData(responce.data.details)
                setTotalPages(responce.data.totalPages)
            }
          }
        ).catch((error) => {console.log(error)})
    }
  }

// html loop for employee table

  const table_row = data.map((row , index) =>  <TableRow key={row.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align="center" component="td" style={{maxWidth: 100}} scope="row">{(page)*10+index+1}</TableCell>
        <TableCell align="center" component="td" style={{maxWidth: 130}} title={row.name}>{row.name}</TableCell>
        <TableCell align="center" component="td" style={{maxWidth: 130}} title={row.phone}>{row.phone}</TableCell>
        <TableCell align="center" component="td" style={{maxWidth: 130}} title={row.email}>{row.email}</TableCell>
        <TableCell align="center" component="td" style={{maxWidth: 130}} title={row.districtName}>{row.districtName}</TableCell>
        {/* <TableCell align="center" component="td" style={{maxWidth: 130}}> *********</TableCell> */}
        {row.approvalStatus === 1 ?<TableCell align="center" component="td" style={{maxWidth: 180}}>
            <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
             onClick={()=>{handleOpenView(row._id)}}
            />
            <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}}
             onClick={()=>{handleOpenEdit(row)}}
             />
            <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}}
             onClick={()=>{handleOpenDelete(row._id)}}
             />
             {!row.blockStatus?<BlockOutlinedIcon className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "Unblock")} }
             />:<BlockOutlinedIcon className="actioinIcon" sx={{cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "block")} }
             />}
      </TableCell>:
      <TableCell align="center" component="td"  className="flexitem bordericons"  style={{maxWidth: 300}}>
        {type === 1 ?<TableCell  align="center" component="td" className="bordertd "  style={{maxWidth: 200}}>
        <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
             onClick={()=>{handleOpenView(row._id)}}
            />
        <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon"
        //  onClick={()=>approveReject(row._id , 1)}
        onClick={()=>{approvalHandleOpen(row._id)}} 
         />
        <CancelOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="actioinIcon"
        //  onClick={()=>approveReject(row._id , 2)}
        onClick={()=>{rejectHandleOpen(row._id)}}
         />
        </TableCell>:<TableCell  align="center" component="td" className="bordertd">
        <Typography>Need to be approved</Typography>
        </TableCell>}
        
      </TableCell>}
        
    </TableRow>);

// html loops for state and district options
      
  const DistrictOptions = districts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
  const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)

  useEffect(() => {
    if (viewEmployee.stateId) {
      setViewEmployee(prevState => ({ ...prevState, districtId: '' }));
    }
}, [viewEmployee.stateId]);

useEffect(() => {
  if (viewEmployee.stateId) {
    getDistricts(viewEmployee.stateId);
  }
}, [viewEmployee.stateId]);
    return(
        <>
          <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid  className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}} >Employees</Typography>
                </Grid>
                <Grid sx={{ display: 'flex' , mb:1 }}>
                    <Grid>
                        <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                            startAdornment: (
                        <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                            
                            ),
                        }}/>
                    </Grid>
                    <Grid  sx={{ display: 'flex',alignItems:'center',mx:3 }}>
                        <Typography className="add_text">Add </Typography>
                        <AddCircleIcon className="Add_icon"  onClick={handleClickOpen} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">NAME</TableCell>
                                <TableCell className="tableHead">PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">DISTRICT</TableCell>
                                {/* <TableCell className="tableHead">PASSWORD</TableCell> */}
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                          data.length === 0 ?<TableRow>
                          
                          <TableCell colSpan={8} align="center">
                             <h2> No Data Found</h2>
                          </TableCell>
                        
                          </TableRow>:table_row}
                     
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid>
              <Grid className={'outletBodyFooter'} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
          </Grid> 
          
{/* MODAL */}
<Modal
  open={open}
  // onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description">
  <Box sx={style}>
      <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
        <Grid>
            <Typography className="titleName" id="modal-modal-title" variant="h6" align="center" component="h2">
              Add Employee
            </Typography>
        </Grid>
        <Box sx={{ width: '100%' }} className="modalscroll">
          <Grid conatiner direction="row" justifyContent="center" alignItems="center" id="modal-modal-description" sx={{ mt: 2 }} className="modalscrollwidth">
             <Grid container  justifyContent='center'>
                   <Grid item md={10} sx={{mt:2}}  >
                          <FormLabel>Name</FormLabel>
                          <TextField name="name" className={'text_field'} {...register('name',{required: true})}/>
                          {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
                    </Grid>
                    <Grid item md={10} sx={{mt:2}}  >
                          <FormLabel>Phone Number</FormLabel>
                          <TextField name="phone" className={'text_field'} {...register('phone',{required: true,pattern:/^\d{10}$/})}></TextField>
                          {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                          {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                    </Grid>
                    <Grid item  md={10} sx={{mt:2}}  >
                          <FormLabel>Email ID</FormLabel>
                          <TextField name="email" className={'text_field'} {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}></TextField>
                          {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter Email </p>}
                          {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>Please enter valid Email</p>}
                    </Grid>
                    <Grid item md={10} sx={{mt:2}}  >
                      <FormLabel>State</FormLabel>
                      <Select
                        disableUnderline
                        className='selectOptions '
                        displayEmpty
                        fullWidth
                        value={state}
                        placeholder="Select state"
                        {...register('state',{required: true})}
                        onChange={stateChange}
                      >
                        <MenuItem value="">
                          <em>Select State</em>
                        </MenuItem>
                        {stateOptions}
                      </Select>
                      {errors.state && errors.state.type === "required" && state.length === 0 &&  <p className='error mt-0'>Please enter state</p>}
                    </Grid>
                    <Grid item md={10} sx={{mt:2}}>
                          <FormLabel>District</FormLabel>
                          <Grid item>
                            <Select disableUnderline 
                              displayEmpty
                              className='selectOptions ' fullWidth
                              defaultValue={30}
                              value={district}
                              {...register('district',{required: true})}
                              onChange={districtChange}>
                              <MenuItem value="">
                                  <em>Select District</em>
                              </MenuItem>
                              {DistrictOptions}
                            </Select>
                            {errors.district && errors.district.type === "required" && district.length === 0 && <p className='error mt-0'>Please enter District</p>}
                          </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent='center' sx={{my:3}}>
              <Button  className="customerLogin"bgColor='#129547' onClick={handleSubmit(onSubmit)}>Add</Button>
              <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loaderOpen}
                    onClick={handleClose}
                    invisible={true}
                >
                    <Lottie 
                    options={defaultOptions}
                    height={100}
                    width={100}
                    />
                    
                </Backdrop>
            </Grid>
  </Box>
</Modal>

{/* MODAL */}

{/*Start View Modal */}
        <Modal
        open={openView}
        // onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box sx={style}>
                    <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
                    <Grid>
                        <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                          Empolyee details
                        </Typography>
                    </Grid>
                    <Box sx={{ width: '100%' }}>
                        <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item md={6}>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Name</FormLabel>
                                    <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.name}</Typography>
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Email</FormLabel>
                                    <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.email}</Typography>
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Password</FormLabel>
                                    <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.password}</Typography>
                                </Grid>
                          </Grid>
                          <Grid item md={6}>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel>Phone number</FormLabel>
                                  <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.phoneNumber}</Typography>
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel>State</FormLabel>
                                  <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.state}</Typography>
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel>District</FormLabel>
                                  <Typography fullWidth name="name" className={'viewBox'}>{viewEmployee.district}</Typography>
                              </Grid>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}}>
                              <Button className="customerLogin" bgColor='#129547' onClick={()=>{handleCloseView()}}>Ok</Button>
                            </Grid> 
                        </Grid>
                    </Box>
            </Box>
        </Modal>
{/* End View Modal */}

{/* Start Edit Modal */}
      <Modal
        open={openEdit}
        // onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
           <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseEdit()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                      Edit Employee
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Name</FormLabel>
                                <TextField fullWidth value={viewEmployee.name} name="editName" className={'text_field '}
                                {...register2('editName',{required: !viewEmployee.name})}  
                                onChange={(e)=>{setViewEmployee({...viewEmployee ,name:e.target.value})}}
                                />
                                {viewEmployee.name === "" && errors2.editName && errors2.editName.type === "required" && <p className='error mt-0'>Please enter name</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Email</FormLabel>
                                <TextField fullWidth value={viewEmployee.email} name="editEmail" className={'text_field '} 
                                {...register2('editEmail',{required: !viewEmployee.email,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                onChange={(e)=>{setViewEmployee({...viewEmployee ,email:e.target.value})}}
                                />
                                {viewEmployee.email === "" && errors2.editEmail && errors2.editEmail.type === "required" && <p className='error mt-0'>Please enter Email</p>}
                                {errors2.editEmail && errors2.editEmail.type === "pattern"  && <p className='error mt-0'>Please enter valid Email</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Password</FormLabel>
                                <TextField fullWidth value={viewEmployee.password} name="editPassword" className={'text_field '}
                                {...register2('editPassword',{required: !viewEmployee.password,pattern:"/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/"})} 
                                onChange={(e)=>{setViewEmployee({...viewEmployee ,password:e.target.value})}}
                                />
                                { viewEmployee.password === "" && errors2.editPassword && errors2.editPassword.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                {errors2.editPassword && errors2.editPassword.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Grid  item sx={{mt:2}}>
                                <FormLabel>Phone number</FormLabel>
                                <TextField fullWidth value={viewEmployee.phoneNumber} name="name" className={'text_field'}
                                {...register2('editPhone',{required: !viewEmployee.phoneNumber,pattern:/^\d{10}$/})}
                                onChange={(e)=>{setViewEmployee({...viewEmployee ,phoneNumber:e.target.value})}}
                                />
                                { viewEmployee.phoneNumber === "" && errors2.editPhone && errors2.editPhone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                {errors2.editPhone && errors2.editPhone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>State</FormLabel>
                                <Select
                                  disableUnderline
                                  className='selectOptions '
                                  displayEmpty
                                  fullWidth
                                  value={viewEmployee.stateId}
                                  placeholder="Select state"
                                  {...register2('editState')}
                                  onChange={editStateChange}
                                >
                                <MenuItem value="">
                                    <em>Select State</em>
                                </MenuItem>
                              {stateOptions}
                            </Select>
                            </Grid>
                            <Grid item sx={{mt:2}}>
                              <FormLabel>District</FormLabel>
                              <Select disableUnderline 
                                  displayEmpty
                                    className='selectOptions ' fullWidth
                                    defaultValue={30}
                                    placeholder="Select District"
                                    value={viewEmployee.districtId}
                                    {...register2('editDistrict')}
                                    onChange={editDistrictChange}>
                                      <MenuItem value="">
                                          <em>Select District</em>
                                      </MenuItem>
                                      {stateChanges&&<MenuItem value={viewEmployee?.districtId}>
                                          {viewEmployee.district}
                                      </MenuItem>}
                                    {DistrictOptions}
                              </Select>
                              { viewEmployee.districtId === "" && errors2.editDistrict && errors2.editDistrict.type === "required" && <p className='error mt-0'>Please select district</p>}
                            </Grid>
                        </Grid>
                  </Grid>
                  <Grid container justifyContent='center'>
                      <Grid item sx={{my:3}} >
                          <Button className="customerLogin" bgColor='#129547' onClick={handleSubmit2(onEditSubmit)}>update</Button>
                      </Grid> 
                  </Grid>
              </Box>
          </Box>
      </Modal>
{/* End Edit Modal */}

{/*START DELETE MODAL */}
<Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete the employee?
          </Typography>
          <Grid container justifyContent="space-around" sx={{mt: 2}}>
            <Button className = "yesButton" onClick={Delete}>Yes</Button>
            <Button className = "noButton" onClick={handleCloseDelete}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END DELETE MODAL  */}

  {/*START BLOCK MODAL  */}
  <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to block the employee?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={Block} >Yes</Button>
                <Button className="noButton" onClick={handleCloseBlock}>No</Button>
            </Grid>
        </Box>
      </Modal>

  {/*END BLOCK MODAL  */}
  {/*START UNBLOCK MODAL  */}
      <Modal
        open={openunBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to un-block the employee?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
              <Button className="yesButton" onClick={unBlock} >Yes</Button>
              <Button className="noButton" onClick={handleCloseunBlock}>No</Button>
            </Grid>
        </Box>
      </Modal>

  {/*END UNBLOCK MODAL  */}


  <Modal
        open={approvalOpen}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
        <Typography  id="modal-modal-title" variant="h6" component="h2">
                Do you really want to Approve?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
              <Button className="yesButton" onClick={()=>approveReject(deleteId, 1)}
               >Yes</Button>
              <Button className="noButton" onClick={approvalHandleClose}>No</Button>
            </Grid>
        </Box>
      </Modal>
      <Modal
        open={rejectOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
            <Typography  id="modal-modal-title" variant="h6" component="h2">
                Do you really want to Reject?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
              <Button className="yesButton" onClick={()=>approveReject(deleteId, 2)} >Yes</Button>
              <Button className="noButton" onClick={rejectHandleClose}>No</Button>
            </Grid>
        </Box>
      </Modal>
      
          <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
              <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
              </Alert>
          </Snackbar>
          <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{vertical, horizontal}} onClose={ErrorClose}>
              <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
              </Alert>
          </Snackbar>
            
            
           
           
        </>
    )
}

export default AdminEmployees