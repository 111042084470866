import React from 'react'
import CustomerHeader from './Header/header'
import CustomerFooter from './Footer/footer'
import { Outlet } from 'react-router-dom'
import { Grid } from '@mui/material'

const User=()=>{
    return(
        <>
        <Grid className='bodyAlign'>
            <CustomerHeader/>
            <Outlet/>
            <CustomerFooter/>

        </Grid>
        
        </>
    )
}
export default User