import React, { useState,useEffect } from 'react'
import { 
Grid ,
Typography ,
Button,
FormLabel,
TextField,
Snackbar,
Alert,
Modal,
Card,
CardContent,
Box,
FormControl,
NativeSelect,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import { useForm } from 'react-hook-form';


const Wallet=()=>{
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () =>{setOpen(true); } ;
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleCloseDelete = () => setOpenDelete(false);
    const {register,handleSubmit,formState: { errors },reset} = useForm();
    const [planData, setPlanData] = React.useState([]);
    const [deleteId,setDeleteId] = React.useState("")
    const handleOpenDelete = (id) =>{
        setOpenDelete(true);
        setDeleteId(id)
    }
    const [successMessage, setSuccessMessage]= useState(false);
    const [errorMessage , setErrorMessage] =useState(false);
    const [vertical] =useState('top');
    const [horizontal] =useState('right');
    const [emessage , setEmessage] =useState(false);
    const [smessage , setSmessage] =useState(false);
    const [wallet , setWallet] = useState({
        rides:'',
        price:''
    })

    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // height:500,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 4% 0% 4%',
        outline:0,
        borderRadius:'10px'
      };
      const DeleteStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline:'none',
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
    };

    const DeleteRider=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}deletePlan/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status

            if (status===1){
                setSuccessMessage(res.data.message)
                getPlan()
                setOpenDelete(false);
                setSmessage(true)

            }
            else if (status===0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                
            }
           
           

         }).catch((error) => {console.log(error)})

    }

    const getPlan=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}getPlans`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            if(res.data.planData){
                setPlanData(res.data.planData)
            }else{
                setPlanData([])
            }
           


        }).catch((error) => {console.log(error)})
    }


    const onSubmit = (data) => {
        const {rides , price} = wallet
        axios.post(`${process.env.REACT_APP_BASE_URL}addPlan`,{rides, price},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status

            if (status===1){
                setWallet({
                    ...wallet,
                    rides:'',
                    price:''
                })
                reset()
                setSuccessMessage(res.data.message)
                getPlan()
                setOpen(false);
                setSmessage(true)

            }
            else if (status===0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                
            }
         }).catch((error) => {console.log(error)})
    }

    const changeStatus = (e , id) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}changePlanStatus`,{planId:id , status:e.target.value},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            
            let status = res.data.status

            if (status===1){
                setSuccessMessage(res.data.message)
                getPlan()
                setSmessage(true)

            }
            else if (status===0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                
            }
         }).catch((error) => {console.log(error)})
    }


    useEffect(()=>{
        getPlan()
    },[])

    const cards = planData.length!='' && planData.map((row , index) =>
                    <Grid container key={row._id}  md={3}>
                    <Grid item>
                        <Card className="walletcard" sx={{backgroundColor:"white"}}>
                            <CardContent>
                                <Grid container alignItems="center" item>
                                <Typography className={"walletNum"}>{row.rides}</Typography>
                                <Typography className={"walletText"}>Rides</Typography>
                                </Grid>
                                <Grid item>
                                <Typography className={"walletPrice"}>₹{row.price}/-</Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container md={12} justifyContent="space-between" className="hospitalName">
                        <Grid item md={6}>
                            <Box >
                                <FormControl fullWidth>
                                    <NativeSelect
                                    className={"nativetag"}
                                    disableUnderline
                                    defaultValue={row.status}
                                    inputProps={{
                                    name: 'age',
                                    id: 'uncontrolled-native',
                                    }}
                                    onChange={(e)=>{changeStatus(e , row._id)}}
                                    >
                                        <option value={1}>Active</option>
                                        <option value={0}>In Active</option>
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={4} sx={{textAlign:"center"}}>
                            <DeleteOutlineOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="hospitallogodeleteicon" onClick={() => {handleOpenDelete (row._id)}} />
                        </Grid>
                </Grid>
                </Grid>
    )
   
    return(
        <>
            <Grid className={'outletBody'} sx={{px:3,py:2}}>
                <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid>
                        <Typography sx={{ fontSize: '18px' }}>Wallet</Typography>
                    </Grid>
                </Grid>
                <Grid container >
                    <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    />
                    <Grid container  justifyContent="center" alignItems="center" md={2.7} mb={3} 
                    className="pluscard" onClick={handleOpen}
                    >
                        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M42 0C43.3924 0 44.7277 0.553124 45.7123 1.53769C46.6969 2.52226 47.25 3.85761 47.25 5.25V36.75H78.75C80.1424 36.75 81.4777 37.3031 82.4623 38.2877C83.4469 39.2723 84 40.6076 84 42C84 43.3924 83.4469 44.7277 82.4623 45.7123C81.4777 46.6969 80.1424 47.25 78.75 47.25H47.25V78.75C47.25 80.1424 46.6969 81.4777 45.7123 82.4623C44.7277 83.4469 43.3924 84 42 84C40.6076 84 39.2723 83.4469 38.2877 82.4623C37.3031 81.4777 36.75 80.1424 36.75 78.75V47.25H5.25C3.85761 47.25 2.52226 46.6969 1.53769 45.7123C0.553124 44.7277 0 43.3924 0 42C0 40.6076 0.553124 39.2723 1.53769 38.2877C2.52226 37.3031 3.85761 36.75 5.25 36.75H36.75V5.25C36.75 3.85761 37.3031 2.52226 38.2877 1.53769C39.2723 0.553124 40.6076 0 42 0Z" fill="white" />
                        </svg>
                    </Grid>
                    {cards}
                </Grid>
            </Grid>

    <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
                <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                        Add 
                    </Typography>
                </Grid>
                <form>
                <Grid container item md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                    <Grid item md={12} sx={{mt:3}}>
                        <Grid container justifyContent="space-between"                                  alignItems="center">
                            <Grid item md={5} >
                                <FormLabel>Rides</FormLabel>
                                <TextField fullWidth name="rides"  className="text_field" value={wallet.rides}  {...register('rides',{required: true})} onChange={(e)=>{setWallet({...wallet,rides:e.target.value})}}/>
                                {errors.rides && errors.rides.type === "required" && <p className='error mt-0'>Please enter no.of rides</p>}
                            </Grid>
                            <Grid item md={5}>
                                <FormLabel>Amount</FormLabel>
                                <TextField fullWidth name="price"  className="text_field" value={wallet.amount}  {...register('price',{required: true})} onChange={(e)=>{setWallet({...wallet,price:e.target.value})}}/>
                                {errors.price && errors.price.type === "required" && <p className='error mt-0'>Please enter price</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} justifyContent='center'>
                    <Grid item sx={{my:3}}>
                        <Button type="submit" className="customerLogin" bgColor='#129547' onClick={handleSubmit(onSubmit)}>
                            add
                        </Button>
                    </Grid> 
                </Grid>
                </form>
        </Box>
    </Modal>

       {/*START DELETE MODAL */}
           
    <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                 Do you want to delete ride?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={DeleteRider} >Yes</Button>
                <Button className="noButton"  onClick={handleCloseDelete}>No</Button>
            </Grid>
        </Box>
    </Modal>

  {/*END DELETE MODAL  */}
    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
            {successMessage}
        </Alert>
    </Snackbar> 
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert className="snackError" onClose={ErrorClose} severity="error" >
            {errorMessage}
        </Alert>
    </Snackbar>
        </>
    )
}
export default Wallet;