import React,{ useState , useEffect } from "react";
import { 
Grid,
Typography,
Card,
CardContent,
Box,
Modal,
TextField,
FormLabel,
Button,
Snackbar,
Alert,
} from "@mui/material";
import axios from "axios";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './hospitallogos.css'
import CloseIcon from '@mui/icons-material/Close';
import avatar from '../../../Assets/icons/imageavthar.png';

const AdminHospitalLogos = () => {

    const [image , setImage] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [smessage , setSmessage] = useState(false);
    const [successMessage , setSuccessMessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [errorMessage , setErrorMessage] = useState(false);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [deleteId , setDeleteId] = useState("");
    const [imageError , setImageError] = useState({
        image:false,
        name:false
    })
    const [imageData , setImageData] = useState({
        profile:"",
        name:"",
        preview:""
    });

    useEffect(()=>{
        getLogos();
    },[])

    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);

    const setPicture = (e) => {
        setImageData({
            ...imageData,
            profile:e.target.files[0],
            preview:URL.createObjectURL(e.target.files[0])
        })      
    }

    const setImageName = (e) => {
        setImageData({
            ...imageData,
            name:e.target.value
        })
    }

    const uploadPicture = () => {

        if(imageData.profile === ""){
            setImageError({
                image:true
            })
        }else if(imageData.name === ""){
            setImageError({
                image:false,
                name:true
            })
        }else{
            setImageError({
                image:false,
                name:false
            })
            const formData = new FormData();
            formData.append("profile", imageData.profile);
            formData.append("name", imageData.name);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}addHspLogo`,
                headers: {
                    Authorization: process.env.REACT_APP_AUTHORIZATION,
                },
                data: formData
            }).then((res) => {
                let status = res.data.status;
                if (status === 1) {
                    getLogos();
                    setOpen(false);
                    setSuccessMessage(res.data.message)
                    setSmessage(true);
                } else if (status === 0) {
                    setErrorMessage(res.data.message)
                    setEmessage(true)
                }

            }).catch((error) => { console.log(error) })
            setImageData({
                ...imageData,
                name:"",
                profile:"",
                preview:""
            })
        }
    }
    const getLogos = () => {
            axios.get(`${process.env.REACT_APP_BASE_URL}getHospitalLogo`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setImage(res.data.hospitalLogos)
                }else if(status === 0){
                    
                }
            }).catch((error) => {console.log(error)})
        }
        const handleOpenDelete = (id) => {
            setDeleteId(id)
            setOpenDelete(true);
        }
        const deleteLogo = () =>{
            axios.get(`${process.env.REACT_APP_BASE_URL}deleteHspLogo/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                
                if(status === 1){
                   
                    setSuccessMessage(res.data.message)
                    setSmessage(true);
                    setOpenDelete(false);
                    window.location.reload();
                }else if(status === 0){
                    
                    setErrorMessage(res.data.message)
                    setEmessage(true)
                }

            }).catch((error) => {console.log(error)})
        }
        const imageTag = image.map((row , index) =>
        <Grid container  md={3}>
            <Grid item>
                <Card className="hospitalcard" sx={{backgroundColor:"white"}}>
                    <CardContent>
                        <Grid item>
                            <img src={`${process.env.REACT_APP_IMAGE_PATH}/${row.image}`} alt="HospitalLogo" align="center" className="hospitalLogo logowidth" />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container md={12} justifyContent="center" className="hospitalName">
                <Grid item md={9}>
                    <Typography className="rowname">{row.name}</Typography>
                </Grid>
                <Grid item md={3} sx={{textAlign:"center"}}>
                    <DeleteOutlineOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="hospitallogodeleteicon" onClick={()=>handleOpenDelete(row._id)} />
                </Grid>
            </Grid>
        </Grid>
        
)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    // height:500,
    bgcolor: ' #F1F3F6 !important;',
    border: '2px solid white',
    boxShadow: 24,
    p: '2% 4% 0% 4%',
    outline:0,
    borderRadius:'10px'
  };
  const DeleteStyle = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: ' #F1F3F6 !important;',
    border: '2px solid white',
    boxShadow: 24,
    outline:'none',
    p: 5,
    textAlign:'center',
    borderRadius:'10px'
};

  const [open, setOpen] = React.useState(false);
  const handleOpen = () =>{
    setImageData({
        ...imageData,
        name:"",
        profile:"",
        preview:""
    })
    setImageError({
        image:false,
        name:false
    })
    setOpen(true);
  } 
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDelete(false);
  
        return (
            <>

                <Grid className={'outletBody'} sx={{px:3,py:2}}>
                    <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid>
                            <Typography sx={{ fontSize: '18px' }}>Hospital logos</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={setPicture}
                        />
                        <Grid container justifyContent="center" alignItems="center" md={2.7} mb={3} 
                        className="pluscard" onClick={handleOpen} >
                            <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M42 0C43.3924 0 44.7277 0.553124 45.7123 1.53769C46.6969 2.52226 47.25 3.85761 47.25 5.25V36.75H78.75C80.1424 36.75 81.4777 37.3031 82.4623 38.2877C83.4469 39.2723 84 40.6076 84 42C84 43.3924 83.4469 44.7277 82.4623 45.7123C81.4777 46.6969 80.1424 47.25 78.75 47.25H47.25V78.75C47.25 80.1424 46.6969 81.4777 45.7123 82.4623C44.7277 83.4469 43.3924 84 42 84C40.6076 84 39.2723 83.4469 38.2877 82.4623C37.3031 81.4777 36.75 80.1424 36.75 78.75V47.25H5.25C3.85761 47.25 2.52226 46.6969 1.53769 45.7123C0.553124 44.7277 0 43.3924 0 42C0 40.6076 0.553124 39.2723 1.53769 38.2877C2.52226 37.3031 3.85761 36.75 5.25 36.75H36.75V5.25C36.75 3.85761 37.3031 2.52226 38.2877 1.53769C39.2723 0.553124 40.6076 0 42 0Z" fill="white" />
                            </svg>
                        </Grid>
                       {imageTag}
                    </Grid>
                </Grid>

               
    <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
            <Grid>
                <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                    Add Logo
                </Typography>
            </Grid>
            <Grid container item md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                <Grid item md={10} sx={{mt:5}}>
                    <Grid container justifyContent="space-between"                                  alignItems="center">
                        <Grid item md={7}>
                            <Typography className="uploadlogo">
                                <img src={imageData.preview !=="" ? imageData.preview : avatar} alt="img" height={140} width={160}></img>
                            </Typography>
                            {imageError.image && <p className="error">Please upload image</p>}
                        </Grid>
                        <Grid item md={4}>
                            <label htmlFor="raised-button-file">
                                <Typography className="adminUploadbtn">Upload</Typography>
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{mt:2}}>
                    <FormLabel>Hospital name</FormLabel>
                    <TextField fullWidth name="name"  className="text_field" onChange={(e)=>{setImageName(e)}} />
                    {imageError.name && <p className="error">Please enter name</p>}
                </Grid>
            </Grid>
            <Grid container md={12} justifyContent='center'>
                <Grid item sx={{my:3}}>
                    <Button className="customerLogin" bgColor='#129547' onClick={uploadPicture}>
                        add
                    </Button>
                </Grid> 
            </Grid>
        </Box>
      </Modal>


      {/*START DELETE MODAL */}
           
    <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to delete the hospital logo?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={deleteLogo}>Yes</Button>
                <Button className="noButton"  onClick={handleCloseDelete}>No</Button>
            </Grid>
        </Box>
    </Modal>

  {/*END DELETE MODAL  */}

    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
            {successMessage}
        </Alert>
    </Snackbar> 
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert className="snackError" onClose={ErrorClose} severity="error" >
            {errorMessage}
        </Alert>
    </Snackbar>
     
            </>
        )
    }
    export default AdminHospitalLogos
