
import './App.css';
import Routes from './Routes/Route_comp';

 
function App() {
  return (
        <>
           <Routes/>
        </>
  );
}

export default App;
