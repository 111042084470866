import React,{ useEffect ,  useState } from 'react';
import {
Grid,
Box,
Typography,
TextField,
Button,
FormLabel,
Modal,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow,
Pagination,
Stack,
Snackbar,
Alert,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';


const BookingHistory = () => {

    const [open, setOpen] = useState(false);
    const [data ,setData] = useState([])
    const [totalPages , setTotalPages] =useState();
    const [page, setPage] = useState(0);
    const [nodata , setNodata] = useState(false);
    const [smessage , setSmessage] =useState(false);
    const [successMessage , setSuccessMessage] =useState(false);
    const [emessage , setEmessage] =useState(false);
    const [errorMessage , setErrorMessage] =useState(false);
    const [vertical] =useState('top');
    const [horizontal] =useState('right');
    const [deleteId , setDeleteId] =useState("");
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const [viewBooking , setViewBooking] = useState({
      userName:"",
      driverName:"",
      price:"",
      type:"",
      fromAddress:"",
      toAddress:""
    })
    const user = JSON.parse(sessionStorage.getItem("userType"));

    const handleOpen = (id) => {
      setDeleteId(id)
      getBookingDetails(id)
      setOpen(true);
    
    }
    const handleClose = () => setOpen(false);

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id) => {
      setDeleteId(id)
      setOpenDelete(true)
    };
    const handleCloseDelete = () => setOpenDelete(false);
    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);

    useEffect(()=>{loadBookingHistory()} , [])

    // pagination change handling
  
  const handleChange = (event, value) => {
    setPage(value-1);
      axios.post(`${process.env.REACT_APP_BASE_URL}bookingHistory/${value-1}/10`,{id:user.id,type:user.type},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {setData(responce.data.details)}
    ).catch((error) => {console.log(error)})
  };


  // functionlity for Deleting history record

  const Delete = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}deleteBooking/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 1){
          setSmessage(true)
          setSuccessMessage(responce.data.message)
          setOpenDelete(false)
          loadBookingHistory()
        }else{
          setEmessage(true)
          setErrorMessage(responce.data.message)
        }
      }
    ).catch((error) => {console.log(error)})
  }

  const Viewstyle = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      width: 600,
      transform: 'translate(-50%, -50%)',
      width: '600 !important',
      bgcolor: '#F1F3F6 !important;',
      border: '2px solid white',
      boxShadow: 24,
      p: '2% 4% 0% 4%',
      outline:0,
      borderRadius:'10px'
    };
    const Deletestyle = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: ' #F1F3F6 !important;',
      border: '2px solid white',
      boxShadow: 24,
      outline:0,
      p: 5,
      textAlign:'center',
      borderRadius:'10px'
    };
    const loadBookingHistory = () => {
      axios.post(`${process.env.REACT_APP_BASE_URL}bookingHistory/0/10`,{id:user.id,type:user.type},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            setData(responce.data.details)
            setTotalPages(responce.data.totalPages)
          }else{
            setData([])
          }
        }
    ).catch((error) => {console.log(error)})
    }

      // search filter functionality

  const searchFilter = (e) =>{
    if(e === ""){
      setNodata(false)
      loadBookingHistory();
    }else{
      axios.post(`${process.env.REACT_APP_BASE_URL}bookingHisSearch/0/10`,{search:e, type:user.type , id:user.id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 0){
            setNodata(true)
          }else if(responce.data.status === 1){ 
            setData(responce.data.details)
            setNodata(false)
            setTotalPages(responce.data.totalPages)
          }
        }
    ).catch((error) => {console.log(error)})
    }
  }
  
  const getBookingDetails = (id) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}bookHistoryView/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
      let status = res.data.status;
        if(status === 1){
          setViewBooking({...viewBooking, userName:res.data.booking.userName , driverName:res.data.booking.driverName , price:res.data.booking.price , type:res.data.booking.ambulanceType , fromAddress:res.data.booking.fromLocation , toAddress:res.data.booking.toLocation })
        }else if(status === 0){
      }
    }).catch((error) => {console.log(error)})
  }

    const table_row =data.map((row , index) => <TableRow key={row.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row" align="center">{(page)*10+index+1}</TableCell>
      <TableCell align="center">{row.userName}</TableCell>
      <TableCell align="center">{row.driverName}</TableCell>
      {userlogCheck.type !== 4&&<TableCell align="center">{row.price} RS</TableCell>}
      <TableCell align="center">{row.ambulanceType}</TableCell>
      <TableCell align="center">{row.fromLocation}</TableCell>
      <TableCell align="center">{row.toLocation}</TableCell>
      <TableCell align="center"> 
          <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:"pointer"}}  onClick={(e)=>{handleOpen(row._id)}}
          />
          <DeleteOutlineOutlinedIcon  sx={{color:'#E11F26',cursor:"pointer"}} onClick={(e)=>{handleOpenDelete(row._id)}}
          />
      </TableCell>
  </TableRow>);
   
    return (
    <>
    
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
              <Grid> 
                <Typography sx={{fontSize:'18px'}}>Booking history</Typography>
              </Grid>
              <Grid>
                <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}}  fullWidth InputProps={{
                    startAdornment: (
                    <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                    ),
                }}/>
              </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
              <TableContainer className="outletBodyTableContainer">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className='headerBg'>
                        <TableCell className='tabel_cell tableHead' align="center">S.NO</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">USER NAME</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">DRIVER NAME</TableCell>
                        {userlogCheck.type !== 4&&<TableCell className='tabel_cell tableHead' align="center">PRICE</TableCell>}
                        <TableCell className='tabel_cell tableHead' align="center">TYPE</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">FROM ADDRESS</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">TO ADDRESS</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        nodata?<TableRow>
                        <TableCell colSpan={8} align="center">
                            <h2> No Data Found</h2>
                        </TableCell>
                      </TableRow>:table_row}
                    </TableBody>                
                </Table>
              </TableContainer>
              
            </Grid>
            <Grid className={'outletBodyFooter'} style={{ display:"flex", alignItems:"right", justifyContent:"right"}}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                  </Stack>
              </Grid>
        </Grid>

    {/* modal */}
    <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={Viewstyle}>
          <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/> 
          <Grid>
              <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                Booking details 
              </Typography>
          </Grid>
          <Box sx={{width: '100%'}} className="modalscroll">
              <Grid className="modalscrollwidth">
                <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}>
                          <FormLabel>User name</FormLabel>
                          <Typography name="name" className={'viewBox'}>{viewBooking.userName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}>
                          <FormLabel>Driver name</FormLabel>
                          <Typography name="name" className={'viewBox'}>{viewBooking.driverName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}>
                          <FormLabel>Price</FormLabel>
                          <Typography name="name" className={'viewBox'}>{viewBooking.price}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}>
                          <FormLabel>Type</FormLabel>
                          <Typography name="name" className={'viewBox'}>{viewBooking.type}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}  >
                          <FormLabel>From</FormLabel>
                          <Typography name="name" className={'viewBox'} fullWidth>{viewBooking.fromAddress}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item sx={{mt:2}}>
                          <FormLabel>To</FormLabel>
                          <Typography name="name"  className={'viewBox'} fullWidth>{viewBooking.toAddress}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                  <Grid item sx={{my:3}} >
                    <Button className="customerLogin" bgColor='#129547' onClick={()=>{setOpen(false)}}>Ok</Button>
                  </Grid> 
                </Grid>
              </Grid>
          </Box>
        </Box>
    </Modal>


      {/*START DELETE MODAL */}
           
      <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={Deletestyle}>
          <Grid className={"titleModal"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to delete the history?
            </Typography>
          </Grid>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
            <Button className="yesButton" onClick={()=>{Delete()}}>Yes</Button>
            <Button className="noButton" onClick={()=>{handleCloseDelete()}}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END DELETE MODAL  */}

      <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{vertical, horizontal}} onClose={ErrorClose}>
        <Alert className="snackError" onClose={ErrorClose} severity="error" >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default BookingHistory; 
