import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
import '../../../Styles/styles.css'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import 'ui-neumorphism/dist/index.css'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function SubAdminLogin() {
    const [open, setOpen] = useState(false);
    const [errMsg , setErrMsg] = useState('')
   const {register,handleSubmit,formState: { errors }} = useForm();

   const handleClose = () => {
 
    setOpen(false);
};

    const[eyeIcon,seteyeIcon]=useState(true);
    const[type,setType] =useState('password')

       const  hidePassword=()=>{
        seteyeIcon(false);
        setType('name')
       }
       const showPassword=()=>{
        seteyeIcon(true);
        setType('password')
       }
       const navigate =  useNavigate();

       const onSubmit = async (data)=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}subAdminLogin`,{email:data.email , password:data.password},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    let userDetails = {'userType':'Sub_admin','id':res.data.details[0]._id , 'name':res.data.details[0].name, 'type':res.data.type};
                    sessionStorage.setItem("userType", JSON.stringify(userDetails)); 
                    navigate('/Sub_admin/Dashboard');
                }else if(status === 0){
                    setOpen(true);
                    setErrMsg(res.data.message);
                }

            }).catch((error) => {console.log(error)})
       }
    
    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                Sub-Admin Login
                </Typography>
            </Grid>
            <Grid container justifyContent='center' lg={12}>
                <Grid container justifyContent='center' lg={6}>
                    <Card className={"login_form"}>
                        <Grid container justifyContent='center' sx={{mt:3}}>
                            <Grid md={8} item sx={{mt:2}}  >
                                <FormLabel sx={{color:"black"}}>Email ID</FormLabel>
                                <TextField name="email" className={'text_field'}  {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></TextField>
                                {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' >
                            <Grid md={8} item  sx={{mt:2}}  className={"parent_position"} >
                                <FormLabel sx={{color:"black"}}>Password</FormLabel>
                                <TextField name="password" type={type} className={'text_field'} {...register('password',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}> </TextField>
                                {errors.password && errors.password.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                {errors.password && errors.password.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                 {eyeIcon?
                                <VisibilityOffOutlinedIcon onClick={hidePassword} className={'eyeicon_position'} />:
                                <RemoveRedEyeOutlinedIcon onClick={showPassword} className={'eyeicon_position'} />}
                                <Typography sx={{cursor:'pointer'}} gutterBottom component="div" color="#E11F26" >
                                    <span onClick={() => {navigate('/sub_admin_forgot_password');}}>Forgot Password?</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained'onClick={handleSubmit(onSubmit)}>Login</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" className="snackError">
              {errMsg}
            </Alert>
        </Snackbar>
    </Container>
    )
}

export default SubAdminLogin;