import Container from '@mui/system/Container';
import { Typography } from 'ui-neumorphism';
import { Grid , List , ListItem } from '@mui/material';
import '../AboutUs/aboutus.css';
import { Box } from '@mui/system';



const PrivacyPolicy = () => {
    return(
        <>
        <Grid className='aboutBg'>
             <Container className='widthSet '>
                <Box sx={{ flexGrow: 1,pt:5,pb:10 }}>
                    <Grid container>
                    <Grid className="aboutresponsive" item md={12} sm={12}>
                        <Grid sx={{mb:2,color:'white' }} component={'h1'} gutterBottom >Privacy Policy</Grid>
                            <Typography className="dataContent">
                                This Privacy Policy outlines the types of personal information collected, used, and shared by our
                                ambulance booking GoAmbee website and Mobile Application and describes how we protect
                                and handle this information. The main aspect of the Application is to book Ambulance as per the
                                needs in the time of Immediate medical conditions. By using the Website, you consent to the
                                practices described in this policy.
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        1. Information We Collect
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        Personal Information: As a part of the usage of application, we may ask to provide some
                                        information for the consent. This information can be collected in various forms in the
                                        steps of registration, contact us and meanwhile booking the Ambulance like:-
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Name
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Mail id
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Password
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Location
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Contact Number
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Payment Details
                                        </ListItem>
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        When you use our Application, we may collect personal information you voluntarilyprovide. This information is kept secured and protected without any use of Malpractice.We may use this information to provide you with timely marketing information.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        Location Information: We may collect and store information about your location if youenable location services on your device. This information is used to provide accurate ambulance booking services.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        Usage Data: We may collect non-personal information about how you use the Website,including pages visited, actions taken, and other usage statistics.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        2. Use of Information
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We use the collected information to facilitate and improve our ambulance bookingservices, respond to inquiries or requests, and provide personalized user experiences.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        Your personal information may be used to communicate with you, send you service-related notifications, updates, and promotional materials.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We may analyze aggregated usage data to monitor and improve the performance of our Website and Mobile Application, enhance security, and develop new features.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        3. Information Sharing and Disclosure
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We may share personal information with ambulance service providers or emergency medical personnel solely for the purpose of facilitating ambulance bookings and ensuring the provision of emergency medical services.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We may disclose personal information to comply with applicable laws, regulations, or legal processes, or to protect our rights, property, or safety, as well as those of our users or the public.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We do not sell, rent, or otherwise provide personal information to third parties for marketing purposes without obtaining explicit consent from you.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        4. Data Security
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We implement appropriate security measures to protect personal information from unauthorized access, disclosure, alteration, or destruction.
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        While we strive to protect your personal information, no method of transmission or storage over the internet is completely secure. Therefore, we cannot guarantee absolute security.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        5. Third-Party Links
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        The Website/Mobile Application may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        6. Changes to this Privacy Policy
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on the Website. It is your responsibility to review the policy periodically for updates.
                                    </ListItem>
                                </List>
                                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                                    <ListItem sx={{ fontWeight: 'bold' }}>
                                        7. Contact Us
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                        If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at.
                                    </ListItem>
                                </List>
                                </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Grid>
            
        </>
    )
}

export default PrivacyPolicy;