import React,{useEffect, useState,useRef} from "react"
import axios from "axios";
import { 
    Grid, 
    TableContainer, 
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead, 
    TableBody,
    Box,
    Button,
    Modal,
    TextField,
    Select,
    MenuItem,
    FormLabel,
    Snackbar,
    Alert,
    Stack,
    Pagination

} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
export const AdminPreBooking = () => {
    const isInitialMount = useRef(true);
    const [preBookingList, setPreBookingList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId , setDeleteId] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [searchData,setSearchData] = useState("");
    const [state , setState] = useState("")
    const [city , setCity] = useState("");
    const [district , setDistrict] = useState("");
    const [states , setStates] = useState([])
    const [districts , setDistricts] = useState([]);
    const [cities , setCities] = useState([]);
    const [openView, setOpenView] = useState(false);
    const [preBookingUser , setPreBookingUser] = useState([
        {
            name:'',
            email:'',
            phone:'',
            state:'',
            city:'',
            district:'',
            date:'',
            hours:''
        }
            ]
      );
    const handleCloseDelete = () => setOpenDelete(false);
    const handleCloseView = () => setOpenView(false);
    const ErrorClose = () => {
        setEmessage(false)
    }
     const SuccessClose=() => {
        setSmessage(false);
    }

    const handleOpenDelete = (id) => {
        setDeleteId(id)
        setOpenDelete(true);
    }
    const handleOpenView = (id) => {
        setDeleteId(id)
        setOpenView(true)
      }
    const handleSearchData = (event) => {
        setSearchData(event.target.value)
    }
    const DeleteStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline:'none',
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid white !important',
        boxShadow: 24,
        outline:'none',
        p:'2% 2% 0% 2%',
        borderRadius:'30px'
      };

    const handleChange = (event, value) => {
        setPage(value-1);
        const payload = {
            page: value-1,
            size:10,
        }
        if(city !== ""){
          payload.city = city
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}getOverallPreBookings`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setPreBookingList(responce.data.details)}
        ).catch((error) => {console.log(error)})
    };
    const getDistricts = (event)=> {
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                }else{
                    setDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const getCities = (event) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:"" , districtId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setCities(responce.data.result)
                }else {
                    setCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const stateChange = (event) => {
        setState(event.target.value)
        setDistrict("")
        setCity("")
        getDistricts(event.target.value)
    }
    const districtChange = (event) => {
        setDistrict(event.target.value);
        setCity("")
        getCities(event.target.value);
    };
    const cityChange = (event) => {
        setCity(event.target.value);
        
    };
    const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
    }
    const DistrictOptions = districts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
    const cityOptions = cities.map(option =><MenuItem key={option._id} value={option._id}>{option.city}</MenuItem>)
    const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)
    
    const geetingPreBooking = async () => {
        const payload = {
            page: 0,
            size:10,
        }
        if (searchData !== "") {
            payload.searchBy = searchData;
        }
        if (state !== "") {
            payload.state = state;
        }
        if (district !== "") { 
            payload.district = district
        }
        if (city !== "") {
            payload.city = city;
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}getOverallPreBookings`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setPreBookingList(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                }
            }
        ).catch((error) => {console.log(error)})
    }


    const getStatusStyle = (status) => {
        switch(status) {
            case 'pending': return { color: '#EB9C25' };
            case 'alloted': return { color: '#00B207' };
            case 'rejected': return { color: '#EB3737' };
            default: return { color: '#32CD32' };
        }
    }
    const TableBodyData = preBookingList.map((row, index) => (
            <TableRow key={row.id}>
                <TableCell align="center">{(page)*10+index+1}</TableCell>
                <TableCell align="center" title={row.name}>{row.name}</TableCell>
                <TableCell align="center" title={row.mobile}>{row.mobile}</TableCell>
                <TableCell align="center" title={row.email}>{row.email}</TableCell>
                <TableCell align="center" title={row.date}>{row.date}</TableCell>
                <TableCell align="center" title={row.status} style={getStatusStyle(row.status)}>{row.status}</TableCell>
                <TableCell align="center" title={row.duration}>{row.duration}</TableCell>
                <TableCell align="center">
                    {row.status==='pending' ?
                        (<CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon"
                        onClick={()=>{handleOpenView(row._id)}}/>):(
                        <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} onClick={()=>{handleOpenView(row._id)}}/>
                        )
                    }
                    <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}} onClick={() => {handleOpenDelete(row._id)}}/>
                </TableCell>
            </TableRow>
    ))

    const GettingPreBookingUserDetails = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getPrebooking/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setPreBookingUser(responce.data.details)
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const Delete = () =>{
        axios.delete(`${process.env.REACT_APP_BASE_URL}deletePreBookings/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setOpenDelete(false)
                    geetingPreBooking();
                }else{
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const AllotRequest = () => {
        axios.patch(`${process.env.REACT_APP_BASE_URL}updatePreBooking/${deleteId}`,{},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setOpenView(false)
                    geetingPreBooking();
                }else{
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }


    useEffect(() => {
        allStates();
        geetingPreBooking();
    }, []);
    
    useEffect(() => {
        if (searchData !== null || state || district || city ) {
            geetingPreBooking();
        }
    }, [searchData, state, district, city]);

    useEffect(() => {
        if(openView){
            GettingPreBookingUserDetails();
        }
    },[openView]);
    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} mb={3} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item md={2}> 
                    <Typography sx={{fontSize:'18px'}}>PreBooking List</Typography>
                </Grid>
                <Grid container md={10} justifyContent={'space-between'}>
                    <Grid item md={2.5} fullWidth>
                        <Select
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={state}
                        placeolder="Select state"
                        onChange={stateChange} >
                            <MenuItem value="">
                                <em>Select State</em>
                            </MenuItem>
                            {stateOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth>
                        <Select 
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={district}
                        placeolder="Select District"
                        onChange={districtChange}
                        >
                        <MenuItem value="">
                            <em>Select district</em>
                        </MenuItem>
                            {DistrictOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth>
                        <Select 
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={city}
                        placeolder="Select City"
                        onChange={cityChange}>
                            <MenuItem value="">
                                <em>Select City</em>
                            </MenuItem>
                            {cityOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth sx={{marginRight:'20px'}}>
                        <TextField className="text_field" placeholder="search"
                         onChange={handleSearchData} 
                         fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899'}} />
                          ),
                        }}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                    <Table  stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">USER NAME</TableCell>
                                <TableCell className="tableHead">PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">DATE</TableCell>
                                <TableCell className="tableHead">STATUS</TableCell>
                                <TableCell className="tableHead">HOURS</TableCell>
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {preBookingList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <h2>No Data Found</h2>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                TableBodyData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                </Stack>
            </Grid>
        </Grid> 
        <Modal
        open={openView}
        // onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                    View details 
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid md={6} item sx={{mt:2}}>
                            <FormLabel>Name</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].name}</Typography>
                        </Grid>
                        <Grid item  md={6} sx={{mt:2}}>
                            <FormLabel>Phone Number</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].mobile}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>Email</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].email}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>Date</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].date}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>State</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].stateName}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>District</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].districtName}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>City</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].cityName}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}>
                            <FormLabel>Hours</FormLabel>
                            <Typography fullWidth name="name" className={'viewBox'}>{preBookingUser[0].duration}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}}>
                           {preBookingUser[0].status==="pending" ?(
                           <Button className="customerLogin" bgColor='#129547' onClick={()=>{AllotRequest()}}>Alot</Button>
                           ):(<Button className="customerLogin" bgColor='#129547' onClick={()=>{handleCloseView()}}>Ok</Button>)}
                        </Grid> 
                    </Grid>
                </Box>
            </Box>
          </Modal>
        <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                 Do you want to delete the pre booking?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" 
                onClick={Delete} 
                >Yes</Button>
                <Button className="noButton" onClick={handleCloseDelete}>No</Button>
            </Grid>
        </Box>
        </Modal>
        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
        </>
    )
}