import React,{useEffect, useState} from "react"
import axios from "axios";
import { 
    Grid , 
    TableContainer, 
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead, 
    TableBody,
    TextField,
    MenuItem,
    Select,
    Pagination,
    Stack
} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
export const AdminPharmacyHistory = () => {

    const [pharmacyClaimedHistory, setPharmacyClaimedHistory] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [serachData,setSearchData] = useState("");
    const [pharmacy,setPharmacy]=useState("");
    const [userPharmacyList, setUserPharmacyList] = useState([]);
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const handleSearchData = (event) => {
      setSearchData(event.target.value)
    }
    const handleChange = (event, value) => {
        setPage(value-1);
        axios.post(`${process.env.REACT_APP_BASE_URL}userClaimList/`,{page:value-1,size:10,type:'pharmacy'},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                setPharmacyClaimedHistory(responce.data.claimList)
            }
        ).catch((error) => {console.log(error)})
    };

    const gettingPharmacyClaimedHistory = async () => {
        const payload = {
            page: 0,
            size: 10,
            type:'pharmacy'
        }
        if (serachData !== "") {
            payload.searchBy = serachData;
        }
        if (pharmacy !== "") {
            payload.filterBy = pharmacy;
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userClaimList`, payload, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } }).then(
            (responce) => {
                if(responce.data.status === 1){
                 setPharmacyClaimedHistory(responce.data.claimList)
                 setTotalPages(responce.data.totalPages)
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const TableBodyData = pharmacyClaimedHistory && pharmacyClaimedHistory.map((row, index) => (
        <TableRow key={row.id}>
            <TableCell align="center">{(page)*10+index+1}</TableCell>
            <TableCell align="center" title={row.userName}>{row.userName}</TableCell>
            <TableCell align="center" title={row.userMobile}>{row.userMobile}</TableCell>
            <TableCell align="center" title={row.unitName}>{row.unitName}</TableCell>
            <TableCell align="center" title={row.unitMobile}>{row.unitMobile}</TableCell>
            <TableCell align="center" title={row.discount}>{row.discount}</TableCell>
        </TableRow>
    ))

    const handleChangePharmacy = (event) => {
      setPharmacy(event.target.value)
    }

    const gettingUserPharmacyList = () => {
        const payload = {
            userId: userlogCheck?.id,
            type:'admin'
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}getPharmacyList`,payload, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
            .then(response => {
                setUserPharmacyList(response.data.pharmaciesList);
            })
        .catch((error) => {console.log(error)})
    }

const TotalPharmacyList = userPharmacyList.map((list,index) => 
  <MenuItem key={index} value={list._id}>{list.name}</MenuItem>
)

useEffect(() => {
    gettingPharmacyClaimedHistory();
    gettingUserPharmacyList();
}, [])

useEffect(() => {
    gettingPharmacyClaimedHistory();
}, [serachData,pharmacy])


    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={'outletBodyHeading'}>
                <Grid> 
                    <Typography sx={{fontSize:'18px'}}>Claimed History</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                  <Grid item md={6} sx={{marginRight:'20px'}}>
                        <Select
                            disableUnderline
                            className='selectOptions'
                            displayEmpty
                            fullWidth
                            value={pharmacy}
                            placeholder="Select Pharmacy"
                            onChange={handleChangePharmacy}
                        >
                            <MenuItem value="">
                                <em>Select Pharmacy</em>
                            </MenuItem>
                            {TotalPharmacyList}
                        </Select>
                      </Grid>

                    <Grid sx={{marginRight:'20px'}}>
                        <TextField className="text_field" 
                        placeholder="Search"
                        onChange={handleSearchData} 
                        fullWidth InputProps={{
                        startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                        ),
                        }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'} sx={{paddingTop:'3rem'}}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">CUSTOMER NAME</TableCell>
                                <TableCell className="tableHead">CUSTOMER PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">PHARMACY NAME</TableCell>
                                <TableCell className="tableHead">PHARMACY PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">DISCOUNT AMOUNT</TableCell>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pharmacyClaimedHistory&&pharmacyClaimedHistory.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">  
                                        <h2>No data Found</h2>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                TableBodyData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                </Stack>
            </Grid>
        </Grid> 
    
        </>
    )
}