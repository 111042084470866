import React,{useEffect,useState} from "react";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow ,
  Backdrop,
  Modal,
  Box,
  Button,
  FormLabel,
  Pagination,
  Alert,
  Snackbar,
  Stack,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Lottie from 'react-lottie'
import AlertJson from '../../../Assets/icons/alert.json'
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { useForm } from 'react-hook-form';
import './SubAdmin.css'


const AdminSubAdmin=()=>{

  const [openView, setOpenView] =useState(false);
  const [deleteId , setDeleteId] =useState("");
  const [openEdit, setOpenEdit] =useState(false);
  const [openBlock, setOpenBlock] =useState(false);
  const [openunBlock, setOpenunBlock] =useState(false);
  const [openDelete, setOpenDelete] =useState(false);
  const [data ,setData] = useState([])
  const [vertical] =useState('top');
  const [horizontal] =useState('right');
  const [totalPages , setTotalPages] =useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [smessage , setSmessage] =useState(false);
  const [successMessage , setSuccessMessage] =useState(false);
  const [emessage , setEmessage] =useState(false);
  const [errorMessage , setErrorMessage] =useState(false);
  const [nodata , setNodata] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const {register,handleSubmit,formState: { errors },reset} = useForm();
  const {
    register:register2,
    handleSubmit:handleSubmit2,
    formState: { errors:errors2 },reset:reset2} = useForm();

  const [viewSubAdmin , setViewSubAdmin] = useState({
    name:"",
    phoneNumber:"",
    email:"",
    password:""
  })

// styles for some icons and modals

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid white !important',
    boxShadow: 24,
    outline:'none',
    p: 4,
    borderRadius:'30px'
  };

  const DeleteStyle = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F1F3F6 !important',
    border: '2px solid white',
    boxShadow: 24,
    outline:'none',
    p: 5,
    textAlign:'center',
    borderRadius:'10px'
  };

  const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: AlertJson,
   renderer: 'svg'
  }

// handling functions to load data on default and to make data empty in modals

  useEffect(() => { loadTable() },[]);
  
  useEffect(() => { reset() },[reset]);

// functions for handling modals and messages

  const handleCloseView = () => setOpenView(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseBlock = () => setOpenBlock(false);
  const handleCloseunBlock = () => setOpenunBlock(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const ErrorClose = () => setEmessage(false);
  const SuccessClose=() => setSmessage(false);

// functionality to add SubAdmin

  const onSubmit = async (Form_data)=>{
    setLoaderOpen(true)
      axios.post(`${process.env.REACT_APP_BASE_URL}addSubadmin`,{name:Form_data.name , phone:Form_data.phone , email:Form_data.email},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
              let status = res.data.status;
              if(status === 1){
                setLoaderOpen(false)
                setSuccessMessage(res.data.message)
                setSmessage(true);
                setOpen(false);
                loadTable();
                  
              }else if(status === 0){
                setErrorMessage(res.data.message)
                  setEmessage(true)
                  setLoaderOpen(false)
              }
              reset();

          }).catch((error) => {console.log(error)})
  }

// functionality to handle view details modal

  const handleOpenView = (id) => {
    setDeleteId(id)
    getSubAdminDetails(id)
    setOpenView(true)
    
  }

// functionality to handle edit details modal

const handleOpenEdit = (id) => {
  setDeleteId(id)
  getSubAdminDetails(id)
  setOpenEdit(true)

}

// to get SubAdmin data from id

const getSubAdminDetails = (id) => {

  axios.get(`${process.env.REACT_APP_BASE_URL}getSubadminById/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
    let status = res.data.status;
    if(status === 1){
      setViewSubAdmin({name:res.data.details.name , phoneNumber:res.data.details.phone , email:res.data.details.email , password:res.data.details.password})
    }else if(status === 0){
      
    }
  }).catch((error) => {console.log(error)})

}

// functionlity for opening Deleting subAdmin modal

  const handleOpenDelete = (id) => {
    setOpenDelete(true)
    setDeleteId(id)
  };

// functionlity for Deleting subAdmin

  const Delete = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}deleteSubadmin/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 1){
          setSmessage(true)
          setSuccessMessage(responce.data.message)
          setOpenDelete(false)
          loadTable()
        }else{
          setEmessage(true)
          setErrorMessage(responce.data.message)
        }
      }
    ).catch((error) => {console.log(error)})
  }

// functionlity for Block/unBlock modals open

  const handleOpenBlock = (id , type) => {
    if(type === "block"){
      setOpenBlock(true)
      setDeleteId(id)
    }else if(type === "Unblock"){
      setOpenunBlock(true)
      setDeleteId(id)
    }
  };

// SubAdmin Block functionality 

  const Block = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockSubadmin/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setSmessage(true);
            setSuccessMessage(responce.data.message)
            setOpenBlock(false)
            loadTable()
          }else{
            setEmessage(true);
            setErrorMessage(responce.data.message)
          }
      }
  ).catch((error) => {console.log(error)})
  }

// SubAdmin unblock functionality 

  const unBlock = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockSubadmin/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setSmessage(true);
            setSuccessMessage(responce.data.message)
            setOpenunBlock(false)
            loadTable()
          }else{
            setEmessage(true);
            setErrorMessage(responce.data.message)
          }
      }
    ).catch((error) => {console.log(error)})
  }

 

// SubAdmin table data loading

  const loadTable = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}getSubadminList/0/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          setData(responce.data.details)
          setTotalPages(responce.data.totalPages)
        }
    ).catch((error) => {console.log(error)})
  }

// search filter functionality

  const searchFilter = (e) =>{
    if(e === ""){
      setNodata(false)
      loadTable();
    }else{
      axios.post(`${process.env.REACT_APP_BASE_URL}subAdminSearch/0/10`,{search:e},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 0){
            setNodata(true)
          }else if(responce.data.status === 1){
            setData(responce.data.details)
            setNodata(false)
            setTotalPages(responce.data.totalPages)
          }
        
        }
    ).catch((error) => {console.log(error)})
    }
    
  }
 
// pagination change handling
  
  const handleChange = (event, value) => {
    setPage(value-1);
      axios.get(`${process.env.REACT_APP_BASE_URL}getSubadminList/${value-1}/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {setData(responce.data.details)}
    ).catch((error) => {console.log(error)})
  };

// handing edited data

const onEditSubmit = (Form_data) => {
  // console.log(viewSubAdmin)
  axios.post(`${process.env.REACT_APP_BASE_URL}editSubadmin`,{name:viewSubAdmin.name , phone:viewSubAdmin.phoneNumber , email:viewSubAdmin.email , password:viewSubAdmin.password , id:deleteId},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
    (responce) => {
      if(responce.data.status === 1){
        setSmessage(true)
        setSuccessMessage(responce.data.message)
        handleCloseEdit()
        loadTable();
      }else if(responce.data.status === 0){
        setEmessage(true)
        setErrorMessage(responce.data.message)
      }
    }
  ).catch((error) => {console.log(error)})
}

// table of SubAdmin and their details

  const table_row = data.map((row , index) => <TableRow key={index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <TableCell align="center" component="td" scope="row">{(page)*10+index+1}</TableCell>
        <TableCell align="center" component="td" title={row.name}>{row.name}</TableCell>
        <TableCell align="center" component="td" title={row.phone}>{row.phone}</TableCell>
        <TableCell align="center" component="td" title={row.email}>{row.email}</TableCell>
        <TableCell align="center" component="td">
            <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
            onClick={()=>{handleOpenView(row._id)} }
            />
            <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
            onClick={()=>{handleOpenEdit(row._id)} }
            />
            <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}}
             onClick={()=>{handleOpenDelete(row._id)} }
             />
             {!row.blockStatus?<BlockOutlinedIcon className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "Unblock")}}
             />:<BlockOutlinedIcon className="actioinIcon" sx={{cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "block")}}
             />}
        </TableCell>
      </TableRow>);
     
    return(
        <>
          <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid > 
                    <Typography sx={{fontSize:'18px'}}>Sub admin</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                    <Grid>
                        <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                          ),
                        }}/>
                    </Grid>
                    <Grid sx={{ display: 'flex',alignItems:'center',mx:3 }}>
                        <Typography className="add_text"> Add </Typography>
                        <AddCircleIcon className="Add_icon" onClick={handleClickOpen} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead className="tableHeader">
                      <TableRow>
                          <TableCell className="tableHead">S.NO</TableCell>
                          <TableCell className="tableHead">NAME</TableCell>
                          <TableCell className="tableHead">PHONE NUMBER</TableCell>
                          <TableCell className="tableHead">EMAIL</TableCell>
                          <TableCell className="tableHead">ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data.length===0 ?<TableRow>
                        <TableCell colSpan={8} align="center">
                            <h2> No Data Found</h2>
                        </TableCell>
                      </TableRow>:table_row}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid className={'outletBodyFooter'} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                      <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
          </Grid> 
          
<Modal
  open={open}
  // onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description">
    <Box sx={style}>
      <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
      <Grid container direction="row" justifyContent="center" alignItems="center" id="modal-modal-description" sx={{ mt: 2 }}>
          <Typography className="titleName" id="modal-modal-title" variant="h6" align="center" component="h2">
            Add Sub Admin
          </Typography>
        <Grid container justifyContent='center'>
          <Grid md={10} item sx={{mt:2}}  >
            <FormLabel>Name</FormLabel>
            <TextField 
            name="name"  
            className={'text_field'} 
            {...register('name',{required: true})}
            />
            {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
          </Grid>
          <Grid md={10} item sx={{mt:2}}  >
            <FormLabel>Phone Number</FormLabel>
            <TextField name="phone" className={'text_field'} {...register('phone',{required: true,pattern:/^\d{10}$/})}></TextField>
            {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
            {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid number</p>}
          </Grid>
          <Grid item md={10} sx={{mt:2}}  >
            <FormLabel>Email ID</FormLabel>
            <TextField name="email" className={'text_field'} {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}></TextField>
            {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
            {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>Please enter valid email</p>}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' sx={{my:3}}>
        <Button className="customerLogin" bgColor='#129547' onClick={handleSubmit(onSubmit)}>Add</Button>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
            invisible={true}
          >
            <Lottie 
              options={defaultOptions}
              height={100}
              width={100}
            />
          </Backdrop>
      </Grid>
    </Box>
</Modal>
                   
{/* modal */}

{/*Start View Modal */}
  <Modal
    open={openView}
    // onClose={handleCloseView}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
      <Box sx={style}>
        <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
        <Grid>
            <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
            Sub admin details
            </Typography>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Name</FormLabel>
                  <Typography fullWidth name="name" className={'viewBox'} >{viewSubAdmin.name}</Typography>
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Phone number</FormLabel>
                <Typography fullWidth name="name" className={'viewBox'} >{viewSubAdmin.phoneNumber}</Typography>
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Email</FormLabel>
                <Typography fullWidth name="name" className={'viewBox'} >{viewSubAdmin.email}</Typography>
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Password</FormLabel>
                <Typography fullWidth name="name" className={'viewBox'} >{viewSubAdmin.password}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item sx={{my:3}} >
                <Button className="customerLogin"bgColor='#129547' onClick={()=>{handleCloseView()}} >Ok</Button>
            </Grid> 
          </Grid>
        </Box>
      </Box>
  </Modal>
{/* End View Modal */}

{/* Start Edit Modal */}
  <Modal
    open={openEdit}
    // onClose={handleCloseEdit}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
      <Box sx={style}>
        <CloseIcon onClick={()=>{handleCloseEdit()}} className={"closeIconPosition"}/>
        <Grid>
            <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
            Edit sub admin
            </Typography>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={10}>
              <Grid  item sx={{mt:2}}  >
                <FormLabel>Name</FormLabel>
                <TextField fullWidth value={viewSubAdmin.name} name="name" className={'text_field '} {...register2('editName',{required: !Boolean(viewSubAdmin.name)})} 
                onChange={(e)=>{setViewSubAdmin({...viewSubAdmin, name:e.target.value})}}
                />{viewSubAdmin.name === "" && errors2.editName && errors2.editName.type === "required" && <p className='error mt-0'>Please enter name</p>}
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Phone number</FormLabel>
                <TextField fullWidth value={viewSubAdmin.phoneNumber} name="name" className={'text_field '}  {...register2('editPhone',{required: !Boolean(viewSubAdmin.phoneNumber),pattern:/^\d{10}$/})}
                onChange={(e)=>{setViewSubAdmin({...viewSubAdmin, phoneNumber:e.target.value})}}
                />{viewSubAdmin.phoneNumber === "" && errors2.editPhone && errors2.editPhone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                {errors2.editPhone && errors2.editPhone.type === "pattern"  && <p className='error mt-0'>Please enter valid number</p>}
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}}  >
                <FormLabel>Email</FormLabel>
                <TextField  fullWidth value={viewSubAdmin.email} name="name" className={'text_field '}  {...register2('editEmail',{required: !Boolean(viewSubAdmin.email),pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                onChange={(e)=>{setViewSubAdmin({...viewSubAdmin, email:e.target.value})}}
                />{viewSubAdmin.email === "" && errors2.editEmail && errors2.editEmail.type === "required" && <p className='error mt-0'>Please enter email</p>}
                {errors2.editEmail && errors2.editEmail.type === "pattern" && <p className='error mt-0'>Please enter valid email</p>}
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Grid item sx={{mt:2}} className={"parent_position"}  >
                <FormLabel>Password</FormLabel>
                <TextField  fullWidth value={viewSubAdmin.password} name="name" className={'text_field '} {...register2('editPassword',{required: !Boolean(viewSubAdmin.password),pattern:"/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/"})} 
                onChange={(e)=>{setViewSubAdmin({...viewSubAdmin, password:e.target.value})}}
                />{viewSubAdmin.password==="" && errors2.editPassword && errors2.editPassword.type === "required" && <p className='error mt-0'>Please enter password</p>}
                {errors2.editPassword && errors2.editPassword.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item sx={{my:3}} >
              <Button  className="customerLogin" bgColor='#129547' onClick={handleSubmit2(onEditSubmit)}>Update</Button>
            </Grid> 
          </Grid>
        </Box>
      </Box>
  </Modal>
{/* End Edit Modal */}

{/*START DELETE MODAL */}
           
      <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete the Sub admin?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
              <Button className="yesButton" onClick={Delete} >Yes</Button>
             <Button className="noButton" onClick={handleCloseDelete}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END DELETE MODAL  */}
    {/*START BLOCK MODAL  */}
      <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to block the sub admin?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
              <Button className="yesButton" onClick={Block}>Yes</Button>
              <Button className="noButton"  onClick={handleCloseBlock}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END BLOCK MODAL  */}
    {/*START UNBLOCK MODAL  */}
    <Modal
        open={openunBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to un-block the sub admin?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
              <Button className="yesButton" onClick={unBlock}>Yes</Button>
              <Button className="noButton" onClick={handleCloseunBlock}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END UNBLOCK MODAL  */}

      <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar  open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert className="snackError" onClose={ErrorClose} severity="error" >
          {errorMessage}
        </Alert>
      </Snackbar>
            
    </>

    )
}

export default AdminSubAdmin