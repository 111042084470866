import React from 'react'
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
// import './Hospital_login'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import { useState } from "react";
import 'ui-neumorphism/dist/index.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useParams } from "react-router-dom";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const PharmacyCreatePassword=()=> {

    let { email , token } = useParams();
    const[type,setType] =useState('password');
    const[confirmtype,setConfirmType] =useState('password');
    const[eyeIcon,seteyeIcon]=useState(true);
    const[confirmeyeIcon,setConfirmeyeIcon]=useState(true);
    const[errorMessage , setErrorMessage] = useState("")
    const[successMessage , setSuccessMessage] = useState("")
    const [open, setOpen] = useState(false);
    const [sopen, setSopen] = React.useState(false);
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const[password , setPassword] =useState(false);
    
    function handleClose() {
        setOpen(false);
    }

    const  hidePassword=()=>{
        seteyeIcon(false);
        setType('name')
    }

    const  showPassword=()=>{
        seteyeIcon(true);
        setType('password')
    }

    const  confirmhidePassword=()=>{
        setConfirmeyeIcon(false);
        setConfirmType('name')
    }

    const  confirmshowPassword=()=>{
        setConfirmeyeIcon(true);
        setConfirmType('password')
    }


    const handleErrorClose=()=>{
        setSopen(false);
    }

    const {register,handleSubmit,formState: { errors }} = useForm();

    const navigate =  useNavigate();

    const onSubmit = async (data)=>{
        const payload = {
            password: data.newPassword,
            secretToken:token,
            email:email,
       }
        let inputValue = document.getElementById("check_password").value; 
        if(inputValue !== data.newPassword){
            setPassword(true);
        }else{
            if(password === true){
                setPassword(true);
            }
            axios.post(process.env.REACT_APP_BASE_URL+'pharmacyResetPassword/',payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setOpen(true);
                    setSuccessMessage(res.data.message)
                    setTimeout(()=>{navigate('/PharmacyLogin');}, 5000);
                }else if(status === 0){
                    setSopen(true);
                    setErrorMessage(res.data.message)
                }
            }).catch((error) => {console.log(error)})
         }     
        }

  return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                    Reset Password
                </Typography>
            </Grid>
            <Grid container justifyContent='center' lg={12}>
                <Grid container  justifyContent='center' lg={6}>
                    <Card className={"login_form"} >
                        <Grid container justifyContent='center'>
                            <Grid item sx={{mt:3}}  className={"parent_position"}>
                                <FormLabel sx={{color:"black"}}>New Password&nbsp;&nbsp;&nbsp;&nbsp;</FormLabel>
                                <TextField type={type} className={'text_field'} placeholder='Enter new password'{...register('newPassword',
                                {
                                    required: true,
                                    pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/
                                })}
                                />
                                {eyeIcon?
                                    <VisibilityOffOutlinedIcon onClick={hidePassword} className={'eyeicon_position'} />:
                                    <RemoveRedEyeOutlinedIcon onClick={showPassword} className={'eyeicon_position'} />
                                }
                                {errors.newPassword && errors.newPassword.type === "required" && <p className='error mt-0'>Please enter new password</p>}
                                {errors.newPassword && errors.newPassword.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                <Typography sx={{width: '345px', color:'#979899', fontSize:12}}>* Must contain one lowercase, uppercase, numeric and 
                                Special Characters with atleast 8 characters</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{mt:3}} className={"parent_position"} >
                                <FormLabel sx={{color:"black"}}>Confirm Password</FormLabel>
                                <TextField type={confirmtype} id="check_password" className={'text_field'} placeholder='Enter confirm password' /> 
                                {confirmeyeIcon?
                                    <VisibilityOffOutlinedIcon onClick={confirmhidePassword} className={'eyeicon_position'} />:
                                    <RemoveRedEyeOutlinedIcon onClick={confirmshowPassword} className={'eyeicon_position'} />}
                                {password && <p className='error mt-0'>Password mismatch</p>}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained' onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={sopen} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
  )
}

export default PharmacyCreatePassword
