import React, { useEffect, useState } from "react"
import axios from "axios";
import { Grid,Button,FormLabel,TextField,Select,MenuItem, FormControl } from "@mui/material"
import Container from '@mui/system/Container';
import { useForm } from 'react-hook-form';
import { Typography } from "ui-neumorphism";
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'react-lottie'
import AlertJson from '../../../Assets/icons/alert.json'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import ContactUs from '../../../Assets/icons/contactUs.png';
export const UserContactUs = () => {
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const {register,handleSubmit,reset,formState: { errors }} = useForm();
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [logCheck , setLogCheck] = useState("")
    const [logError , setLogError] = useState(false);
    const authKey = localStorage.getItem("Key");
    const [secureKey , setSecureKey] = useState("")
    
    const navigate =  useNavigate();
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
      }
      const ErrorClose = () => setEmessage(false);
      const SuccessClose=() => setSmessage(false);
        const handleClose = () => setOpen(false);
        
        const onSubmit = (data) => {
            const  payload = {
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.message,
                createdBy: userlogCheck?.id
            };
            setLoaderOpen(true);
            axios.post(`${process.env.REACT_APP_BASE_URL}addUserEnquiry/`, payload, {
                headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}
             }).then((response) => {
                    if(response.data.status === 1){
                        setLoaderOpen(false);
                        setSmessage(true);
                        setSuccessMsg(response.data.message)
                        reset();
                    }else{
                        setLoaderOpen(false);
                        setEmessage(true)
                        setErrorMsg(response.data.message)
                    }
                })
                .catch((error) => {if(error.response.status === 401){
                    setErrorMsg("Your acoount was logged in from another device")
                    setEmessage(true)
                    setTimeout(() => {
                        userLogOut();
                    }, 2000);
                    
                }})
            }

            const userLogOut = () =>{
                sessionStorage.removeItem("userType");
                localStorage.removeItem("latitude");
                localStorage.removeItem("longitude");
                navigate('/homepage');
                window.location.reload();
            }
            const logging = () =>{
                if(userlogCheck !== null){
                    setLogCheck(false);
                    if(authKey !== null){
                      setSecureKey(authKey);
                    }
                }else{
                    setLogCheck(true);
                }
            }
    useEffect(() => {
        logging();
    }, [])
    return(
        <>
        <Grid className='aboutBg' py={5}>
            <Container className="widthSet"> 
                <Grid component={'h2'} pb={3} className="subscriptionText">Contact Us</Grid>
                <Typography className={'contactUsText'}>
                    Have any question or feedback, feel free to reach out to us. We are always available to help.
                </Typography>
                <Grid container justifyContent={'space-between'} alignItems={'center'} pt={5}>
                    <Grid item md={6} lg={6}>
                        <img src={ContactUs} alt="ambulance" className="ambulanceImg"/>
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Grid component={'form'} container justifyContent={'center'} onSubmit={handleSubmit(onSubmit)}>
                            <Grid md={12} lg={7} item>
                                <Grid item md={12} lg={12} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Full Name</FormLabel>
                                        <TextField 
                                        fullWidth 
                                        className={'userTextField'} 
                                        type={'text'}
                                        autoComplete="off"
                                        placeholder={'Enter Full Name'}
                                        {...register('name',{required: true})} 
                                        />
                                        {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item  md={12} lg={12} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Phone Number</FormLabel>
                                        <TextField  
                                        className={'userTextField'}
                                        placeholder={'Enter Phone Number'} 
                                        {...register('phone',{required: true,pattern:/^\d{10}$/})}
                                        autoComplete="off"
                                        />
                                        {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                        {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} lg={12} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Email</FormLabel>
                                        <TextField 
                                        className={'userTextField'}
                                        placeholder={'Enter Email'} 
                                        {...register('email',{required: true, pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                        autoComplete="off"
                                        />
                                        {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email </p>}
                                        {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>Please enter valid email</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} lg={12} mt={3} width={'100%'}>
                                    <FormControl fullWidth>
                                        <FormLabel className={'userFormLabel'}>Message</FormLabel>
                                        <Grid>
                                            <TextField 
                                            multiline 
                                            rows={4} 
                                            className={'userDescriptionField'} 
                                            placeholder={'Enter Description'} 
                                            {...register('message',{required: true})}
                                            sx={{width:'100%',color:'white'}}
                                            />
                                        </Grid>
                                        {errors.message && errors.message.type === "required" && <p className='error mt-0'>Please enter description </p>}
                                    </FormControl>
                                </Grid>
                                <Grid container justifyContent="center">
                                   <Button className='userBuyButton' type="submit" size="medium"  >Submit</Button>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={loaderOpen}
                                        onClick={handleClose}
                                        invisible={true}
                                    >
                                    <Lottie 
                                        options={defaultOptions}
                                        height={100}
                                        width={100}
                                    />
                                    </Backdrop>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error" className="snackError">
          {errorMsg}
        </Alert>
    </Snackbar>
        </>
    )
}