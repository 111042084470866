import React from 'react'
import { Routes,Route,Navigate } from 'react-router-dom';
import Login from '../Components/Admin/login/login';
import CustomerLogin from '../Components/Customer/login/Customer_login';
import EmployeesLogin from '../Components/Employees/login/Employee_login';
import HospitalLogin from '../Components/Hospital/login/Hospital_login';
import SubAdminLogin from '../Components/Sub_admin/login/Sub_admin_login';
import AdminForgotPassword from '../Components/Admin/login/forgot_password';
import CustomerForgotPassword from '../Components/Customer/login/Customer_forgot';
import EmployeeForgotPassword from '../Components/Employees/login/Employee_forgot';
import HospitalForgotPassword from '../Components/Hospital/login/Hospital_forgot';
import SubAdminForgotPassword from '../Components/Sub_admin/login/Sub_admin_forgot';
import Admin from '../Components/Admin/Admin';
import Dashboard from '../Components/Admin/Dashboard/dahboard';
import Ambulance from '../Components/Admin/Ambulancs/ambulance';
import AdminSubAdmin from '../Components/Admin/SubAdmin/sub_admin';
import AdminHospitals from '../Components/Admin/Hospitals/hospitals';
import AdminEmployees from '../Components/Admin/Employees/employees';
import AdminCustomers from '../Components/Admin/Customers/customers';
import AdminBooking from '../Components/Admin/Booking/booking';
import AdminHospitalLogos from '../Components/Admin/HospitalLogos/Hospital_logos';
import SubAdmin from '../Components/Sub_admin/subAdmin';
import Employee from '../Components/Employees/employee';
import NoMatch from './Nomatch';
import Hospital from '../Components/Hospital/Hospital';
import AdminCreatePassword from '../Components/Admin/login/create_password';
import MainPage from '../Components/User/MainPage/mainpage';
import AboutUs from '../Components/User/AboutUs/aboutus';
import PublicRoutes from './PublicRoutes';
import AdminPrivateRoutes from './AdminPrivateRoutes';
import SubAdminPrivateroutes from './SubAdminPrivateroutes';
import HospitalPrivateRoutes from './HospitalPrivateRoutes';
import EmployeePrivateRoutes from './EmployeePrivateRoutes';
import SubadminCreatePassword from '../Components/Sub_admin/login/ResetPassword';
import HsptlResetPassword from '../Components/Hospital/login/HsptlResetPassword';
import EmployeeResetPassword from '../Components/Employees/login/EmployeeResetPassword';
import User from '../Components/User/user';
import AmbulanceDriver from '../Components/Admin/Ambulancs/ambulanceDriver';
import TypePrice from '../Components/Admin/Ambulancs/typePrice';
import OnGoing from '../Components/Admin/Booking/ongoing';
import BookingHistory from '../Components/Admin/Booking/booking_history'
import UserBooking from '../Components/User/UserBooking/userbooking';
import Sos from '../Components/User/Sos/sos';
import History from '../Components/User/History/history';
import OngoingTracking from '../Components/Admin/Tracking/ongoingtraking';
import Wallet from '../Components/Admin/Ambulancs/wallet';
import OtherPrice from '../Components/Admin/Ambulancs/otherPrice';
import WalletApproval from '../Components/Admin/walletApproval/walletApproval';
import NoAmbulance from '../Components/Admin/NoAmbulance/NoAmbulance';
import Terms from '../Components/User/Terms/terms';
import PrivacyPolicy from '../Components/User/PrivacyPolicy/privacyPolicy';
import LiveStatus from '../Components/Admin/LiveStatus/LiveStatus';
import { SubscriptionPlan } from '../Components/User/subscription/subscriptionPlan';
import { PreBooking } from '../Components/User/preBooking/preBooking';
import { AdminPreBooking } from '../Components/Admin/PreBooking/preBooking';
import { AdminSubscriptionPlan } from '../Components/Admin/Subscriptions/plans';
import { AdminPlansHistory } from '../Components/Admin/Subscriptions/plansHistory';
import Subscription from '../Components/Admin/Subscriptions/subscription';
import AdminPharmacy from '../Components/Admin/Pharmcy/Pharmacy';
import { AdminPharmacyList } from '../Components/Admin/Pharmcy/PharmcyList';
import PharmacyLogin from '../Components/Pharmacy/Login/login';
import PharmacyForgotPassword from '../Components/Pharmacy/Login/forgotPassward';
import Pharmacy from '../Components/Pharmacy/Pharmacy';
import Laboratory from '../Components/Laboratory/laboratory';
import { UserPharmacy } from '../Components/User/Pharmacy/pharmacy';
import { UserLabouratory } from '../Components/User/Labouratory/labouratory';
import { AdminLabouratoryList } from '../Components/Admin/Labouratory/list';
import AdminLabouratory from '../Components/Admin/Labouratory/Labouratory';
import {AdminLaboratoryHistory} from '../Components/Admin/Labouratory/history';
import LabouratoryCreatePassword from '../Components/Laboratory/Login/createPassword';
import PharmacyCreatePassword from '../Components/Pharmacy/Login/createPassword';
import LaboratoryLogin from '../Components/Laboratory/Login/login';
import LaboratoryForgotPassword from '../Components/Laboratory/Login/forgotPassward'; 
import { PharmacyCustomers } from '../Components/Pharmacy/customers';
import { LaboratoryCustomers } from '../Components/Laboratory/customers';
import { AdminPharmacyHistory } from '../Components/Admin/Pharmcy/PharmacyHistory';
import { PharmacyHistory } from '../Components/Pharmacy/history';
import { LaboratoryHistory } from '../Components/Laboratory/history';
import { UserContactUs } from '../Components/User/ContactUs/contactUs';
import  AdminContactUs  from '../Components/Admin/ContactUs/contactUs';
import { AdminContactUsDriver } from '../Components/Admin/ContactUs/driver';
import { AdminContactUsUser } from '../Components/Admin/ContactUs/user';
const Routes_comp = () => {
  return (
   <>
   <Routes>
      <Route path="adminLogin" element={<PublicRoutes component={Login} />}></Route>
      <Route path="/adminLogin" element={<PublicRoutes component={Login} />}></Route>
      <Route path="customer" element={<CustomerLogin/>}></Route>
      <Route path="EmployeesLogin" element={<PublicRoutes component={EmployeesLogin} />}></Route>
      <Route path="HospitalLogin" element={<PublicRoutes component={HospitalLogin}/>}></Route>
      <Route path="subAdmin" element={<PublicRoutes component={SubAdminLogin} />}></Route>
      <Route path="PharmacyLogin" element={<PublicRoutes component={PharmacyLogin} />}/>
      <Route path="LaboratoryLogin" element={<PublicRoutes component={LaboratoryLogin} />}/>
      <Route path="admin_forgot_password" element={<AdminForgotPassword/>} ></Route>
      <Route path="customer_forgot_password" element={<CustomerForgotPassword />}></Route>
      <Route path="employee_forgot_password" element={<EmployeeForgotPassword/>}></Route>
      <Route path="hospital_forgot_password" element={<HospitalForgotPassword/>}></Route>
      <Route path="sub_admin_forgot_password" element={<SubAdminForgotPassword/>}></Route>
      <Route path="Pharmacy_forgot_password" element={<PharmacyForgotPassword/>}/>
      <Route path="Laboratory_forgot_password" element={<LaboratoryForgotPassword/>}/>
      <Route path="AdminCreatePassword/:email/:token" element={<AdminCreatePassword/>}></Route>
      <Route path="SubadminCreatePassword/:email/:token" element={<SubadminCreatePassword/>}></Route>
      <Route path="HsptlResetPassword/:email/:token" element={<HsptlResetPassword/>}></Route>
      <Route path="EmployeeResetPassword/:email/:token" element={<EmployeeResetPassword/>}></Route>
      <Route path="PharmacyCreatePassword/:email/:token" element={<PharmacyCreatePassword/>}/>
      <Route path="LaboratoryCreatePassword/:email/:token" element={<LabouratoryCreatePassword/>}/>


      {/* Admin Routes */}
      <Route path="admin" element={<AdminPrivateRoutes component={Admin} />}>
         <Route index element={<AdminPrivateRoutes component={Dashboard} />}></Route>
         <Route path="Dashboard" element={<AdminPrivateRoutes component={Dashboard} />}></Route>
         <Route path="SubAdmin" element={<AdminPrivateRoutes component={AdminSubAdmin} />}></Route>
         <Route path="Ambulance" element={<AdminPrivateRoutes component={Ambulance} />}>
            <Route index element={<Navigate  to="AmbulanceDriver" />} />
            <Route path="AmbulanceDriver" element={<AdminPrivateRoutes component={AmbulanceDriver} />}></Route>
            <Route path="TypePrice" element={<AdminPrivateRoutes component={TypePrice} />}></Route>
            <Route path="Wallet" element={<AdminPrivateRoutes component={Wallet} />}></Route>
            <Route path="OtherPrice" element={<AdminPrivateRoutes component={OtherPrice} />}></Route>
         </Route>
         <Route path="Hospitals" element={<AdminPrivateRoutes component={AdminHospitals} />}></Route>
         <Route path="Employees" element={<AdminPrivateRoutes component={AdminEmployees} />}></Route>
         <Route path="Customers" element={<AdminPrivateRoutes component={AdminCustomers} />}></Route>
         <Route path="Booking" element={<AdminPrivateRoutes component={AdminBooking} />}>
            <Route index element={<Navigate  to="OnGoing" />} />
            <Route path="OnGoing" element={<AdminPrivateRoutes component={OnGoing} />}></Route>
            <Route path="BookingHistory" element={<AdminPrivateRoutes component={BookingHistory} />}>
            </Route>
         </Route>
         <Route path="HospitalLogos"  element={<AdminPrivateRoutes component={AdminHospitalLogos} />}></Route>
         <Route path="WalletApproval"  element={<AdminPrivateRoutes component={WalletApproval} />}></Route>
         <Route path="NoAmbulanceAvailable"  element={<AdminPrivateRoutes component={NoAmbulance} />}></Route>
         <Route path="LiveStatus"  element={<AdminPrivateRoutes component={LiveStatus} />}></Route>
         <Route path="Subscription" element={<AdminPrivateRoutes component={Subscription} />}>
            <Route index element={<Navigate  to="SubscriptionPlan" />} />
            <Route path="SubscriptionPlan" element={<AdminPrivateRoutes component={AdminSubscriptionPlan} />}></Route>
            <Route path="PlansHistory" element={<AdminPrivateRoutes component={AdminPlansHistory} />}>
            </Route>
         </Route>
         <Route path="PreBooking"  element={<AdminPrivateRoutes component={AdminPreBooking} />}></Route>
         <Route path="Pharmacy" element={<AdminPrivateRoutes component={AdminPharmacy} />}>
            <Route index element={<Navigate  to="List" />} />
            <Route path="List" element={<AdminPrivateRoutes component={AdminPharmacyList} />}></Route>
            <Route path="ClaimedHistory" element={<AdminPrivateRoutes component={AdminPharmacyHistory} />}>
            </Route>
         </Route>
         <Route path="Labouratory" element={<AdminPrivateRoutes component={AdminLabouratory} />}>
            <Route index element={<Navigate  to="List" />} />
            <Route path="List" element={<AdminPrivateRoutes component={AdminLabouratoryList} />}></Route>
            <Route path="ClaimedHistory" element={<AdminPrivateRoutes component={AdminLaboratoryHistory} />}>
            </Route>
         </Route>
         <Route path="ContactUs" element={<AdminPrivateRoutes component={AdminContactUs} />}>
            <Route index element={<Navigate  to="User" />} />
            <Route path="User" element={<AdminPrivateRoutes component={AdminContactUsUser} />}></Route>
            <Route path="Driver" element={<AdminPrivateRoutes component={AdminContactUsDriver} />}>
            </Route>
         </Route>
      </Route>

      {/* subAdmin */}
      <Route path="Sub_admin" element={<SubAdminPrivateroutes component={SubAdmin} />}>
         <Route index element={<SubAdminPrivateroutes component={Dashboard} />}></Route>
         <Route path="Dashboard" element={<SubAdminPrivateroutes component={Dashboard} />}></Route>
         <Route path="Employees" element={<SubAdminPrivateroutes component={AdminEmployees} />}></Route>
         <Route path="Ambulance" element={<SubAdminPrivateroutes component={Ambulance} />}>
            <Route index element={<Navigate  to="AmbulanceDriver" />} />
            <Route path="AmbulanceDriver" element={<SubAdminPrivateroutes component={AmbulanceDriver} />}></Route>
            <Route path="TypePrice" element={<SubAdminPrivateroutes component={TypePrice} />}></Route>
         </Route>
         <Route path="Hospitals" element={<SubAdminPrivateroutes component={AdminHospitals} />}></Route>
         <Route path="Customers" element={<SubAdminPrivateroutes component={AdminCustomers} />}></Route>
         <Route path="Booking" element={<SubAdminPrivateroutes component={AdminBooking} />}>
            <Route index element={<Navigate  to="OnGoing" />} />
            <Route path="OnGoing" element={<SubAdminPrivateroutes component={OnGoing} />}></Route>
            <Route path="BookingHistory" element={<SubAdminPrivateroutes component={BookingHistory} />}>
            </Route>
         </Route>
         <Route path="LiveStatus"  element={<SubAdminPrivateroutes component={LiveStatus} />}></Route>
      </Route>
      {/* End subAdmin */}

      {/* Employees */}
      <Route path="Employees" element={<EmployeePrivateRoutes component={Employee} />}>
         <Route index element={<EmployeePrivateRoutes component={Dashboard} />}></Route>
         <Route path="Dashboard" element={<EmployeePrivateRoutes component={Dashboard} />}></Route>
         <Route path="Ambulance" element={<EmployeePrivateRoutes component={Ambulance} />}>
            <Route index element={<Navigate  to="AmbulanceDriver" />} />
            <Route path="AmbulanceDriver" element={<EmployeePrivateRoutes component={AmbulanceDriver} />}></Route>
            <Route path="TypePrice" element={<EmployeePrivateRoutes component={TypePrice} />}></Route>
         </Route>
         <Route path="Hospitals" element={<EmployeePrivateRoutes component={AdminHospitals} />}></Route>
         <Route path="Customers" element={<EmployeePrivateRoutes component={AdminCustomers} />}></Route>
         <Route path="Booking" element={<EmployeePrivateRoutes component={AdminBooking} />}>
            <Route index element={<Navigate  to="OnGoing" />} />
            <Route path="OnGoing" element={<EmployeePrivateRoutes component={OnGoing} />}></Route>
            <Route path="BookingHistory" element={<EmployeePrivateRoutes component={BookingHistory} />}>
            </Route>
         </Route>
         <Route path="LiveStatus"  element={<EmployeePrivateRoutes component={LiveStatus} />}></Route>
      </Route>
      {/* Employees End */}

      {/*Start Hospitals */}
      <Route path="Hospitals" element={<HospitalPrivateRoutes component={Hospital} />}>
         <Route index element={<HospitalPrivateRoutes component={AdminBooking}   />}></Route>
         <Route path="Booking" element={<HospitalPrivateRoutes component={AdminBooking} />}>
            <Route index element={<Navigate  to="OnGoing" />} />
            <Route path="OnGoing" element={<HospitalPrivateRoutes component={OnGoing} />}></Route>
            <Route path="BookingHistory" element={<HospitalPrivateRoutes component={BookingHistory} />}>
            </Route>
         </Route>
         <Route path="HospitalLogos" element={<HospitalPrivateRoutes component={AdminHospitalLogos} />}></Route>
      </Route>
         {/*End Hospitals */}

         {/* Pharmacy */}
         <Route path="Pharmacy" element={<Pharmacy/>}>
            <Route index element={<PharmacyCustomers/>}/>
            <Route path="Customers" element={<PharmacyCustomers/>}/>
            <Route path="ClaimedHistory" element={<PharmacyHistory/>}/>
         </Route>
         {/* Laboratory */}
         <Route path="Laboratory" element={<Laboratory/>}>
            <Route index element={<LaboratoryCustomers/>}/>
            <Route path="Customers" element={<LaboratoryCustomers/>}/>
            <Route path="ClaimedHistory" element={<LaboratoryHistory/>}/>
         </Route>

         {/* User */}
         <Route path="/" element={<User/>}>
            <Route index element={<MainPage/>}/>
            <Route path="homepage" element={<MainPage/>}/>
            <Route path="AboutUs" element={<AboutUs/>}/>
            <Route path="subscriptionPlan" element={<SubscriptionPlan/>}/>
            <Route path="preBooking" element={<PreBooking/>}/>
            <Route path="userPharmacy" element={<UserPharmacy/>}/>
            <Route path="userLabouratory" element={<UserLabouratory/>}/>
            <Route path="Terms" element={<Terms/>}/>
            <Route path="PrivacyPolicy" element={<PrivacyPolicy/>}/>
            <Route path="ContactUs" element={<UserContactUs/>}/>
            <Route path="userbooking/:id" element={<UserBooking/>}/>
            <Route path="Sos/:id" element={<Sos/>}/>
            <Route path="history" element={<History/>}/>
         </Route>
      {/* User */}

      {/* Traking */}
         <Route path="OngoingTracking/:id" element={<OngoingTracking/>}></Route>
      {/* Traking */}

      <Route path="*" element={<NoMatch />}></Route>
   </Routes>
   
   </>
  )
}

export default Routes_comp