import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg';
import '../../../Styles/styles.css';
import { Container } from "@mui/system";
import { FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import 'ui-neumorphism/dist/index.css'



const  CustomerForgotPassword = ()=> {

    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:5}} align="center" variant="h4" gutterBottom component="div">
                    Forgot Password
                </Typography>
            </Grid>
            <Grid container justifyContent='center' lg={12}>
                <Grid container justifyContent='center' lg={6}>
                    <Card className={"login_form"}>
                        <Typography className="forgot_text" sx={{my:5}} align="center" variant="h6" gutterBottom component="div">
                            An email will be sent to you with a link to login your password
                        </Typography>
                        <Grid container justifyContent='center' sx={{mt:3}}>
                            <Grid item>
                                <FormLabel>Email ID</FormLabel>
                                <TextField className={'text_field'} type="email" placeholder='Enter email'></TextField>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button  className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained'>Send</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CustomerForgotPassword;