import React from 'react'
import {  Navigate } from "react-router-dom";

const PublicRoutes=({component : Component , restricted , ...rest}) => {
    const user = JSON.parse(sessionStorage.getItem("userType"));
    if(user !== null){
        if(user.userType === "Admin"){
            return <Navigate to="/admin"/>
        }else if(user.userType === "Sub_admin"){
            return <Navigate to="/Sub_admin"/>
        }else if(user.userType === "Hospital"){
            return <Navigate to="/hospitals"/>
        }else if(user.userType === "Employee"){
            return <Navigate to="/Employees"/>
        }
    }else if(user == null ){
        return <Component {...rest}/>
    }
}

export default PublicRoutes