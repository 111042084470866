import React,{ useState , useEffect,useRef } from 'react'
import { 
    Grid,
    TextField,
    FormLabel,
    Modal,
    Box,
    Snackbar,
    List,
    ListItem,
    ListItemText,
    Alert,
    FormControl,
    MenuItem,
    Select,
    Card,
    CardContent,
    FormControlLabel, 
    RadioGroup,
    Radio,
    LinearProgress,
    Button,

} from '@mui/material'
import '../Header/header.css'
import './mainpage.css'
import { Typography } from 'ui-neumorphism'
import SemiIcu from '../../../Assets/icons/semiicu.png'
import Icu from '../../../Assets/icons/icu.png'
import General from '../../../Assets/icons/general.png'
// import SmallSemiIcu from '../../../Assets/icons/smallsemiicu.png'
import SmallIcu from '../../../Assets/icons/smallicu.png'
import SmallGeneral from '../../../Assets/icons/smallgeneral.png'
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import axios from "axios";
import Rader from '../../../Assets/icons/Rader.json';
import noambavailable from '../../../Assets/icons/noambavailable.json'
import Lottie from 'react-lottie'
import Geocode from "react-geocode";
import { useForm } from 'react-hook-form';
import AmbulanceJson from '../../../Assets/icons/ambulance.json'
import LocationAmb from '../../../Assets/icons/location.svg'
import LocationIcon from '../../../Assets/icons/location.svg'
import {useJsApiLoader , GoogleMap , MarkerF } from '@react-google-maps/api';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CloseIcon from '@mui/icons-material/Close';
import savedAddressServices from '../srvices';
import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete} from '@react-google-maps/api'
import { ToastContainer, toast } from 'react-toastify';
import { Subscriptions } from '@mui/icons-material'

const MainPage=()=>{
    const [districts , setDistricts] = useState([]);
    const [states , setStates] = useState([]);
    const [cities , setCities] = useState([]);
    const [areas , setAreas] = useState([]);
    const [hospitals , setHospitals] = useState([]);
    const [progress, setProgress] = useState(0);
    const [latlong , setLatlong] = useState({latitude:"" , longitude:""})
    const [address , setAddress] = useState("")
    const [ambType , setAmbType] = useState("3");
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [hospital, setHospital] = useState('');
    const [vertical] =useState('top');
    const [horizontal] =useState('right');
    const [bkngPrice , setBkngPrice] = useState({
        Icu:null,
        General:null
    });
    const [sorryScreen , setSorryScreen] = useState(true)
    const {register,handleSubmit,formState: { errors },reset} = useForm();
    const [logCheck , setLogCheck] = useState("")
    const [logError , setLogError] = useState(false);
    const [trackCheck , setTrackCheck] = useState(true);
    const [bookId , setBookId] = useState("");
    const [bookStatus , setBookStatus] = useState("");
    const [center , setCenter] = useState({lat:"", lng:""})
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [secureKey , setSecureKey] = useState("")
    const [hospitalField,setHospitalField] = useState(true)
    const [addressField,setAddressField] = useState(false)
    const [addPageNum , setAddPageNum] = useState('0');
    const [addPageSize , setAddPageSize] = useState('10');
    const [savAdd , setSavAdd] = useState([]);
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const authKey = localStorage.getItem("Key");
    const [savedAddress,setSavedAddress] = useState(false)
    const [successMessage , setSuccessMessage] = useState(false);
    const [oAddress , setOAddress] = useState("")
    const [mapModal , setmapModal] = useState("")
    const [fromLocType, setFromLocType] = useState(1)
    const [userSubscriptions,setUserSubscriptions] = useState([])
    const [image , setImage] = useState([]);
    const originRef = useRef()
    const [titleError , setTitleError] = useState(false);
    const [addressError , setAddressError] = useState(false);
    const [normalBookingId,setNormalBookingId] = useState("")
    const [addAddress , setAddAddress] = useState(
    {
        addresstitle:'',
        address:'',
    }
    )
    const [otherAddress, setOtherAddress] = useState('')
    const [bookTo , setBookTo] = useState(1)
    const autocompleteRef = useRef(null);
    const [openMap, setOpenMap] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [handleErrors , setHandleErrors] = useState({
        state:false,
        district:false,
        city:false,
        hospital:false,
        address:false
    })
    const [cityName , setCityName] = useState("")
    const [ambulance, setAmbulance] = useState(General)
    const [generalactive, setGeneralactive] = useState("")
    const [semiIcuactive, setSemiIcuactive] = useState("")
    const [icuactive, setIcuactive] = useState("")
    const [generalNameActive, setGeneralNameActive] = React.useState("ambulanceNameActive")
    const [semiIcuNameActive, setSemiIcuNameActive] = React.useState("")
    const [icuNameActive, setIcuNameActive] = React.useState("")
    const [heading, setHeading] = React.useState("GENERAL")
    const [ambPrice , setAmbPrice] = useState(0)
    const [text, setText] = React.useState("Basic Life support is a large van with important and basic medical equipment. However, is not for critical patients and long durations.")
    const [displayText, setDisplayText] = useState('');
    const [shownText,setShownText] = useState(false)

    const onLoad = (autocomplete) => {
       autocompleteRef.current = autocomplete;
     };
  
    const handleCloseSavedPassword = () => setSavedAddress(false)
    const addAddres = () =>{
       setSavedAddress(true)
    }
   
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,
        libraries: ['places'],
    })

    const logErrorhandleClose = () => {
        setLogError(false)
    }
    
    const handleOpenMap = (value) => {
      setOpenMap(true);
      setmapModal(value);
      handleClose()
  }


    const handleCloseMap = () => {
        setOpenMap(false)
        //setAddAddress({...addAddress , address:address})
    }
    const MapStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#22272B',
        boxShadow: 24,
        p: '2% 2% 2% 2%',
        outline:0,
        borderRadius:'10px'
    };
    const AddressStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight:400,
        bgcolor: '#22272B',
        outline:'none',
        p: '2% 2% 2% 2%',
        borderRadius: '30px',
    };
    const logging = () =>{
        if(userlogCheck !== null){
            setLogCheck(false);
            setTrackCheck(true);
            if(authKey !== null){
              setSecureKey(authKey);
            }
            allStates();
            getAddress(); 
        }else{
            setLogCheck(true);
        }
    }
   
    const addNewAddress = async() => {
        if(addAddress.addresstitle === ''){
            setTitleError(true);
            return;
        }else if(addAddress.address === ''){
            setTitleError(false);
            setAddressError(true);
            return;
        }else{
            setTitleError(false);
            setAddressError(false);
        }
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");

       await Geocode.fromAddress(addAddress.address).then(
            async(response) => {
                const { lat, lng } = response.results[0].geometry.location;
                const data = await savedAddressServices.addSavedLoc(userlogCheck?.id , addAddress?.addresstitle , addAddress?.address , lat ,lng)
                if(data.status == 1){
                    getSavedAddresses()
                    setAddAddress({...addAddress , addresstitle:'' , address:''})
                    setSuccessMsg(data.message)
                    setSmessage(true);
                }
                else{
                    setEmessage(true);
                    setErrorMsg("Address already exists")
                }
            },
            (error) => {console.log(error)}
        );
    }
    const getSavedAddresses =async () => {
        const data = await savedAddressServices.getSavAddresses(addPageNum , addPageSize , userlogCheck?.id)
            if(data){
                setSavAdd(data)
            }else {
                setSavAdd([]);
            }
    }
    const trackStatus = (status) =>{
        setInterval(()=>{
            if(userlogCheck?.id){
                axios.get(`${process.env.REACT_APP_BASE_URL}getUserBookingStatus/${userlogCheck?.id}`,{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
                     setBookId(res.data.bookingId)
                     setBookStatus(res.data.lastBookingType)
                    if(res && res.data.status === 1){
                       if(res.data.bookingStatus === 0){
                        setTrackCheck(true);
                       }else if(res.data.bookingStatus === 1){
                        setTrackCheck(false);
                       }
                    }
            }).catch((error) => {console.log(error)})
            }
        },10000)
    
    }

    const allStates = () =>{
    axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:userlogCheck?.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
        (responce) => {setStates(responce.data.result)}
    ).catch((error) => {console.log(error)})
    }

    const statehandleChange = (event) => {
        setState(event.target.value);
        setDistrict('')
        setCity('')
        setHospital('')
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:userlogCheck?.id,stateId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                }else{
                    setDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
     };

     const waitforconfirmation = (id) => {
        setSorryScreen(true)
        handleOpen();
        let check = setInterval(() => {
            axios.post(`${process.env.REACT_APP_BASE_URL}getBookingStatus`,{bookingId:id ,userId:userlogCheck?.id , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        clearInterval(check);
                        handleClose();
                        clearTimeout(checkaftmin);
                        // navigate('../userbooking');
                        window.open(`userbooking/id=${id}` , '_blank')
                    }else{
                    }
                }
            ).catch((error) => {console.log(error)})
        } , 3000)

        const checkaftmin =  setTimeout(()=>{
            axios.post(`${process.env.REACT_APP_BASE_URL}getBookingStatus`,{bookingId:id ,userId:userlogCheck?.id , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        clearInterval(check);
                        handleClose();
                        // navigate('../userbooking');
                        window.open(`userbooking/id=${id}` , '_blank')
                    }else{
                        setSorryScreen(false)
                        GetUpdateBookingAmbulance(id)
                        setTimeout(()=>{
                            handleClose();
                        } , 5000)
                    }
                }
            ).catch((error) => {console.log(error)})
        }, 60000);
     }

     const GetUpdateBookingAmbulance = (id) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}updateBooking`,{userId:userlogCheck?.id,bookingId:id },{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
           
        }).catch((error) => {console.log(error)})
    }

    
    const getLogos = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getHospitalLogo`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setImage(res.data.hospitalLogos)
                
            }else if(status === 0){
                
            }

        }).catch((error) => {console.log(error)})
    }

     const onSubmit = (Form_data) => {
        if(!addressField){
            
            if(state===""){
                setHandleErrors({state:true})
                return;
            }else if(district===""){
                setHandleErrors({district:true})
                return;
            }else if(city===""){
                setHandleErrors({city:true})
                return;
            }else if(hospital===""){
                setHandleErrors({hospital:true})
                return;
            }else{
                setHandleErrors({
                    state:false,
                    district:false,
                    city:false,
                    hospital:false
                })
                axios.post(`${process.env.REACT_APP_BASE_URL}booking`,{userId:userlogCheck?.id , fromLat:latlong.latitude , fromLong:latlong.longitude ,toLoc:bookTo, toLocId:hospital,bookingType:1 , fromLocation:address , ambulanceType:ambType ,  price:ambPrice , loginType:1,fromLocType,fromLocId:address},{headers:{Authorization:localStorage.getItem("Key")}}).then(

                    (responce) => {
                        if(responce.data.status === 1){
                            waitforconfirmation(responce.data.bookingId);
                            setNormalBookingId(responce.data.bookingId)
                            axios.post(`${process.env.REACT_APP_BASE_URL}changePushStatus`,{userId:userlogCheck?.id ,bookingId:responce.data.bookingId},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                                (responce) => {
                                    
                                }
                            ).catch((error) => {if(error.response.status === 401){
                                setErrorMsg("Your acoount was logged in from another device")
                                setEmessage(true)
                                setTimeout(() => {
                                    // userLogOut();
                                }, 2000);}})
                        }else{
                                
                            handleOpen();
                            setSorryScreen(false)
                            GetUpdateBookingAmbulance(responce.data.bookingId)
                            setTimeout(()=>{
                            handleClose();
                            } , 10000)
                        }
                    }
                ).catch((error) => {console.log(error)})
            }
        }else{
            if(otherAddress === ""){
                setHandleErrors({address:true})
                return;
            }else{
                if(otherAddress === address){
                    setErrorMsg("From and to location can't be same")
                    setEmessage(true)
                }
                else{
                    axios.post(`${process.env.REACT_APP_BASE_URL}booking`,{userId:userlogCheck?.id , fromLat:latlong.latitude , fromLong:latlong.longitude ,toLoc:bookTo, toLocId:otherAddress , bookingType:1 ,fromLocType, fromLocation:address , ambulanceType:ambType , price:ambPrice , loginType:1,fromLocId:address},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                        (responce) => {
                            if(responce.data.status === 1){
                                waitforconfirmation(responce.data.bookingId);
                                axios.post(`${process.env.REACT_APP_BASE_URL}changePushStatus`,{userId:userlogCheck?.id ,bookingId:responce.data.bookingId},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                                    (responce) => {
                                        
                                    }
                                ).catch((error) => {if(error.response.status === 401){
                                    setErrorMsg("Your acoount was logged in from another device")
                                    setEmessage(true)
                                    setTimeout(() => {
                                        // userLogOut();
                                    }, 2000);}})
                            }
                            else{
                                handleOpen();
                                setSorryScreen(false)
                                setTimeout(()=>{
                                handleClose();
                                } , 10000)
                            }
                        }
                    ).catch((error) => {console.log(error)})
                }
            
            }
        }
        
     }
    const districthandleChange = (event) => {
        setDistrict(event.target.value);
        setCity('');
        setHospital('');
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:userlogCheck?.id,districtId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                 setCities(responce.data.result)
                }
                else{
                 setCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    };

   
    const cityhandleChange = (event) => {
        setCity(event.target.value);
        setHospital('')
        axios.post(`${process.env.REACT_APP_BASE_URL}getHospitals/`,{userId:userlogCheck?.id,cityId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
        (responce) => {
            if(responce.data.status === 1){
                setHospitals(responce.data.details)
            }else{
                setHospitals([])
            }
        }
    ).catch((error) => {console.log(error)})
    };

    // const [area, setArea] = useState('');
    // const areahandleChange = (event) => {
    // setArea(event.target.value);
    // axios.get(`${process.env.REACT_APP_BASE_URL}getHospitals/${event.target.value}`,{headers:            {Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
    // (responce) => {
    //  if(responce.data.status === 1){
    //     setHospitals(responce.data.details)
    //  }else{
    //     setHospitals([])
    //  }
    //  }
    //  ).catch((error) => {console.log(error)})
    // };


    
    const hospitalhandleChange = (event) => {
        setHospital(event.target.value);
        settingPrice(event.target.value,ambType);
    };

    const addresshandleChange = (event) =>{
        // console.log(event.target.value)
        setOtherAddress(event.target.value);
        settingPrice(event.target.value,ambType);
    }

    const ErrorClose = () => {
        setEmessage(false)
    }
    const SuccessClose=() => {
        setSmessage(false);
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Rader,
        renderer: 'svg'
    }
    const defaultOptionsAmb = {
        loop: true,
        autoplay: true,
        animationData: AmbulanceJson,
        renderer: 'svg',
       
    }

    

    const settingPrice = (hospitalaa ,ambNum) => {
        if(userlogCheck?.id !== null && hospitalaa !== ''){
        axios.post(`${process.env.REACT_APP_BASE_URL}getBookingPrice`,{fromLat:latlong.latitude , fromLong:latlong.longitude ,toType:bookTo, toLocId:hospitalaa, ambulanceType:ambNum, userId:userlogCheck?.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (response) => {
                if(response.data.status === 1){
                    setBkngPrice({
                        ...bkngPrice,
                        Icu:response.data.data.icuPrice,
                        General:response.data.data.generalPrice
                    })
                }else{
                    // alert("else")
                    //setBkngPrice('')
                }
            }
        ).catch((error) => {console.log(error)})
        }
    }

    const lightOptions = {
       loop: true,
       autoplay: true,
       animationData: noambavailable,
       renderer: 'svg'
    }

    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        color:'white !importamt',
        bgcolor: '#22272B',
        boxShadow: 24,
        p: '2% 4% 2% 4%',
        outline:0,
        borderRadius:'10px'
      };
    
    const handleOpen = () => {
        setOpen(true);
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress === 100) {
                return 0;
              }
              const diff = Math.random() * 2;
              return Math.min(oldProgress + diff, 100);
            });
          }, 1000);
          return () => {
              clearInterval(timer);
            };
    }
    const handleClose = () => setOpen(false);
    
    const handleClick = () => {
        setShownText(true)
        setHospitalField(false)
        setAddressField(false)
        setDisplayText('Oops! It looks like this feature is only available with a subscription plan. Upgrade now to unlock this functionality!');
    };
    const changeImg=async(imageData)=>{
       if(imageData==="SemiIcu"){
            setAmbType("2")
            setAmbulance(SemiIcu)
            setSemiIcuactive("ambulanceActive")
            setSemiIcuNameActive("ambulanceNameActive")
            setHeading("SEMI ICU")
            setIcuactive("")
            setGeneralactive("")
            setIcuNameActive("")
            setGeneralNameActive("")
            setText("The Patient Transport Service Vehicle is used to transfer non-critical patients from one place to another. It is equipped with a stretcher as well")
            if(bookTo === 2){
                settingPrice(otherAddress,2);
            }
            else{
                settingPrice(hospital,2);
            }
        }
        
       else if (imageData==="Icu"){
            setAmbType("1")
            setAmbulance(Icu)
            setSemiIcuactive("")
            setIcuactive("ambulanceActive")
            setGeneralactive("")
            setSemiIcuNameActive("")
            setIcuNameActive("ambulanceNameActive")
            setGeneralNameActive("")
            setHeading("ICU")
            setAmbPrice(bkngPrice.Icu)
            setText("A large vehicle, Advanced Life Support is used for critical patients. The Ambulance consists of an array of ICU equipment.")
            if(bookTo === 2){
                settingPrice(otherAddress,1);
            }else{
                settingPrice(hospital,1);
            }
        }
        else if (imageData==="General"){
            setAmbType("3")
            setAmbulance(General)
            setSemiIcuactive("")
            setIcuactive("")
            setGeneralactive("ambulanceActive")
            setSemiIcuNameActive("")
            setIcuNameActive("")
            setGeneralNameActive("ambulanceNameActive")
            setHeading("GENERAL")
            setAmbPrice(bkngPrice.General)
            setText("Basic Life support is a large van with important and basic medical equipment. However, is not for critical patients and long durations.")
            if(bookTo === 2){
                settingPrice(otherAddress,3);
            }else{
                settingPrice(hospital,3);
            }
        }
    }
    
    const getAddress = () => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");

        Geocode.fromLatLng(localStorage.getItem("latitude"), localStorage.getItem("longitude")).then(
            (response) => {
              const address = response.results[0].formatted_address;
              setAddress(address);
            },
            (error) => {
              console.error(error);
            }
        );
    }
    const setlatvalues = (e) => {
       const frmadd = e.target.value.toString();
       setAddress(frmadd)
        Geocode.fromAddress(frmadd).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLatlong({latitude:lat, longitude:lng})
            },
            (error) => {
              console.error(error);
            }
            );
    }

     const showLogInMsg = () => {
        logCheck && setLogError(true)
    }
     
    const trackNavigate = () => {
        if(bookId && bookStatus === 1){
            window.open(`/userbooking/id=${bookId}` , '_blank')
        }else if(bookId && bookStatus === 2){
            window.open(`/Sos/id=${bookId}` , '_blank')
        }else{
            setEmessage(true);
            setErrorMsg("waiting for driver conformation")
        }
    }
    const stateOptions = states.map(menuItem =><MenuItem className='stateoptions' key={menuItem._id} value={menuItem._id}>{menuItem.state}</MenuItem>)
    const DistrictOptions = districts.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.district}</MenuItem>)
    const citiesOptions = cities.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.city}</MenuItem>)
    const areaOptions = areas.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.area}</MenuItem>)
    const hospitalOptions = hospitals.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.name}</MenuItem>)
     const imageTag = image.map((row , index) =><Grid item xs={12} md={2.4}>
    <Card className="hospitalcard">
        <CardContent>
            <Grid item key={index}>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}/${row.image}`} alt="HospitalLogo" className="hospitalLogo"/> 
            </Grid>
        </CardContent>
    </Card>
 </Grid>)

    const setMap = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
        setCenter({
            lat:position.coords.latitude,
            lng:position.coords.longitude
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
            const address = response.results[0].formatted_address;
            setAddress(address);
        },
        (error) => {
            console.error(error);
        }
        );
        });
    }

    const setMarker = (ev) => {
        setCenter({
          lat:ev.latLng.lat(),
          lng:ev.latLng.lng()
        })
        setLatlong({
          latitude:ev.latLng.lat(),
          longitude:ev.latLng.lng()
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");

        Geocode.fromLatLng(ev.latLng.lat(), ev.latLng.lng()).then(
            (response) => {
              const address = response.results[0].formatted_address;
              let city = address.split(',')
              setCityName(city[2]);
            //   setAddress(address);
                if(mapModal===1){
                    setAddress(address)
                }else{
                    setAddAddress({...addAddress,address:address})
                }
            },
            (error) => {
              console.error(error);
            }
          );
    }

    const cancelSos = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}userCancelBook`,{bookingId:normalBookingId , userId:userlogCheck?.id },{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSuccessMsg(responce.data.message)
                    setSmessage(true);
                    setOpen(false)

                }else{
                    setErrorMsg(responce.data.message)
                    setEmessage(true)
                }
            }
        ).catch((error) => {
            if(error.response.status === 401){
            setEmessage("Your acoount was logged in from another device")
            setEmessage(true)
            }
        }
    )
    }

    const setMarkerfromsearch = (lat ,lng) => {
        setCenter({
          lat:lat,
          lng:lng
        })
        setLatlong({
          latitude:lat,
          longitude:lng
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              let city = address.split(',');
              setCityName(city[2]);
              setAddress(address);
            },
            (error) => {
              console.error(error);
            }
        );
    }

        const googleMap = <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '95%' }}
            options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            }}
            onClick={ev => {
                setCenter({
                    lat:ev.latLng.lat(),
                    lng:ev.latLng.lng()
                })
                setMarker(ev)
            }}
        >
            <MarkerF position={center}></MarkerF>
        </GoogleMap>
        const hospitalInput = () => {
            setAddressField(false)
            setShownText(false)
            setHospitalField(true)
            setBookTo(1)
            setHandleErrors({
                state:false,
                district:false,
                city:false,
                hospital:false
            })
        }
        const addressInput = () => {
            setHospitalField(false)
            setAddressField(true)
            setBookTo(2)
            setHandleErrors({
                state:false,
                district:false,
                city:false,
                hospital:false
            })
        }
        const deleteAddress = async(id) => {
            const data = await savedAddressServices.deleteSavedLoc(userlogCheck?.id , id)
            if(data.status == 1){
                getSavedAddresses()
                setSuccessMsg(data.message)
                setSmessage(true);
            }
        }

    
    const handlePlaceSelect = (place)=> {
        if(place.formatted_address){
            setMarkerfromsearch(place.geometry.location.lat(), place.geometry.location.lng())
        }
    }
//  const [value,setValue] = useState("")
    const savaddresses = savAdd.map(menuItem =><MenuItem sx={{borderBottom:'1px solid #E1E1E1'}} value={menuItem._id}><Grid item container justifyContent={'center'} alignItems={'center'} sx={{mt:2}} >
    <Grid item md={10} sx={{paddingLeft:'10px'}} onClick={()=>{setOAddress(menuItem.address)}} >
        <Typography className={'menuItemTitle'} variant='h6'>{menuItem.title}</Typography>
        <Typography className={'textWord'} >{menuItem.address}</Typography>
    </Grid>
    <Grid item md={2}>
        <Grid container justifyContent={'center'}>
            <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer', textAlign:"center"}} onClick={()=>deleteAddress(menuItem._id)}/>
        </Grid>
    </Grid>
    </Grid></MenuItem>)
    
    const savaddressesOptions = savAdd.map(menuItem =><MenuItem sx={{borderBottom:'1px solid #E1E1E1'}} value={menuItem._id}><Grid item container justifyContent={'center'} alignItems={'center'} sx={{mt:2}}>
    <Grid item md={10} sx={{paddingLeft:'10px',}} onClick={()=>{setOAddress(menuItem.address)}}>
        <Typography color={'#129547'}  variant='h6' className={'menuTitle'}>{menuItem.title}</Typography>
        <Typography className={'textWord'} >{menuItem.address}</Typography>
    </Grid>
    </Grid></MenuItem>)
function onPlaceChanged(place){
    setMarkerfromsearch(place.geometry.location.lat() , place.geometry.location.lng())
}

const getUserSubscriptionDetails = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}userSubscriptionDetails/${userlogCheck?.id}`,{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
    if(res.data.status === 1){
        setUserSubscriptions(res.data.userSubscriptiondetails);
    }
}).catch((error) => {console.log(error)})
}

const gpsClick = () => {
    if (userSubscriptions && userSubscriptions.length > 0) {
        handleOpenMap(1);
        setMap();
        setFromLocType(1);
    } else {
        setErrorMsg("Oops! It looks like this feature is only available with a subscription plan.");
        setEmessage(true);;
    }
}


useEffect(() => {
    getUserSubscriptionDetails()
},[])

useEffect(() => {
    setLatlong({latitude:localStorage.getItem("latitude"), longitude:localStorage.getItem("longitude")}) 
    logging();
    trackStatus();
    getLogos();
    getSavedAddresses();       
},[]);

useEffect(()=>{
    if(userlogCheck){
        settingPrice(hospital);
    }
},[ambType])
    return(
        <>
            <Grid>{}
                {trackCheck ?<Grid container className='backgroundImage' >
                    <Grid item xs={12} md={6} sx={{mt:5}}>
                        <Grid component={"h4"} className="textWhite">
                            {heading}
                        </Grid>
                        <Grid component={"div"}>
                            <Typography className="textWhite1">
                                {text}
                            </Typography>
                        </Grid>
                        <Grid sx={{mt:3}}>
                            <img src={ambulance} alt="ambulance" className="ambulanceImg" width={'435px'} height={'345px'}/>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent={'center'} xs={12} md={6}>
                        <Grid item md={4}>
                            <Grid container alignItems="center" justifyContent="center">
                                <Grid className="formresponsive">
                                    <Grid item sx={{ mt: 4 }}  >
                                        <FormLabel className='label'>From</FormLabel><br></br>
                                        <Grid container>
                                            <Grid md={1.5} className="gpsflex" >
                                                 <GpsFixedIcon 
                                                    className='userLocationIcon' 
                                                    sx={{ marginTop: '10px' }} 
                                                    onClick={gpsClick} 
                                                />
                                            </Grid>
                                            <Grid md={9}>
                                                <Select fullWidth className='userSelectOption'
                                                value={address}
                                                displayEmpty
                                                disableunderline
                                                inputProps={{'aria-label': 'Without label'}}
                                                onChange={(e) => {
                                                setlatvalues(e)
                                                setFromLocType(2)
                                                }}
                                                disabled={logCheck}
                                                onClick={showLogInMsg}
                                                sx={{Width:'460px'}}
                                                >
                                                  <MenuItem value={address} className='bgColor'>
                                                    <em className='userWidthAdust'>{address}</em>
                                                    </MenuItem> 
                                                    {userSubscriptions && userSubscriptions.length > 0 ?(<MenuItem sx={{borderBottom:'1px solid #E1E1E1'}}>
                                                        <Grid onClick={()=>{addAddres()}}>
                                                            <Typography component="h4" className={'addressStyle'} >Add Address</Typography>
                                                        </Grid>
                                                    </MenuItem>):
                                                    (<MenuItem sx={{whiteSpace:'pre-wrap'}}>Oops! It looks like this feature is only available with a subscription plan. Upgrade now to unlock this functionality!</MenuItem>)
                                                    }
                                                    
                                                    {savaddressesOptions}
                                                </Select>
                                                {errors.address && <p className='error'>please select address</p>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    {/* <FormControl > */}
                                    <Grid>                                    
                                        <FormLabel className='label'>TO</FormLabel><br></br>
                                        <FormControl>
                                            <RadioGroup 
                                            sx={{display:'block'}}
                                            container
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Hospital"
                                            name="radio-buttons-group"
                                            >
                                                <FormControlLabel
                                                    value={"Hospital"}
                                                    control={
                                                        <Radio
                                                        sx={{
                                                            color: "white",
                                                            " &.Mui-checked": {
                                                            color: "#129547",
                                                            },
                                                        }}
                                                        />
                                                    }
                                                    className="labelName"
                                                    label={"Hospital"}
                                                    onClick={()=>{hospitalInput()}}
                                                />
                                            {userSubscriptions&& userSubscriptions.length>0?  (<FormControlLabel
                                                        value={"Address"}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: "white",
                                                                    " &.Mui-checked": {
                                                                        color: "#129547",
                                                                    },
                                                                }}
                                                                onClick={()=>{addressInput()}}
                                                            />
                                                        }
                                                        className="labelName"
                                                        label={"Address"}
                                                    />):(
                                                        <FormControlLabel
                                                        value={"Address"}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: "white",
                                                                    " &.Mui-checked": {
                                                                        color: "#129547",
                                                                    },
                                                                }}
                                                                onClick={()=>{handleClick()}}
                                                            />
                                                        }
                                                        className="labelName"
                                                        label={"Address"}
                                                    /> 
                                                    )}
                                                
                                            </RadioGroup>
                                        </FormControl>
                                        {shownText&&
                                        <Grid mt={3}>
                                            <Grid className='displayText'>{displayText}</Grid>
                                            {logCheck?(<Grid container justifyContent="center">
                                            <Button className='userBuyButton' size="large" onClick={showLogInMsg} >Buy</Button>
                                                
                                            </Grid>):(<Grid container justifyContent="center">
                                            <a href='/SubscriptionPlan' style={{width:'100%',display:'flex',justifyContent:'center'}}>
                                                <Button className='userBuyButton' size="large"  >Buy</Button>
                                                </a> 
                                            </Grid>)}
                                        </Grid>
                                        }
                                        {!userSubscriptions&&<Typography>Buy Plan</Typography>}
                                        {!addressField && !shownText &&  
                                        (<Select fullWidth className='userSelectOption'
                                            value={state}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            {...register("state", {
                                                required: "Please select state",
                                            })}
                                            onChange={statehandleChange}
                                            disabled={logCheck}
                                            onClick={showLogInMsg}
                                            >
                                            <MenuItem disabled className='optoins' value="">
                                                <em>Select State</em>
                                            </MenuItem>
                                            {stateOptions}
                                        </Select>)}
                                        {handleErrors.state && state.length === 0 && <p className='error'>please select state</p>}
                                    </Grid>
                                    {!addressField  && !shownText &&  
                                    <Grid>
                                        <Select fullWidth className='userSelectOption'
                                            value={district}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{'aria-label': 'Without label'}}
                                            {...register('district',{required: true})}
                                            onChange={districthandleChange}
                                            disabled={logCheck}
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select District</em>
                                            </MenuItem>
                                            {DistrictOptions}
                                        </Select>
                                    </Grid>}
                                    {handleErrors.district && district.length === 0 && <p className='error'>please select district</p>}
                                    {!addressField  && !shownText && 
                                    <Grid>
                                        <Select fullWidth className='userSelectOption'
                                            value={city}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{'aria-label': 'Without label'}}
                                            {...register('city',{required: true})}
                                            onChange={cityhandleChange}
                                            disabled={logCheck}
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select City</em>
                                            </MenuItem>
                                            {citiesOptions}
                                        </Select>
                                    </Grid>}
                                    {handleErrors.city && <p className='error'>please select city</p>}
                                    {/* <Grid>
                                        <Select fullWidth className='select'
                                            value={area}
                                            displayEmpty
                                            disableUnderline
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            {...register('area',{required: true})}
                                            onChange={areahandleChange}
                                            disabled={logCheck}
                                            onClick={showLogInMsg}
                                        >
                                            <MenuItem value="">
                                                <em>Select Area</em>
                                            </MenuItem>
                                            {areaOptions}
                                        </Select>
                                        
                                    </Grid> */}
                                    {hospitalField && <Grid>
                                        <Select fullWidth className='userSelectOption'
                                        value={hospital}
                                        displayEmpty
                                        disableUnderline
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        {...register('hospital',{required: true})}
                                        onChange={hospitalhandleChange}
                                        disabled={logCheck}
                                        onClick={showLogInMsg}
                                        >
                                        <MenuItem value="">
                                            <em>Select Hospital</em>
                                        </MenuItem>
                                            {hospitalOptions}
                                        </Select>
                                    </Grid>}
                                    {handleErrors.hospital && hospital.length === 0 && <p className='error'>please select hospital</p>}
                                    {addressField &&<Grid>
                                        <Select fullWidth  className='userSelectOption'
                                        value={otherAddress}
                                        displayEmpty
                                        disableUnderline
                                        inputProps={{'aria-label': 'Without label'}}
                                        onChange={addresshandleChange}
                                        disabled={logCheck}
                                        onClick={showLogInMsg}
                                        sx={{Width:'460px'}}
                                        >
                                            <MenuItem value="">
                                                <em style={{color:'white'}}>Select Address</em>
                                            </MenuItem>
                                            <MenuItem sx={{borderBottom:'1px solid #E1E1E1'}}>
                                                <Grid onClick={()=>{addAddres()}} className='paperBg'>
                                                    <Typography component="h4" className={'addressStyle'} >Add Address</Typography>
                                                </Grid>
                                            </MenuItem>
                                            
                                            {savaddressesOptions}
                                        </Select>
                                    </Grid>}
                                    {handleErrors.address && otherAddress.length === 0 && <p className='error'>please select address</p>}
                                {/* </FormControl> */}
                                {!shownText&&  <Grid container className='bottomImg' spacing={{ xs: 2, md: 5 }} columns={{ xs:3, sm: 8, md: 12 }} justifyContent="center">
                                        <Grid Item sx={{m:5}}  className="totalAmbulance">
                                        <Grid>
                                            <img src={SmallGeneral} alt="ambulance" className={`ambulanceShadow ${generalactive}`} onClick={()=>{changeImg("General")}}/>
                                            </Grid>
                                            {bkngPrice.General&&<Typography  className={`ambulanceShadow Amblabel ${generalactive}`}>{bkngPrice.General}</Typography>}
                                            <Typography className={`ambulanceName ${generalNameActive}`}>General</Typography>
                                        </Grid>
                                        {/* <Grid Item sx={{m:5}} className="totalAmbulance">
                                            <img src={SmallSemiIcu} alt="ambulance" className={`ambulanceShadow ${semiIcuactive}`}  onClick={()=>{changeImg("SemiIcu")}} />
                                            <Typography className={`ambulanceName ${semiIcuNameActive}`}>Semi ICU</Typography>
                                        </Grid> */}
                                        <Grid Item sx={{m:5}} className="totalAmbulance">
                                            <Grid>
                                                <img src={SmallIcu} alt="ambulance" className={`ambulanceShadow ${icuactive}`} onClick={()=>{changeImg("Icu")}}/>
                                            </Grid>
                                        {bkngPrice.Icu &&                                           <Typography className={`ambulanceShadow Amblabel ${icuactive}`}>{bkngPrice.Icu}</Typography>}
                                            <Typography className={`ambulanceName ${icuNameActive}`}>ICU</Typography>
                                        </Grid>
                                    </Grid>}
                                    {/* <Grid item md={12}>
                                        <Typography className='label'>Booking price :<span>{bkngPrice.icuPrice}</span></Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            {!shownText&&<Grid container justifyContent="center">
                                {logCheck?<Button className='userBookButton' size="medium" onClick={showLogInMsg}>Book</Button>:<Button className='userBookButton' size="medium" onClick={onSubmit}>Book</Button>}
                            </Grid>}
                        </Grid>
                    </Grid>
                  </Grid>:<Grid className="backImg" height="600px">
                            <Grid item>
                                <Lottie 
                                options={defaultOptionsAmb}
                                height={400}
                                width={400}
                                />
                            </Grid>
                            <Grid item container justifyContent="center">
                                <List className="listtrack" onClick={trackNavigate}>
                                    <ListItem>
                                        <img src={LocationAmb} alt="ambulancelocaton"/>
                                        <ListItemText primary="Track" className="itemtext"/>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                }
                </Grid>
                <Grid className='backgroundColor'>
                    <Grid sx={{pt:3,color:'white'}} container justifyContent="center" component="div" fontSize="30px" fontWeight="700">Hospitals</Grid>
                    <Grid container className='hospitallogos' alignItems="center" sx={{ p:5}} rowSpacing={4} columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
                        {imageTag}
                    </Grid>
                </Grid>
        {/* modal */}
       <Modal
       open={open}
        onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
       <Box sx={style} className="acceptanceModal">
           <Grid>
            {sorryScreen ? 
            <> <Grid item>
                   <Lottie
                   options={defaultOptions}
                   height={100}
                   width={100}/>
                   <Typography className="serachingText">Waiting For Driver Acceptance....</Typography>
               </Grid>
               <Grid item sx={{mt:5}}>
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress variant="determinate" className='linear' value={progress}/>
                      <Grid item sx={{mt:5}} container justifyContent='center'>
                        <Button className='cancelBtn' size="medium" onClick={cancelSos}>Cancel</Button>
                    </Grid>
                    </Box>
               </Grid>
            </> : <> 
                    <Grid item>
                        <Lottie options={lightOptions} height={100} width={100}/>
                        <Typography className="serachingText">Sorry, no ambulance available nearby</Typography>
                    </Grid>
                </>
            }
           </Grid>
        </Box>
    </Modal>
    {/*  */}
    <Modal
    open={openMap}
    // onClose={handleCloseMap}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
        <Box sx={MapStyle} className="mapmodal">
            <Grid>
                <Autocomplete onLoad={onLoad} onPlaceChanged={()=>onPlaceChanged(autocompleteRef.current.getPlace())}>
                    <TextField className="userTextField" placeholder="search"
                    //onChange={() => handlePlaceSelect(autocompleteRef.current.getPlace())}
                      fullWidth InputProps={{
                    startAdornment: (
                    <SearchIcon sx={{color:'white',marginLeft:'10px'}} />
                    ),
                    }} ref={originRef}/>
                </Autocomplete>
            </Grid>
            <Grid className="mapHeight" mt={3}>
            {isLoaded? googleMap:<h1 className={'locationText'}>loading...</h1>}
            </Grid>
            <Grid md={12}>
                <Grid>
                    <Typography className={'locationText'}>Pin location</Typography>
                </Grid>
                <Grid container sx={{mt:1}}>
                    <Grid md={0.5}>
                        <img src={LocationIcon} alt="icon"/>
                    </Grid>
                    <Grid md={7}>
                        <Typography></Typography>
                        <Typography className={'locationText'}>{mapModal&& mapModal===1? address : addAddress.address}</Typography>
                    </Grid>
                    <Grid md={4.5}>
                        <Button className="userConfirmLocationbtn" onClick={()=>{handleCloseMap()}}>Confirm Location</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </Modal>
<Modal
    open={savedAddress}
 // onClose={handleCloseSavedPassword}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
    <Box className="modalwidth" sx={AddressStyle}>
        <Grid>
            <CloseIcon onClick={()=>{handleCloseSavedPassword()}} className={"userCloseIconPosition"}/>
            <Typography className="userTitleName"  align="center" variant="h4" gutterBottom component="div">Saved address</Typography>
        </Grid>
        <Box sx={{width: '100%'}}>
            <Grid container justifyContent="center"  rowSpacing={1} columnSpacing = {{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={5}>
                    <Grid item sx={{mt:2}}>
                        <FormLabel className='userFormLabel'>Title</FormLabel>
                        <TextField fullWidth name="addresstitle" value={addAddress.addresstitle} className={'userTextField'} onChange={(e)=>{setAddAddress({...addAddress , addresstitle : e.target.value})}} />
                        {titleError&&<p className='error mt-0'>Please enter title</p>}
                    </Grid>
                    <Grid item sx={{mt:2}}>
                        <Grid container justifyContent={'space-between'}>
                            <FormLabel className='userFormLabel'>Address</FormLabel>
                            <MyLocationIcon className="userLocationIcon"
                              onClick={()=>{
                                handleOpenMap(2)
                                setMap()
                              }}
                            />  
                        </Grid>
                        <Grid item>
                            <TextField fullWidth value={addAddress.address} className="userAddressField "  multiline variant='outlined' rows="2" size="small" onChange={(e)=>{setAddAddress({...addAddress,address : e.target.value})}} />
                            {addressError&&<p className='error mt-0'>Please enter address</p>}
                        </Grid>
                        <Grid item container justifyContent={'center'} sx={{my:3}}>
                            <Button className="customerLogin" bgColor='#129547' onClick={addNewAddress} >Add</Button>
                        </Grid> 
                    </Grid>
                </Grid>
                <Grid item md={7}>
                    <Grid item className={'addressLine'}>
                        {savaddresses}
                    </Grid>
                </Grid>
            </Grid>
           </Box>
        </Box>
</Modal>
    <Snackbar open={logError} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={logErrorhandleClose} >
        <Alert onClose={logErrorhandleClose} severity="error" className="snackError">
            Please login for Booking
        </Alert>
    </Snackbar>
    <Snackbar open={smessage} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success">
            {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error">
            {errorMsg}
        </Alert>
    </Snackbar>

    
   
    
    
    </> 
    )
}

export default MainPage