import React from 'react'
import {  Navigate } from "react-router-dom";

const SubAdminPrivateroutes=({component:Component , ...rest}) => {
    const user = JSON.parse(sessionStorage.getItem("userType"));
    if(user !== null && user.userType === "Sub_admin"){
        return <Component {...rest} />
    }else if(user == null ){
        return <Navigate to="/subAdmin" />
    }
}

export default SubAdminPrivateroutes