import React from 'react';
import { Grid,Box } from '@mui/material';
import AboutImg from '../../../Assets/icons/aboutus.png';
import Container from '@mui/system/Container';
import { Typography } from 'ui-neumorphism';
import './aboutus.css';

const AboutUs=()=>{
    return(
      <>
        <Container className='UserwidthSet aboutBg widthSet'>
          <Box sx={{ flexGrow: 1,pt:5,pb:10 }}>
            <Grid container alignItems={'center'}>
              <Grid item md={5}>
                <img src={AboutImg} alt="about" className="ambulanceImg"/>
              </Grid>
              <Grid item md={7} sm={12} className="aboutresponsive">
                <Grid sx={{mb:2,color:'white' }} component={'h1'} gutterBottom >About Us</Grid>
                <Typography className="dataContent">
                  Life is inherently unpredictable, and it is our responsibility to take precautions to protect ourselves from unforeseen incidents. Recognizing the need to assist society and individuals during unexpected accidents, GoAmbee was established with a positive intention.The purpose of GoAmbee is to provide support and aid in times of unforeseen events. By extending a helping hand during accidents or other unexpected occurrences, GoAmbee aims to contribute to the safety and welfare of society and its members.
                </Typography>
                <Typography className="dataContent">
                  GoAmbee is an Ambulance Booking Application which acts as a platform for booking the Ambulance in times of urgent medical needs. It's a hand-on solution during emergencies where every second counts. At the time of emergency/mishap, Medical assistance makes a huge difference in saving the lives of individuals. Our mission behind the concept is to save the lives of people with a click on your hand providing the users with feasibility to book the type of Ambulance as per the condition by the trusted Ambulance drivers with prompt response.
                </Typography>
                <Typography className="dataContent">
                  We are associated with the trusted and promising hospitals. Our team has created a trusted network for a safe and secure process. Our team in collaboration with Ambulance drivers around the location of the situation will accept the request for booking the Ambulance based on the type of Ambulance selected as per the need by the users. Without any delay the Ambulance arrives by providing the first aid and serving the patient to the nearest associated hospital for the treatment.
                </Typography>
                <Typography className="dataContent">
                  We aim to be a supporting system for the individuals in urgent medical condition and the nearest hospitals to provide seamless service. We believe that everyone should have access to immediate medical assistance and support when needed within a few clicks in their hand. The Ambulance is equipped with all the necessary equipment for the patient to sustain their lives to reach the nearest hospital.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </>
        )
}
export default AboutUs