import React, { useEffect, useState } from "react";
import { 
    Grid, 
    Typography, 
    TableContainer,
    Table, 
    TableRow, 
    TableHead, 
    TableCell, 
    TableBody, 
    Button,
    Stack,
    Pagination,
    TextField,
    Select,
    MenuItem,
    Modal,
    FormLabel,
    Box,
    Snackbar,
    Alert,
} from "@mui/material"  
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const AdminContactUsUser = () => {
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [contactUsUserList , setContactUsUserList] = useState([])
    const [searchData,setSearchData] = useState("");
    const [userStatus,setUserStatus] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [ApprovalModal,setApprovalModal] = useState(false);
    const [userId,setUserId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const handleCloseDelete = () => setOpenDelete(false);

    const handleOpenApprovalModal = (id) => {
        setApprovalModal(true)
        setUserId(id)
    }
    const handleOpenDelete = (id) => {
        setUserId(id)
        setOpenDelete(true)
        
      }
    const handleCloseApprovalModal = () => {
        setApprovalModal(false)
    }
    
    const ErrorClose = () => {
        setEmessage(false)
    }
    const SuccessClose=() => {
        setSmessage(false);
    }
    const handleChangeUserStatus = (event) => {
        setUserStatus(event.target.value)
    }
     const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height:500,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 2% 0% 2%',
        outline:0,
        borderRadius:'10px',
    };
    const DeleteStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline:'none',
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
      };

    
    
    const handleSearchData = (event) => {
        setSearchData(event.target.value)
        gettingContactUsUserList()
    }
    const gettingContactUsUserList = () => {
        const payload = {
            page:0,
            size:10,
        }
        if (searchData !== ""){
            payload.searchBy = searchData
        }
        if (userStatus !== ""){
            payload.filterBy = userStatus
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userEnquiryList`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setContactUsUserList(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                }else {
                    setTotalPages(0)
                }
            }).catch((error) => {console.log(error)})
        }
        const handleChange = (event, value) => {
            setPage(value-1);
            const payload = {
                page:value-1,
                size:10,
            }
            if (searchData !== ""){
                payload.searchBy = searchData
            }
            if (userStatus !== ""){
                payload.filterBy = userStatus
            }
            
            axios.post(`${process.env.REACT_APP_BASE_URL}userEnquiryList`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
                (responce) => {setContactUsUserList(responce.data.details)}
            ).catch((error) => {console.log(error)})
        };

    const ApproveUserContactList = () => {
        const payload = {
            id:userId,
            type:'update'   
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}updateUserEnquiry`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setApprovalModal(false)
                    gettingContactUsUserList()

                }else {
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
        }).catch((error) => {console.log(error)})
    }   
    const Delete = () =>{
        const payload = {
            id:userId,
            type:'delete'   
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}updateUserEnquiry`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              setSmessage(true)
              setSuccessMsg(responce.data.message)
              setOpenDelete(false)
              gettingContactUsUserList()
            }else{
              setEmessage(true)
              setErrorMsg(responce.data.message)
            }
          }
        ).catch((error) => {console.log(error)})
      }

    const StatusColorStyle = (status) => {
        if(status === "Pending"){
          return '#EB9C25'
        }else if(status === "Closed"){
          return '#00B207'
        }
    }

    const TotalUserContactList = contactUsUserList.map((row, index) =>
        <TableRow key={index}>
            <TableCell align="center">{(page)*10+index+1}</TableCell>
            <TableCell align="center" title={row.name}>{row.name}</TableCell>
            <TableCell align="center" title={row.phone}>{row.phone}</TableCell>
            <TableCell align="center" title={row.email}>{row.email}</TableCell>
            <TableCell align="center" title={row.message}>{row.message}</TableCell>
            <TableCell align="center" title={row.status} style={{color:StatusColorStyle(row.status)}}>{row.status}</TableCell>
            <TableCell align="center">
               {row.status==="Pending" && <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon"
                onClick={() => {handleOpenApprovalModal(row._id)} }/>}
                <DeleteOutlineOutlinedIcon className="actionIcon" sx={{color:'#E11F26',cursor:'pointer'}}
                onClick={()=>{handleOpenDelete(row._id)}} />
            </TableCell>
        </TableRow>
    );

    useEffect(() => {
        gettingContactUsUserList() 
    },[])

    useEffect (() => {
        if(searchData !== null || userStatus){
            gettingContactUsUserList()
        }
    },[searchData,userStatus])


    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} mb={3} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item md={2}> 
                    <Typography sx={{fontSize:'18px'}}>Contact Us - User</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                   <Grid item md={6} sx={{marginRight:'20px'}}>
                        <Select
                            disableUnderline
                            className='selectOptions'
                            displayEmpty
                            fullWidth
                            value={userStatus}
                            placeholder="Select Laboratory"
                            onChange={handleChangeUserStatus}
                        >
                            <MenuItem value=""> 
                                <em>Select Status</em>
                            </MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Closed"}>Closed</MenuItem>
                        </Select>
                    </Grid>
                    <Grid sx={{marginRight:'20px'}}>
                        <TextField 
                        className="text_field" 
                        placeholder="Search"
                        onChange={handleSearchData} 
                        fullWidth InputProps={{
                        startAdornment: (
                        <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                        ),
                        }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">USER NAME</TableCell>
                                <TableCell className="tableHead">PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">MESSAGE</TableCell>
                                <TableCell className="tableHead">STATUS</TableCell>
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {TotalUserContactList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <h2> No Data Found </h2>
                                    </TableCell>
                                </TableRow> 
                            ):(
                            TotalUserContactList
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination 
                    count={totalPages} 
                    page={page+1} 
                    onChange={handleChange} 
                    className={"paginationAlign"}
                    />
                </Stack>
            </Grid>
        </Grid>

        {/*Start View Modal */}
        <Modal
            open={ApprovalModal}
            // onClose={handleCloseView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <Box sx={DeleteStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Do you want to approve the user?
                    </Typography>
                    <Grid container justifyContent="space-around" sx={{mt: 2}}>
                        <Button className = "yesButton" onClick={ApproveUserContactList}>Yes</Button>
                        <Button className = "noButton" onClick={handleCloseApprovalModal}>No</Button>
                    </Grid>
                </Box>
                
            
        </Modal>
    {/*START DELETE MODAL */}
    <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete the user details?
          </Typography>
          <Grid container justifyContent="space-around" sx={{mt: 2}}>
            <Button className = "yesButton" onClick={Delete}>Yes</Button>
            <Button className = "noButton" onClick={handleCloseDelete}>No</Button>
          </Grid>
        </Box>
    </Modal>

    {/*END DELETE MODAL  */}

    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
            {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error" className="snackError">
            {errorMsg}
        </Alert>
    </Snackbar>
       
    </>
    )
}