import { useEffect, useState } from 'react'
import TrakingHeader from './trakingheader'
import { 
Grid ,
Container,
Typography
} from '@mui/material'
import './ongoingtraking.css'
import DriverLocation from '../../../Assets/icons/driverlocation.svg'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import {useJsApiLoader, GoogleMap, MarkerF, DirectionsRenderer} from '@react-google-maps/api';
import PubNub from 'pubnub';
import ambulancetrack from '../../../Assets/icons/ambulancetrack.png'


const OngoingTracking=()=>{

    let{ id } = useParams();

    
    const user = JSON.parse(sessionStorage.getItem("userType"));
    let idparam = id.split('=')
    const[fromLoc , setFromLoc] = useState("");
    const[toLoc , setToLoc] = useState("");
    const[tolatlong , setTolatlong] = useState({toLatitude:'',toLongitude: ''})
    const[fromlatlong , setFromlatlong] = useState({fromLatitude:'',fromLongitude: ''})
    const toLocation = {lat: parseFloat(tolatlong.toLatitude), lng: parseFloat(tolatlong.toLongitude)}
    const [fromLocationdetails  , setFromLocationdetails] = useState({lat: parseFloat(fromlatlong.fromLatitude), lng: parseFloat(fromlatlong.fromLongitude)})
    const center = {lat: 17.0226374, lng: 82.2367775}  
    const channels = ([idparam[1]]);
    const {isLoaded} = useJsApiLoader({
    googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,
    })

    const pubnub = new PubNub({
        publishKey : "pub-c-d1177e8a-4dd3-4115-ad90-d52fddb4a310",
        subscribeKey : "sub-c-3681dda7-1b85-4efd-9575-724b4a358727",
        uuid: "3b378ddb-3178-4cec-8a5f-a70bb4bdc6dc", //generated uuid,
     });

    const markerSet = () =>{
        setInterval(()=>{
            pubnub.history({
                channel:channels,
                reverse:true,
              },
              (status, res) => {
                let count = res.messages;
                let messagesLength = count.length-1;
                setFromLocationdetails({lat:res.messages[messagesLength].entry.lat, lng:res.messages[messagesLength].entry.lng})
              })
        },3000)
    }

    const loadTracking=()=>{
            axios.get(`${process.env.REACT_APP_BASE_URL}track/${idparam[1]}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
                (responce) => {
                    setFromLoc(responce.data.booking.fromLocation)
                    setToLoc(responce.data.booking.toLocation)
                    if(responce.data.booking.bookingType === 1){
                        setTolatlong({
                            ...tolatlong,
                            toLatitude: responce.data.booking.toLatitude,
                            toLongitude:responce.data.booking.toLongitude
                        })
                        
                        updateToLocation()
                    }else if(responce.data.booking.bookingType === 2){
                        setTolatlong({
                            ...tolatlong,
                            toLatitude: responce.data.booking.fromLatitude,
                            toLongitude:responce.data.booking.fromLongitude
                        })
                        updateToLocation()
                    }
                    
                }
            ).catch((error) => {console.log(error)})
        }
    const updateToLocation = () =>{
        const payload = {
            bookingId:idparam[1],
            userId: user.id,
            logInType:2
        }
            setInterval(()=>{
                axios.post(`${process.env.REACT_APP_BASE_URL}getToLocation/`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
                    (responce) => {
                        if(tolatlong.toLatitude !== responce.data.locationDetails.latitude || tolatlong.toLongitude !== responce.data.locationDetails.longitude){
                            setTolatlong({
                                ...tolatlong,
                                toLatitude:parseFloat(responce.data.locationDetails.latitude),
                                toLongitude:parseFloat(responce.data.locationDetails.longitude)
                            })
                        }
                    }
                ).catch((error) => {console.log(error)})
            },5000)
    }
    const googleMap = fromLocationdetails.lat !== "" && fromLocationdetails.lng !== "" ? (
        <GoogleMap
            center={fromLocationdetails}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
        >
            <MarkerF icon={ambulancetrack} position={fromLocationdetails} />
            <MarkerF position={toLocation}></MarkerF>
        </GoogleMap>
    ) : (
        <h1 className='bookingHeading'>I'm not receiving data from the driver</h1>
    );
        useEffect(() =>{
            loadTracking();
            pubnub.subscribe({channels ,withPresence: true});
            markerSet();
        },[]);
        
    return(
      <>
        <TrakingHeader/>
        <Container>
            <Grid className='dashboard_bg trakingHeight' sx={{mt:5}}>
                <Grid style={{height:'100%', padding:'1.5rem'}}>
                    {isLoaded? googleMap:<h1>loading...</h1>}  
                </Grid>
            </Grid>
            <Grid item container spacing={3} sx={{mt:1,mb:4}}>
                <Grid item> 
                    <img src={DriverLocation} alt="driver"/>
                </Grid>
                <Grid item>
                    <Grid item>
                        <Typography fontSize="14px" color="#979899">Pick up from</Typography>
                        <Typography>{fromLoc}</Typography>
                    </Grid>
                    <Grid item sx={{mt:2}}>
                        <Typography fontSize="14px" color="#979899">Drop at</Typography>
                        <Typography>{toLoc}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
    )
}
export default OngoingTracking
