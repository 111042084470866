import React,{useEffect, useState} from "react"
import axios from "axios";
import { 
    Grid , 
    TableContainer, 
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead, 
    TableBody,
    TextField,
} from "@mui/material"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
export const PharmacyHistory = () => {

    const [pharmacyClaimedHistory, setPharmacyClaimedHistory] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [serachData,setSearchData] = useState("");
    const user = JSON.parse(sessionStorage.getItem("userType"));
    
    const handleSearchData = (event) => {
      setSearchData(event.target.value)
      gettingPharmacyClaimedHistory()
    }
    const handleChange = (event, value) => {
        setPage(value-1);
        axios.post(`${process.env.REACT_APP_BASE_URL}userClaimHistory/${value-1}/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setPharmacyClaimedHistory(responce.data.details)}
        ).catch((error) => {console.log(error)})
    };

    const gettingPharmacyClaimedHistory = async () => {
        const payload = {
            page: 0,
            size: 10,
            id:user.id,
            type:'pharmacy'
        }
        if (serachData !== "") {
            payload.searchBy = serachData;
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}userClaimHistory`, payload, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } }).then(
            (responce) => {
                if(responce.data.status === 1){
                 setPharmacyClaimedHistory(responce.data.claimHistory)
                 setTotalPages(responce.data.totalPages)
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const TableBodyData = pharmacyClaimedHistory && pharmacyClaimedHistory.map((row, index) => (
        <TableRow key={row.id}>
            <TableCell align="center">{index+1}</TableCell>
            <TableCell align="center" title={row.userName}>{row.userName}</TableCell>
            <TableCell align="center" title={row.mobile}>{row.mobile}</TableCell>
            <TableCell align="center" title={row.discount}>{row.discount}</TableCell>
            <TableCell align="center" title={row.totalAmount}>{row.totalAmount}</TableCell>
        </TableRow>
    ))

useEffect(() => {
    gettingPharmacyClaimedHistory();
}, [])

useEffect(() => {   
    if(serachData !== null){
    gettingPharmacyClaimedHistory();
    }
}, [serachData])


    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={'outletBodyHeading'}>
                <Grid> 
                    <Typography sx={{fontSize:'18px'}}>Claimed History</Typography>
                </Grid>
                <Grid sx={{display:'flex', mb:1}}>
                    <Grid sx={{marginRight:'20px'}}>
                        <TextField className="text_field" 
                        placeholder="Search"
                        onChange={handleSearchData} 
                        fullWidth InputProps={{
                        startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                        ),
                        }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'} sx={{paddingTop:'3rem'}}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">Name</TableCell>
                                <TableCell className="tableHead">Phone Number</TableCell>
                                <TableCell className="tableHead">Discount Amount</TableCell>
                                <TableCell className="tableHead">Total amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pharmacyClaimedHistory&&pharmacyClaimedHistory.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">  
                                        <h2>No data Found</h2>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                TableBodyData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                </Stack>
            </Grid>
        </Grid> 
    
        </>
    )
}