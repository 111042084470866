import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
import '../../../Styles/styles.css'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from "react";
import {  useNavigate } from "react-router-dom";

function CustomerLogin() {

    const[eyeIcon,seteyeIcon]=useState(true);
    const[type,setType] =useState('password')

       const hidePassword=()=>{
           seteyeIcon(false);
           setType('name')
       }

       const showPassword=()=>{
           seteyeIcon(true);
           setType('password')
       }
       const navigate =  useNavigate();
    
    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography sx={{my:5}} align="center" variant="h4" gutterBottom component="div">
                    Customer Login
                </Typography>
            </Grid>
            <Grid container justifyContent='center' lg={12}>
                <Grid container justifyContent='center' lg={6}>
                    <Card className={"login_form"}>
                        <Grid container justifyContent='center' sx={{mt:3}}>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Email ID</FormLabel>
                                <TextField className={'text_field'}></TextField>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' >
                            <Grid item sx={{mt:2}}  className={"parent_position"}>
                                <FormLabel>Password</FormLabel>
                                <TextField type={type} className={'text_field'}></TextField>
                                {eyeIcon?
                                <RemoveRedEyeOutlinedIcon onClick={hidePassword} className={'eyeicon_position'} />:
                                <VisibilityOffOutlinedIcon onClick={showPassword} className={'eyeicon_position'} />}
                                <Typography sx={{cursor:'pointer'}} onClick={() => {navigate('/customer_forgot_password');}} gutterBottom component="div" color="#E11F26">
                                    Forgot Password?
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button  className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained'>Login</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CustomerLogin;

