import React, { useState, useEffect } from 'react';
import { useLoadScript, GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import AmbLocationIcon from '../../../Assets/icons/amblocation.svg';
import axios from 'axios';

const libraries = ['places'];

const LiveStatus = () => {

  let user = JSON.parse(sessionStorage.getItem("userType"));
  let id = user.id;
  let type = user.type;

  const [markers, setMarkers] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const locationLiveStatus = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}getDriverLiveStatus`, 
    {type:type,empId:id},{ headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
      .then((response) => {
        if (response.data.status === 1 && response.data.details) {
          const newMarkers = response.data.details.map((detail) => ({
            lat: parseFloat(detail.latitude),
            lng: parseFloat(detail.longitude),
            name: detail.name,
            ambulanceNumber: detail.ambulanceNumber
          }));
          setMarkers(newMarkers);
        }
      })
      .catch((error) => {
        console.error('Error fetching live status:', error);
      });
  };

  useEffect(() => {
    locationLiveStatus();
  }, []);

  const handleMarkerMouseOver = (marker) => {
    setHoveredMarker(marker);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading Google Maps: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }
  if (markers.length === 0) {
    return <div className='no-amb'>No ambulances available.</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      zoom={13}
      center={markers.length > 0 ? markers[0] : {}}
    >
      {markers.map((marker, index) => (
        <MarkerF
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
          onMouseOver={() => handleMarkerMouseOver(marker)}
          onMouseOut={handleMarkerMouseOut}
          icon={{
            url: AmbLocationIcon,
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 40),
          }}
        >
          {hoveredMarker === marker && (
            <InfoWindowF position={{ lat: marker.lat, lng: marker.lng }}>
              <div>
                <p className='markerText'>{marker.name}</p>
                <p className='ambText'>{marker.ambulanceNumber}</p>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      ))}
    </GoogleMap>
  );
};

export default LiveStatus;



// import React, { useState, useEffect } from 'react';
// import { useJsApiLoader, GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
// import AmbLocationIcon from '../../../Assets/icons/amblocation.svg';
// import axios from 'axios';

// const LiveStatus = () => {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
//   });

//   const mapStyles = {
//     height: '450px',
//     width: '100%',
//   };
//   const [markers, setMarkers] = useState([]);
//   const [hoveredMarker, setHoveredMarker] = useState(null);

//   const locationLiveStatus = () => {
//     axios
//       .post(`${process.env.REACT_APP_BASE_URL}getDriverLiveStatus`, {}, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
//       .then((response) => {
//         if (response.data.status === 1 && response.data.details) {
//           // console.log(response.data.details,"det")
//           const newMarkers =   response.data.details.map((detail) => ({
          
//             // id: detail.phone, // Add a unique identifier for each marker if available
//             lat: parseFloat(detail.latitude),
//             lng: parseFloat(detail.longitude),
//             name:detail.name,
//             ambulanceNumber:detail.ambulanceNumber
//             // Other details you want to show in the InfoWindow
//             // ...
//           }));
          
//           setMarkers(newMarkers);
//           // console.log(newMarkers,"new")
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching live status:', error);
//       });
//   };

//   useEffect(() => {
//     locationLiveStatus();
//   }, []);

//   const handleMarkerMouseOver = (marker) => {
//     setHoveredMarker(marker);
//   };

//   const handleMarkerMouseOut = () => {
//     setHoveredMarker(null);
//   };
//   // console.log(markers,"marker")
//   return isLoaded ? (
//     <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={markers.length > 0 ? markers[0] : {}}>
//       {markers.map((marker, index) => (
//         <MarkerF
//           key={index}
//           position={{ lat: marker.lat, lng: marker.lng }}
//           onMouseOver={() => handleMarkerMouseOver(marker)}
//           onMouseOut={handleMarkerMouseOut}
//           icon={{
//             url: AmbLocationIcon,
//             scaledSize: new window.google.maps.Size(40, 40),
//             origin: new window.google.maps.Point(0, 0),
//             anchor: new window.google.maps.Point(20, 40),
//           }}
//         >
//           {hoveredMarker === marker && (
//             <InfoWindowF className={"infoWindow"} position={{ lat: marker.lat, lng: marker.lng }}>
//               <div>
//                 <p className='markerText'>{marker.name}</p>
//                 <p className='ambText'>{marker.ambulanceNumber}</p>
//               </div>
//             </InfoWindowF>
//           )}
//         </MarkerF>
//       ))}
//     </GoogleMap>
//   ) : (
//     <p>Loading Google Maps...</p>
//   );
// };

// export default LiveStatus;

