import React,{ useEffect , useState } from "react";
import {
 Grid ,
 Box,
 Typography,
 TextField,
 Button,
 FormLabel,
 Modal,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow ,
 Pagination,
 Stack,
} from '@mui/material';
import LocationTrack from '../../../Assets/icons/distancetraced.svg'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';

const OnGoing = () => {
    const [data ,setData] = useState([])
    const [from , setFrom] = useState("");
    const [to , setTo] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = (e) => {
      setOpen(true);
      axios.get(`${process.env.REACT_APP_BASE_URL}ongoingBookView/${e}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            setFrom(responce.data.ongoingBooking.fromLocation)
            setTo(responce.data.ongoingBooking.toLocation)
          }
        }
        ).catch((error) => {console.log(error)})
    }

    const handleClose = () => setOpen(false);
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] =useState();
    const[nodata , setNodata] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = (event, value) => {
      setPage(value-1);
    axios.get(`${process.env.REACT_APP_BASE_URL}ongoingBooking/${value-1}/10`,{type:type , id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {setData(responce.data.details)}
      ).catch((error) => {console.log(error)})
      };
      const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px !important',
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 4% 0% 4%',
        outline:0,
        borderRadius:'10px'
      };

      let id = user.id;
      let type = user.type;

      const loadOngoingTable = () => {
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_BASE_URL}ongoingBooking/0/10`,{type:type , id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              setData(responce.data.details)
              setTotalPages(responce.data.totalPages)
              if(responce.data.details.length === 0){
                setNodata(true)
              }else{
               setNodata(false)
              }
              
            }else{
              setNodata(true)
            }
            // setData(responce.data.details)
          }
      ).catch((error) => {console.log(error)})
      .finally(() => {
        setIsLoading(false); // Set loading to false once data is fetched
      });
      }

    
    const table_row = data&&data.map((row , index) => <TableRow key={row._id}>
      <TableCell  scope="row" align="center">{(page)*10+index+1}</TableCell>
      <TableCell align="center" title={row.ambulanceNumber}>{row.ambulanceNumber}</TableCell>
      <TableCell align="center" title={row.fromLocation}>{row.fromLocation}</TableCell>
      <TableCell align="center" title={row.toLocation}>{row.toLocation}</TableCell>
      <TableCell align="center">
         <Box component={"img"} src={LocationTrack} alt="trace" onClick={()=>{window.open(`/OngoingTracking/id=${row._id}` , '_blank')}} sx={{cursor:"pointer"}} />
      </TableCell>
      <TableCell align="center"> 
        <VisibilityOutlinedIcon sx={{color:'#129547',cursor:"pointer"}} onClick={()=>{handleOpen(row._id)}}/>
      </TableCell> 
  </TableRow>);

const searchFilter = (e) =>{
  if(e === ""){
    setNodata(false)
    loadOngoingTable();
  }else{
    axios.post(`${process.env.REACT_APP_BASE_URL}ongoingBookingSearch/0/10`,{id:id, type:type , search:e },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
        if(responce.data.status === 0){
          setNodata(true)
        }else if(responce.data.status === 1){
          setNodata(false);
          setData(responce.data.details)
          setTotalPages(responce.data.totalPages)
        }
      }
  ).catch((error) => {console.log(error)})
  }
  
}
useEffect(() => { 
  setInterval(()=>{
    loadOngoingTable();
  },3000)
  
},[]);

  return (
    <>
      <Grid className={'outletBody'} sx={{px:3,py:2}}>
        <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
          <Grid> 
              <Typography sx={{fontSize:'18px'}}>On going</Typography>
          </Grid>
          <Grid>
              <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                  startAdornment: (
                <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                ),
              }}/>
          </Grid>
        </Grid>
        <Grid className={'outletBodyBody'}>
          <TableContainer className="outletBodyTableContainer">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className='headerBg'>
                  <TableCell className="tableHead" align="center">S.NO</TableCell>
                  <TableCell className="tableHead" align="center">AMBULANCE NUMBER</TableCell>
                  <TableCell className="tableHead" align="center">FROM ADDRESS</TableCell>
                  <TableCell className="tableHead" align="center">TO ADDRESS</TableCell>
                  <TableCell className="tableHead" align="center">TRACK</TableCell>
                  <TableCell className="tableHead" align="center">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (nodata && !isLoading)?
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <h2> No On going Data Found</h2>
                      </TableCell>
                    </TableRow>:isLoading?<Grid mt={2}>Loading...</Grid>:table_row
                }
              </TableBody>
            </Table>
          </TableContainer>
          
        </Grid>
        <Grid className={'outletBodyFooter'} sx={{pt:1}} style={{ display:"flex", alignItems:"right", justifyContent:"right"}}>
              <Stack spacing={2}>
                <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
              </Stack>
        </Grid>
        
      </Grid>

    {/* modal */}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
          <Grid>
            <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
              On-Going details 
            </Typography>
            <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
          </Grid>
          <Box sx={{ width: '100%' }}>
              <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item md={6}>
                    <Grid item sx={{mt:2}}  >
                      <FormLabel>From</FormLabel>
                      <Typography name="name"  className={'viewBox'}>{from}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                      <Grid item sx={{mt:2}}  >
                        <FormLabel>To</FormLabel>
                        <Typography name="name"  className={'viewBox'}>{to}</Typography>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid container justifyContent='center'>
                  <Grid item sx={{my:3}} >
                    <Button className="customerLogin" bgColor='#129547' onClick={()=>{handleClose()}}>Ok</Button>
                  </Grid> 
              </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default OnGoing; 