import React from 'react'
import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import DriverImage from '../../../Assets/icons/driverimge.svg'
import DriverCall from '../../../Assets/icons/drivercall.svg'
import DriverAmbulance from '../../../Assets/icons/driverambulance.png'
import DriverPhone from '../../../Assets/icons/driverphone.png'
import DriverLocation from '../../../Assets/icons/driverlocation.svg'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import { useEffect , useState } from 'react'
import axios from 'axios';
import {useJsApiLoader , GoogleMap , MarkerF , DirectionsRenderer} from '@react-google-maps/api';
import PubNub from 'pubnub';
import ambulancetrack from '../../../Assets/icons/ambulancetrack.png'
import { useSearchParams } from 'react-router-dom';
import RazorpayPayment from "../../../Components/RazorpayPayament/razorpayPayment";


const UserBooking=()=>{

    let{ id } = useParams();
    const [searchParams] = useSearchParams();
    let idparam = id.split('=')
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const channels = ([idparam[1]]);
    const [toLatLong , setToLatLong] = useState({lat:"" , lng:""});
    const [fromLatLng , setFromLatLng] = useState({lat:"" , lng:""});
    const [payButton , setPayButton] = useState(true)
    const [payDisable , setPayDisable] = useState(true)
    const center = {lat: 17.0226374, lng: 82.2367775}
    const [bookingClose,setBookingClose]=React.useState(true);
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,libraries: ['places'],
    })
    const [trackDetails , setTrackDetails] = useState({
        name:"",
        number:"",
        ambNumber:"",
        otp:"",
        fromLoc:"",
        toLoc:"",
        ambType:"",
        price:""
    })
    const pubnub =  new PubNub({
        publishKey : "pub-c-d1177e8a-4dd3-4115-ad90-d52fddb4a310",
        subscribeKey : "sub-c-3681dda7-1b85-4efd-9575-724b4a358727",
        uuid: "3b378ddb-3178-4cec-8a5f-a70bb4bdc6dc", //generated uuid,
    });

    const checkPayStatus = () => {
        let paymentCheckStatus = searchParams.get('payment_status')
        if(paymentCheckStatus && paymentCheckStatus !== 'Failed'){
            let userCheckId = searchParams.get('userId')
            let price = searchParams.get('price').split('?')
            let PaymentId = price[1].split("=")
            // console.log(PaymentId ,'check')
            axios.post(`${process.env.REACT_APP_BASE_URL}paymentComplete`,{bookingId:idparam[1] , loginType:1 , payType:1 ,price:price[0] , userId:userCheckId, paymentId:PaymentId[1]},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    let resData = responce.data.status
                   if(resData === 1){
                        setPayButton(false);
                   }
                }
            ).catch((error) => {console.log(error)})
        }
    }

    const markerUpdate = () => {
        setInterval(()=>{
            axios.post(`${process.env.REACT_APP_BASE_URL}getToLocation/`,{bookingId:idparam[1] , userId:userlogCheck.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(toLatLong.lat !== responce.data.locationDetails.latitude || toLatLong.lng !== responce.data.locationDetails.longitude){
                        setToLatLong({
                            lat:parseFloat(responce.data.locationDetails.latitude),
                            lng:parseFloat(responce.data.locationDetails.longitude)
                        })
                    }
                }
            ).catch((error) => {console.log(error)})
            axios.post(`${process.env.REACT_APP_BASE_URL}ontheway`,{bookingId:idparam[1] , userId:userlogCheck.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    let resData = responce.data.bookingDetails
                   if(resData.pickUpStatus === 1){
                        setPayDisable(false)
                   }
                   if(resData.dropStatus === 1){
                        setBookingClose(false)
                   }
                   if(resData.paymentStatus === 1){
                        setPayButton(false);
                   }
                   
                }
            ).catch((error) => {console.log(error)})
        },10000)
        
    }

    const loadTracking=()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}ontheway`,{bookingId:idparam[1] , userId:userlogCheck.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                let resData = responce.data.bookingDetails
                setTrackDetails({...trackDetails , name:resData.driverName , number:resData.driverPhone , ambNumber:resData.ambulanceNumber , otp:resData.otp , fromLoc:resData.fromLocation , toLoc:resData.toLocation , ambType:resData.ambulanceType , price:resData.price })
                setToLatLong({
                    lat:parseFloat(resData.fromLatitude),
                    lng:parseFloat(resData.fromLongitude)
                })
               
            }
        ).catch((error) => {console.log(error)})
    }

    const markerSet = () =>{
        markerUpdate();
        setInterval(()=>{
            pubnub.history({
                channel:channels,
                reverse:true,
              },
              (status, res) => {
                let count = res.messages;
                let messagesLength = count.length-1;    
                setFromLatLng({
                    lat:res.messages[messagesLength].entry.lat,
                    lng:res.messages[messagesLength].entry.lng
                })
              })
        },3000)
    }
    
    const paymentOnline = (plan,type) =>{
        const payment = RazorpayPayment.handlePayment(plan,type,idparam[1]).then(
            (res) => {
                if(res.status === 1){
                    setPayButton(true);
                }
            }
        )
        return payment
    }

    const googleMap = fromLatLng.lat !== "" && fromLatLng.lng !== "" ? (
        <GoogleMap
            center={fromLatLng}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
        >
            <MarkerF icon={ambulancetrack} position={fromLatLng} />
            <MarkerF position={toLatLong}></MarkerF>
        </GoogleMap>
    ) : (
        <h1 className='bookingHeading'>I'm not receiving data from the driver</h1>
    );
    

    useEffect(()=>{
        loadTracking();
        pubnub.subscribe({ channels ,withPresence: true});
        markerSet();
        checkPayStatus()
    } , [])
    return(
        <>
        <Grid className='aboutBg' py={5}>
            <Container className="widthSet">
                <Grid container sx={{mt:5}} className="mapblock">
                    <Grid item md={4}>
                        <Grid item component="h2" className='bookingHeading'>
                            On the way
                        </Grid>
                        <Grid item component="h4" className='bookingHeading'>
                            {trackDetails.ambType}
                        </Grid>
                        <Grid item container spacing={3} sx={{mt:1}}>
                            <Grid item>
                                <img src={DriverImage} alt="driver"/>
                            </Grid>
                            <Grid item>
                                <Typography fontSize="14px" color="#979899">Ambulance driver</Typography>
                                <Typography className='bookingHeading'>{trackDetails.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={3} sx={{mt:1}}>
                            <Grid item>
                                <img src={DriverCall} alt="driver" className='driverphone'/>
                            </Grid>
                            <Grid item>
                                <Typography fontSize="14px" color="#979899">Ambulance driver number</Typography>
                                <Typography className='bookingHeading'>+91{trackDetails.number}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={3} sx={{mt:1}}>
                            <Grid item>
                                <img src={DriverAmbulance} alt="driver"/>
                            </Grid>
                            <Grid item>
                                <Typography fontSize="14px" color="#979899">Ambulance number</Typography>
                                <Typography className='bookingHeading'>{trackDetails.ambNumber}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={3} sx={{mt:1}}>
                            <Grid item>
                                <img src={DriverPhone} alt="driver" />
                            </Grid>
                            <Grid item>
                                <Typography fontSize="14px" color="#979899">OTP</Typography>
                                <Typography className='bookingHeading'> {trackDetails.otp}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={3} sx={{mt:1}}>
                            <Grid sx={{mt:3}}>
                                <img src={DriverLocation} alt="driver" height={'100%'} />
                            </Grid>
                            <Grid item style={{width:"80%"}}>
                                <Grid item>
                                    <Typography fontSize="14px" color="#979899">Pick up from</Typography>
                                    <Typography className='bookingHeading'>{trackDetails.fromLoc}</Typography>
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <Typography fontSize="14px" color="#979899">Drop at</Typography>
                                    <Typography className='bookingHeading'>{trackDetails.toLoc}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={{mb:4}}>
                                {payButton?<Button className={payDisable?'paymentbtn1':'paymentbtn2'}
                                //  onClick={()=>{setLogOutopen(true)}}
                                onClick={()=>{paymentOnline(trackDetails ,2)}}
                                disabled={payDisable} >Payment &nbsp; &nbsp;₹{trackDetails.price}</Button>:<Button className="payComplete">Payment Completed</Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {bookingClose ? (
                                <Grid item md={8} mb={5} className="dashboard_bg trackingHeight">
                                    {isLoaded ? googleMap : <h1 className='bookingHeading'>Loading...</h1>}
                                </Grid>
                            ) : (
                                <Grid item md={8} className="dashboard_bg trackingHeight">
                                    <Grid sx={{ height: '400px' }} container justifyContent="center" alignItems="center">
                                        <h1 className='bookingHeading'>Booking Completed</h1>
                                    </Grid>
                                </Grid>
                            )}
                </Grid>
            </Container>
        </Grid>



        
        </>
    )
}
export default UserBooking