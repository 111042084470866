import React, { useEffect, useState } from "react";
import { 
    Grid, 
    Typography, 
    TextField,
    FormLabel,
    Select,
    MenuItem,
    Button,
    Modal,
    Box,
    Backdrop,
    Snackbar,
    Alert
} from "@mui/material";
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie';
import AlertJson from '../../../Assets/icons/alert.json';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import axios from "axios";

export const AdminSubscriptionPlan = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [plan, setPlan] = useState("");
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [planNames, setPlanNames] = useState([]);
    const [planDetails, setPlanDetails] = useState([]);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage, setSmessage] = useState(false);
    const [emessage, setEmessage] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [editPlan, setEditPlan] = useState(null); 
    const [updateButton, setUpdateButton] = useState(false);
    const [planInputClass, setPlanInputClass] = useState("");
    const [showForm,setShowForm] = useState(true);
    const handleClose = () => setOpen(false);
    const handleCloseDelete = () => setOpenDelete(false);
    const handleChangePlan = (event) => {
        setPlan(event.target.value);
    }
    const ErrorClose = () => {
        setEmessage(false);
    }
    const SuccessClose = () => {
        setSmessage(false);
    }
    const handleOpenDelete = (id) => {
        setDeleteId(id);
        if(updateButton !== true){
            setOpenDelete(true);
        }
    }
    const handleOpenEdit = (plan) => {
        setShowForm(true)
        setUpdateButton(true)
        setEditPlan(plan);
        setPlanInputClass("viewBox"); 
        window.scrollTo(0, 0);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
    };

    const DeleteStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline: 'none',
        p: 5,
        textAlign: 'center',
        borderRadius: '10px'
    };
    
    

    const GetPlans = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getPlanNames`, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
            .then((response) => {
                setPlanNames(response.data.getPlanNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const GetPlanDetails = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}planDetails`, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
            .then(response => {
                setPlanDetails(response.data.data);
            })
            .catch(error => {
                console.error("Error fetching plan details:", error);
            });
    };
    
    const TotalPlanDetails = planDetails && planDetails.length > 0 && (
        planDetails.map((plan) => {
            // Define a function to map plan properties to background gradients
            const getBackgroundGradient = (property) => {
                switch(property) {
                    case 'GOLD':
                        return 'linear-gradient(95.47deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%)';
                    case 'SILVER':
                        return 'linear-gradient(95.47deg, #717171 -0.22%, #7B7B7B 10.66%, #CBCBCB 26.65%, #D1D1D1 78.95%, #818181 93%, #9B9B9B 97.24%)';
                    case 'BRONZE':
                        return 'linear-gradient(95.47deg, #D66D0B -0.22%, #E9770C 17.32%, #FAC39C 53.92%, #FACAA8 71.98%, #F26E0F 86.86%, #F58B40 97.24%)';
                    default:
                        return ''; 
                }
            };
            return (
                <Grid item md={4} pb={5} key={plan._id}>
                    <Grid className="AdminitemBoxShadow" >
                        <Grid container p={3} style={{ background: getBackgroundGradient(plan.planName) }} alignItems={'center'}>
                            <Grid item md={6}>
                                <Typography variant="h2" className={'planName'}>{plan.planName}</Typography>
                                <Typography variant="h2" className={'planText'}>Plan</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="h2" className={'planName'} sx={{ textAlign: 'center'}}>Rs.{plan.amount}</Typography>
                            </Grid>
                        </Grid>
                        <Grid className="AdminOrderList" style={{ whiteSpace: 'pre-wrap' }}>
                            {plan.description}
                        </Grid>
                    </Grid>
                    <Grid container md={12} justifyContent="end" className="hospitalName">
                        <Grid item md={4} sx={{ textAlign: "left" }}>
                            <BorderColorOutlinedIcon className="actioinIcon hospitallogodeleteicon" sx={{ color: '#129547', cursor: 'pointer' }} onClick={() => { handleOpenEdit(plan) }}/>
                            <DeleteOutlineOutlinedIcon sx={{ color: '#E11F26', cursor: 'pointer' }} className="hospitallogodeleteicon" onClick={() => { handleOpenDelete(plan._id) }} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        })
    );
    

    const TotalPlans = planNames.map((plan, index) => (
        <MenuItem 
        key={index} 
        value={plan._id} 
        disabled={planDetails&&planDetails.map(planData=>planData.planNameId).includes(plan._id)}
        >
            {plan.name}
        </MenuItem>
    ));


    const onSubmit = (data) => {
        const formData = {
            id: editPlan && editPlan._id,
            amount: data.amount,
            description: data.description
        };
        if(updateButton === true){
            setLoaderOpen(true);
            axios.put(`${process.env.REACT_APP_BASE_URL}planDetails`, formData, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
                .then((response) => {
                if (response.data.status === 1) {
                    setLoaderOpen(false);
                    setSmessage(true);
                    setSuccessMsg(response.data.message);
                    GetPlanDetails();
                    setUpdateButton(false);
                    setPlanInputClass(""); 
                    reset({
                        amount: '',
                        description: ''
                    });
                    setPlan("");
                } else if (response.data.status === 0) {
                    setLoaderOpen(false);
                    setEmessage(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoaderOpen(false);
                setEmessage(true);
                setErrorMsg("An error occurred while submitting the form.");
            });
            
        }
        else{
            const formData = {
                planNameId: data.plan,
                amount: data.amount,
                description: data.description
            };
            setLoaderOpen(true);
            axios.post(`${process.env.REACT_APP_BASE_URL}planDetails`, formData, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
                .then((response) => {
                if (response.data.status === 1) {
                    setLoaderOpen(false);
                    setSmessage(true);
                    setSuccessMsg(response.data.message);
                    GetPlanDetails();
                    setUpdateButton(false);
                    reset({
                        amount: '',
                        description: ''
                    });
                    setPlan("");
                } else if (response.data.status === 0) {
                    setLoaderOpen(false);
                    setEmessage(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoaderOpen(false);
                setEmessage(true);
                setErrorMsg("An error occurred while submitting the form.");
            });
        }
    };
    

    const Delete = () => {
        const payload = {
            id: deleteId
        };
        axios.delete(`${process.env.REACT_APP_BASE_URL}planDetails`,{
            data: payload,
            headers: {
                Authorization: process.env.REACT_APP_AUTHORIZATION
            }
        }).then((response) => {
            if (response.data.status === 1) {
                setSmessage(true);
                setSuccessMsg(response.data.message);
                setOpenDelete(false);
                GetPlanDetails();
                setUpdateButton(false)
            } else {
                setEmessage(true);
                setErrorMsg(response.data.message);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        GetPlans();
        GetPlanDetails();
    }, []);

    useEffect(() => {
        if (editPlan) {
            setPlan(editPlan.planNameId);
            reset({
                plan: editPlan.planNameId,
                amount: editPlan.amount,
                description: editPlan.description,
            });
        }
    }, [editPlan, reset]);

    useEffect(() => {
        if (planDetails && planDetails.length < 2) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [planDetails]);
    return (
        <>
            <Grid className={'outletBody'} sx={{ px: 3, py: 2 }}>
                <Grid className={'outletBodyHeading'} container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid>
                        <Typography sx={{ fontSize: '18px' }}>Subscription plans</Typography>
                    </Grid>
                </Grid>
                <Grid className={'outletBodyBody'}>
                    {showForm &&  <Grid component={'form'}
                    onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container md={8} spacing={2} >
                            <Grid item md={6} sx={{ mt: 2 }}>
                                <FormLabel>Plan</FormLabel>
                                <Select
                                    disableUnderline
                                    className={`selectOptions ${planInputClass}`}
                                    displayEmpty
                                    fullWidth
                                    value={plan}
                                    placeholder="Select plan"
                                    disabled={updateButton}
                                    {...register('plan', { required: true })}
                                    onChange={handleChangePlan}
                                >
                                    <MenuItem value="">
                                        <em>Select Plan</em>
                                    </MenuItem>
                                    {TotalPlans}
                                </Select>
                                {!plan && errors.plan && errors.plan.type === "required" && <p className='error mt-0'>Please select plan</p>}
                            </Grid>
                            <Grid item md={6} sx={{ mt: 2 }}>
                                <FormLabel>Amount</FormLabel>
                                <TextField name="amount" className={'text_field'} {...register('amount', { required: true })} />
                                {errors.amount && errors.amount.type === "required" && <p className='error mt-0'>Please enter amount</p>}
                            </Grid>
                            <Grid item md={12} sx={{ mt: 2 }}>
                                <FormLabel>Description</FormLabel>
                                <Grid item sx={{ display: 'flex' }}>
                                    <TextField fullWidth className="address_field" multiline variant='outlined' rows="4" size="small"
                                    {...register('description', { required: true })}
                                    />
                                </Grid>
                                {errors.description && errors.description.type === "required" && <p className='error mt-0'>Please add description</p>}
                            </Grid>
                            <Grid container justifyContent='center'>
                                <Grid item sx={{ my: 3 }} >
                                    <Button className="customerLogin" bgColor='#129547' type="submit">{updateButton ? "Update" : "Submit"}</Button>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={loaderOpen}
                                        onClick={handleClose}
                                        invisible={true}
                                    >
                                        <Lottie
                                            options={defaultOptions}
                                            height={100}
                                            width={100}
                                        />
                                    </Backdrop>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                    <Grid container spacing={3} sx={{overflow:'hidden'}}>
                        {TotalPlanDetails}
                    </Grid>
                </Grid>
            </Grid>

            {/* Modal */}
            <Modal
                open={openDelete}
                // onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={DeleteStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Do you want to delete ?
                    </Typography>
                    <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                        <Button className="yesButton" onClick={Delete}  >Yes</Button>
                        <Button className="noButton" onClick={handleCloseDelete}>No</Button>
                    </Grid>
                </Box>
            </Modal>
            <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
                <Alert onClose={SuccessClose} severity="success" className="snackBar">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
                <Alert onClose={ErrorClose} severity="error" className="snackError">
                    {errorMsg}
                </Alert>
            </Snackbar>
        </>
    );
};
