import React,{ useState ,useEffect }from "react";
import { Grid,Container,Typography,Avatar,Stack} from "@mui/material";
import Logo from "../../../Assets/icons/header_logo.svg"
import Avatar_img from '../../../Assets/icons/avatar.svg'
import {  useNavigate } from "react-router-dom";

const TrakingHeader = () => {
    const [open, setOpen] = React.useState(false);
    const [name ,setName] = useState("")
    const handleClose = () => setOpen(false);
    const navigate =  useNavigate();
    useEffect(()=>{
        const user = JSON.parse(sessionStorage.getItem("userType"));
        setName(user.name);
        // setUser(user);
    },[])

    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        outline:0,
        textAlign:'center',
        borderRadius:'10px'
      };
    
        const LogOut = () => {
        let user = JSON.parse(sessionStorage.getItem("userType"));
        if(user.userType === "Admin"){
            navigate('/login');
            sessionStorage.removeItem("userType")
        }
        else if(user.userType === "Employee"){
            navigate('/EmployeesLogin');
            sessionStorage.removeItem("userType")
        }
        else if(user.userType === "Sub_admin"){
            navigate('/subAdmin');
            sessionStorage.removeItem("userType")
        }
        else if(user.userType === "Hospital"){
            navigate('/HospitalLogin');
            sessionStorage.removeItem("userType")
        }
    }

   
    return (
    <>
        <Grid className={"header_box"}>
            <Container className="widthSet">
                <Grid container direction="row" justifyContent="space-between" alignItems="center"  md={12} >
                    <Grid item>
                        <img src={Logo} alt="logo"/>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt="Remy Sharp" src={Avatar_img} />
                            <Typography>{name}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </>
    )
}

export default TrakingHeader;