import React, { useEffect, useState } from "react"
import { 
    Grid, 
    Typography, 
    TableContainer,
    Table, 
    TableRow, 
    TableHead, 
    TableCell, 
    TableBody, 
    Button,
    Stack ,
    Pagination,
    TextField,
    Select,
    MenuItem,
    Modal,
    FormLabel,
    Box,
    Snackbar,
    Alert,
    Backdrop
    
} from "@mui/material" 
import Lottie from 'react-lottie' 
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import AlertJson from '../../../Assets/icons/alert.json'
export const AdminLabouratoryList = () => {
    const [page, setPage] = useState(0);
    const [totalPages , setTotalPages] = useState();
    const [state , setState] = useState("")
    const [city , setCity] = useState("");
    const [district , setDistrict] = useState("");
    const [otherState, setOtherState] = useState("");
    const [otherCity , setOtherCity] = useState("");
    const [otherDistrict , setOtherDistrict] = useState("");
    const [otherStates , setOtherStates] = useState([]);
    const [otherCities , setOtherCities] = useState([]);
    const [otherDistricts , setOtherDistricts] = useState([]);
    const [states , setStates] = useState([])
    const [districts , setDistricts] = useState([]);
    const [cities , setCities] = useState([]);
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const {register,handleSubmit,setValue,formState: { errors },reset} = useForm();
    const {register:register1,handleSubmit:handleSubmit1,formState: { errors:errors1 }} = useForm();
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [laboratoryList , setLaboratoryList] = useState([])
    const [searchData,setSearchData] = useState("");
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [viewModal,setViewModal] = useState(false);
    const [editModal,setEditModal] = useState(false);
    const [otherViewLaboratoryData,setOtherViewLaboratoryData] = useState({
            name : "",
            email : "",
            phone : "",
            state : "",
            district : "",
            city : "",
            address : "",
            discount : "",
            password : "",
            branch : ""
    });
    const [viewLaboratoryData,setViewLaboratoryData] = useState(
        {
            name : "",
            email : "",
            phone : "",
            state : "",
            district : "",
            city : "",
            address : "",
            discount : "",
            password : "",
            branch : ""
        }
    );
    const [laboratoryId,setLaboratoryId] = useState("");
    const [handleStateChange , setHandleStateChange] = useState(true);
    const [handleDistrictChange , setHandleDistrictChange] = useState(true);
    const [handleCityChange , setHandleCityChange] = useState(true);
    const [openBlock, setOpenBlock] = useState(false);
    const [openunBlock, setOpenunBlock] = useState(false);
    const [approvalOpen, setApprovalOpen] = useState(false);
    const [rejectOpen, setRejectOpen] = useState(false);

    
    const approvalHandleClose = () => setApprovalOpen(false);
    const handleCloseBlock = () => setOpenBlock(false);
    const handleCloseunBlock = () => setOpenunBlock(false);
    const rejectHandleClose = () => setRejectOpen(false);
    const handleClose = () =>{ 
        setOpen(false)
        reset();
    };
    const handleOpenViewModal = (id) => {
        setViewModal(true)
        setLaboratoryId(id)
    }
    const handleCloseViewModal = () => {
        setViewModal(false)
    }
    const handleOpenEditModal = (id) => {
        setEditModal(true)
        setLaboratoryId(id)
    }
    const handleCloseEditModal = () => {
        setEditModal(false)
        reset();
    }
   
    const approvalHandleOpen = (id) =>{
        setLaboratoryId(id)
      setApprovalOpen(true)
    };
    
    const rejectHandleOpen = (id) => {
        setLaboratoryId(id)
      setRejectOpen(true)
    };

    const handleOpenBlock = (id , type) => {
        if(type === "block"){
            setOpenBlock(true)
            setLaboratoryId(id)
        }else if(type === "Unblock"){
            setOpenunBlock(true)
            setLaboratoryId(id)
        }
    };
    const ErrorClose = () => {
        setEmessage(false)
    }
    const SuccessClose=() => {
        setSmessage(false);
    }
   const handleOpen = () => {
        setOpen(true);
    }
    const stateChange = (event) => {
        setState(event.target.value)
        setDistrict("")
        setCity("")
        getDistricts(event.target.value)
    }
    const otherStateChange = (event) => {
        setOtherState(event.target.value)
        setOtherDistrict("")
        setOtherCity("")
        setOtherCities([])
        getOtherDistricts(event.target.value)
    }
    const districtChange = (event) => {
        setDistrict(event.target.value);
        setCity("")
        getCities(event.target.value);
    };
    const otherDistrictChange = (event) => {
        setOtherDistrict(event.target.value);
        setOtherCity("");
        getOtherCities(event.target.value);
    };

    const cityChange = (event) => {
        setCity(event.target.value);
    }
    const otherCityChange = (event) => {
        setOtherCity(event.target.value);
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
     }
     const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height:500,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 2% 0% 2%',
        outline:0,
        borderRadius:'10px',
    };
    const approvalStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        outline:0,
        textAlign:'center',
        borderRadius:'10px'
      };

    const handleSearchData = (event) => {
        setSearchData(event.target.value)
        gettingLaboratoryList()
    }
    
        const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
    }
    const getOtherStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setOtherStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
    }
    const getDistricts = (event)=> {
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                }else{
                    setDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const getOtherDistricts = (event)=> {
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setOtherDistricts(responce.data.result)
                }else{
                    setOtherDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const getCities = (event) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:"" , districtId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setCities(responce.data.result)
                }else {
                    setCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const getOtherCities = (event) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:"" , districtId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setOtherCities(responce.data.result)
                }else {
                    setOtherCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const gettingLaboratoryList = () => {
        const payload = {
            page:0,
            size:10,
        }
        if (state !== "") {
            payload.state = state;
        }
        if (city !== "") {
            payload.city = city;
        }
        if (district !== "") {
            payload.district = district;
        }
        if (searchData !== ""){
            payload.searchBy = searchData
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}laboratoryList`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                   
                    setLaboratoryList(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                }else {
                    setTotalPages(0)
                }
            }).catch((error) => {console.log(error)})
        }
        const handleChange = (event, value) => {
            setPage(value-1);
            const payload = {
                page:value-1,
                size:10,
            }
            if (state !== "") {
                payload.state = state;
            }
            if (city !== "") {
                payload.city = city;
            }
            if (district !== "") {
                payload.district = district;
            }
            if (searchData !== ""){
                payload.searchBy = searchData
            }
            
            axios.post(`${process.env.REACT_APP_BASE_URL}laboratoryList`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
                (responce) => {setLaboratoryList(responce.data.details)}
            ).catch((error) => {console.log(error)})
        };

    const gettingLaboratoryViewList = () => {
        
        axios.get(`${process.env.REACT_APP_BASE_URL}laboratory?id=${laboratoryId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    getOtherDistricts(responce.data.data.state)
                    getOtherCities(responce.data.data.district)
                    setViewLaboratoryData(responce.data.data)
                    setOtherViewLaboratoryData(responce.data.data)
                }else {
                    setViewLaboratoryData({})
                }
            }).catch((error) => {console.log(error)})
    }   

    const TotalPharmaList = laboratoryList.map((row, index) =>
        <TableRow key={index}>
            <TableCell align="center">{(page)*10+index+1}</TableCell>
            <TableCell align="center" >{row.name}</TableCell>
            <TableCell align="center" >{row.phone}</TableCell>
            <TableCell align="center" >{row.email}</TableCell>
            <TableCell align="center" >{row.branch}</TableCell>
            <TableCell align="center" >{row.discount}</TableCell>
            <TableCell align="center" >{row.cityName}</TableCell>
            {row.status === 1 && (<TableCell align="center">
                <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
                onClick={() => {handleOpenViewModal(row._id)} }
                />
                <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
                onClick={() => {handleOpenEditModal(row._id)}}
                />
                <BlockOutlinedIcon className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}
                    onClick={()=>{handleOpenBlock(row._id , "Unblock")} }
                />
            </TableCell>)}
            {row.status === 2 && (<TableCell align="center">
                <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
                onClick={() => {handleOpenViewModal(row._id)} }
                />
                <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
                onClick={() => {handleOpenEditModal(row._id)}}
                />
                <BlockOutlinedIcon className="actioinIcon" sx={{cursor:'pointer'}}
                    onClick={()=>{handleOpenBlock(row._id , "block")} }
                />
            </TableCell>)}
            {row.status === 0 && (<TableCell align="center" >
                <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon" 
                    onClick={()=>{approvalHandleOpen(row._id)}}
                />
                <CancelOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="actioinIcon"
                // onClick={()=>{rejectHandleOpen(row._id)}}
                 onClick={()=>{rejectHandleOpen(row._id , 2)}}
                />
                </TableCell>)}
        </TableRow>

        
        );

    const DistrictOptions = districts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
    const cityOptions = cities.map(option =><MenuItem key={option._id} value={option._id}>{option.city}</MenuItem>)
    const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)

    const otherDistrictOptions = otherDistricts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
    const otherCityOptions = otherCities.map(option =><MenuItem key={option._id} value={option._id}>{option.city}</MenuItem>)
    const otherStateOptions = otherStates.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)

    const onSubmit = (data) => {
        setLoaderOpen(true)
        const payload = {
            name:data.name,
            email:data.email,
            phone:data.phone,
            branch:data.branch,
            address:data.address,
            state:data.state,
            district:data.district,
            city:data.city,
            discount:data.discount,
            password:data.password,
            userPrivilege:user.name.toLowerCase(),
            createdBy:user.id
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}laboratory`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setLoaderOpen(false)
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setOpen(false)
                    gettingLaboratoryList()
                    reset({
                        name: '',
                        email: '',
                        phone: '',
                        branch: '',
                        address: '',
                        discount: '',
                        password: '',
                    })
                    setOtherState("")
                    setOtherDistrict("")
                    setOtherCity("")
                    
                }else{
                    setLoaderOpen(false)
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }

                }
            ).catch((error) => {console.log(error)})
    }

    const onEditSubmit = (event) => {
        event.preventDefault();

        const payload = {
            name:otherViewLaboratoryData?.name,
            phone:otherViewLaboratoryData?.phone,
            password:otherViewLaboratoryData?.password,
            branch:otherViewLaboratoryData?.branch,
            address:otherViewLaboratoryData?.address,
            state:otherViewLaboratoryData?.state,
            district:otherViewLaboratoryData?.district,
            city:otherViewLaboratoryData?.city,
            discount:otherViewLaboratoryData?.discount,
            id:laboratoryId
        }
        axios.put(`${process.env.REACT_APP_BASE_URL}laboratory`,payload,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setLoaderOpen(false)
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setEditModal(false)
                    gettingLaboratoryList()
                    reset({
                        name: '',
                        email: '',
                        phone: '',
                        branch: '',
                        address: '',
                        discount: '',
                    })
                }else{
                    setLoaderOpen(false)
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const approveReject = ( id , status ) =>{
        axios.patch(`${process.env.REACT_APP_BASE_URL}laboratory`,{id:id,status:status},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
            if(responce.data.status === 1){
                setSmessage(true);
                gettingLaboratoryList();
                setSuccessMsg(responce.data.message);
                setApprovalOpen(false);
                setRejectOpen(false);
            }else{
              setEmessage(true);
              setErrorMsg(responce.data.message);
            }
        }
        ).catch((error) => {console.log(error)})
    }
      const Block = (id , status) =>{
        axios.patch(`${process.env.REACT_APP_BASE_URL}laboratory`,{id:id,status:status},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true);
                    setSuccessMsg(responce.data.message)
                    setOpenBlock(false)
                    gettingLaboratoryList();
                    setPage(0);
                }else{
                    setEmessage(true);
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }

     const unBlock = (id,status) =>{
        axios.patch(`${process.env.REACT_APP_BASE_URL}laboratory`,{id:id,status:status},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setPage(0);
                    setSmessage(true);
                    setSuccessMsg(responce.data.message)
                    setOpenunBlock(false)
                    gettingLaboratoryList();
                }else{
                    setEmessage(true);
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }
    

    useEffect(() => {
        allStates()
        getOtherStates()
        gettingLaboratoryList() 
    },[])

    useEffect (() => {
        gettingLaboratoryList()
    },[searchData,state,district,city])


    useEffect (() => {
     if (viewModal||editModal) {
        gettingLaboratoryViewList()
     }  
    },[viewModal,editModal])


    return(
        <>
        <Grid className={'outletBody'} sx={{px:3,py:2}}>
            <Grid className={'outletBodyHeading'} mb={3} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item md={2}> 
                    <Typography sx={{fontSize:'18px'}}>Laboratory List</Typography>
                </Grid>
                <Grid container md={10} justifyContent={'space-between'}>
                    <Grid item md={2.5} fullWidth>
                        <Select
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={state}
                        placeolder="Select state"
                        onChange={stateChange} >
                            <MenuItem value="">
                                <em>Select State</em>
                            </MenuItem>
                            {stateOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth>
                        <Select 
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={district}
                        placeolder="Select District"
                        onChange={districtChange}
                        >
                        <MenuItem value="">
                            <em>Select district</em>
                        </MenuItem>
                            {DistrictOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth>
                        <Select 
                        disableUnderline
                        displayEmpty
                        className='selectOptions'
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={city}
                        placeolder="Select City"
                        onChange={cityChange}>
                            <MenuItem value="">
                                <em>Select City</em>
                            </MenuItem>
                            {cityOptions}
                        </Select>
                    </Grid>
                    <Grid item md={2.5} fullWidth sx={{marginRight:'20px'}}>
                        <TextField className="text_field" placeholder="search"
                         onChange={handleSearchData} 
                         fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899'}} />
                          ),
                        }}/>
                    </Grid>
                    <Grid item md={1} fullWidth sx={{ display: 'flex',alignItems:'center' }}>
                        <Typography className="add_text">Add </Typography>
                        <AddCircleIcon className="Add_icon" 
                         onClick={handleOpen} 
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'outletBodyBody'}>
                <TableContainer className="outletBodyTableContainer">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">NAME</TableCell>
                                <TableCell className="tableHead">PHONE NUMBER</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">BRANCH</TableCell>
                                <TableCell className="tableHead">DISCOUNT</TableCell>
                                <TableCell className="tableHead">CITY</TableCell>
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {TotalPharmaList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <h2> No Data Found </h2>
                                    </TableCell>
                                </TableRow> 
                            ):(
                            TotalPharmaList
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className={'outletBodyFooter'}  style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                <Stack spacing={2}>
                    <Pagination 
                    count={totalPages} 
                    page={page+1} 
                    onChange={handleChange} 
                    className={"paginationAlign"}
                    />
                </Stack>
            </Grid>
        </Grid>

        {/* Add Modal */}
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
                <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                        Add Laboratory
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid component={'form'} className="modalscrollwidth"
                     onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container  alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid  md={6} item sx={{mt:2}}>
                                <FormLabel>Name</FormLabel>
                                 <TextField name="name" className={'text_field'} {...register('name',{required: true})} />
                                {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter Name</p>}
                            </Grid>
                            <Grid item md={6} sx={{mt:2}}  >
                                <FormLabel>Phone number</FormLabel>
                                <TextField className={'text_field'} type="number" {...register('phone',{required: true,pattern:/^\d{10}$/})}></TextField>
                                {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>please enter valid Number</p>}
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Email</FormLabel>
                                <TextField fullWidth name="email" className={'text_field'} {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></TextField>
                                {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                            </Grid>
                            <Grid item  md={6}  sx={{mt:2}}>
                                <FormLabel sx={{color:"black"}}>Password</FormLabel>
                                <TextField name="password"  className={'text_field'} {...register('password',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}> </TextField>
                                {errors.password && errors.password.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                {errors.password && errors.password.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Branch</FormLabel>
                                <TextField name="branch" className={'text_field'} {...register('branch',{required: true})} />
                                {errors.branch && errors.branch.type === "required" && <p className='error mt-0'>Please enter branch</p>}
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Address</FormLabel>
                                <Grid item sx={{display:'flex'}}>
                                    <TextField fullWidth   className="address_field "  multiline variant='outlined' rows="2" size="small"   
                                    {...register('address',{required: true})} 
                                    />
                                </Grid>
                                {errors.address && errors.address.type === "required" && <p className='error mt-0'>Please enter address</p>}
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Discount</FormLabel>
                                <TextField name="discount" type="number" className={'text_field'} {...register('discount',{required: true})} 
                                InputProps={{ 
                                    inputProps: { 
                                        max: 99,
                                        onInput: (e) => {
                                            e.target.value = Math.min(parseInt(e.target.value), 99);
                                        }
                                    }
                                }} />
                                {errors.discount && errors.discount.type === "required" && <p className='error mt-0'>Please enter discount</p>}
                            </Grid>
                            <Grid item md= {6}  sx={{mt:2}}  >
                                <FormLabel>State</FormLabel>
                                <Select
                                disableUnderline
                                displayEmpty
                                className='selectOptions'fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={otherState}
                                placeolder="Select state"
                                {...register('state',{required: true})}
                                onChange={otherStateChange}
                                 >
                                    <MenuItem value="">
                                        <em>Select State</em>
                                    </MenuItem>
                                    {otherStateOptions}
                                </Select>
                                {!otherState&&errors.state && errors.state.type === "required" && <p className='error mt-0'>Please enter state</p>}
                            </Grid>
                            <Grid md={6} item sx={{mt:2}}  >
                                <FormLabel >District</FormLabel>
                                <Select 
                                disableUnderline
                                displayEmpty
                                className='selectOptions'fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={otherDistrict}
                                placeolder="Select District"
                                {...register('district',{required: true})}
                                onChange={otherDistrictChange}
                                >
                                <MenuItem value="">
                                    <em>Select district</em>
                                </MenuItem>
                                {otherDistrictOptions}
                                </Select>
                                {!otherDistrict&&errors.district && errors.district.type === "required" && <p className='error mt-0'>Please select district</p>}
                            </Grid>
                            <Grid md={6} item sx={{mt:2}}  >
                                <FormLabel>City</FormLabel>
                                <Select 
                                disableUnderline
                                displayEmpty
                                className='selectOptions'
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={otherCity}
                                placeolder="Select City"
                                {...register('city',{required: true})}
                                onChange={otherCityChange}>
                                    <MenuItem value="">
                                        <em>Select City</em>
                                    </MenuItem>
                                    {otherCityOptions}
                                </Select>
                                {!otherCity&&errors.city && errors.city.type === "required" && <p className='error mt-0'>Please add city</p>}
                            </Grid>
                            </Grid>
                        
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}} >
                                <Button className="customerLogin" type="submit">Add</Button>
                                <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loaderOpen}
                                onClick={handleClose}
                                invisible={true}
                            >
                                <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                                />
                            </Backdrop>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
        {/*Start View Modal */}
        <Modal
            open={viewModal}
            // onClose={handleCloseView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <CloseIcon 
                onClick={()=>{handleCloseViewModal()}} 
                className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                    View details
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="modalscrollwidth">
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Name</FormLabel>
                            <Typography fullWidth  className={'viewBox'}>{viewLaboratoryData?.name}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Phone number</FormLabel>
                            <Typography fullWidth  className={'viewBox'} >{viewLaboratoryData?.phone}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Email</FormLabel>
                            <Typography fullWidth  className={'viewBox'} >{viewLaboratoryData?.email}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Password</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.password}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Branch</FormLabel>
                            <Typography fullWidth  className={'viewBox'} >{viewLaboratoryData?.branch}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Address</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.address}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>Discount</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.discount}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>State</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.stateName}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>District</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.districtName}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{mt:2}}  >
                            <FormLabel>City</FormLabel>
                            <Typography fullWidth className={'viewBox'} >{viewLaboratoryData?.cityName}</Typography>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}} >
                                <Button className="customerLogin"bgColor='#129547' 
                                onClick={()=>{handleCloseViewModal()}} 
                                >Ok</Button>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>

        {/* Start Edit Modal */}
        <Modal
            open={editModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseEditModal()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                        Edit Laboratory
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid component={'form'} className="modalscrollwidth"
                     onSubmit={onEditSubmit}
                    >
                        <Grid container alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid md={6} item sx={{mt:2}}>
                                <FormLabel>Name</FormLabel>
                                <TextField 
                                className={'text_field'}
                                name="editName"
                                value={otherViewLaboratoryData?.name}
                                {...register1('editName',{required: !viewLaboratoryData?.name})} 
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    name:e.target.value
                                })}}
                               
                                />
                            </Grid>
                            <Grid item md={6} sx={{mt:2}}  >
                                <FormLabel>Phone number</FormLabel>
                                <TextField 
                                className={'text_field'}
                                value={otherViewLaboratoryData?.phone}
                                type="number" 
                                name="editPhone"
                                {...register1('editPhone',{required: !viewLaboratoryData?.phone,pattern:/^\d{10}$/})}
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    phone:e.target.value
                                })}}
                                />
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Email</FormLabel>
                                <TextField 
                                fullWidth 
                                name="editEmail" 
                                className={'text_field'}
                                value={viewLaboratoryData?.email} 
                                disabled
                                {...register1('editEmail',{required: !viewLaboratoryData?.email,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                onChange={(e)=>{setViewLaboratoryData({
                                    ...viewLaboratoryData,
                                    email:e.target.value
                                })}}
                                />
                            </Grid>
                            <Grid item  md={6}  sx={{mt:2}}>
                                <FormLabel sx={{color:"black"}}>Password</FormLabel>
                                <TextField 
                                name="editPassword"
                                className={'text_field'} 
                                value={otherViewLaboratoryData?.password}
                                {...register1('editPassword',{required: !viewLaboratoryData?.password,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    password:e.target.value
                                })}}
                                />
                                {errors1.password && errors1.password.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                {errors1.password && errors1.password.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Branch</FormLabel>
                                <TextField 
                                className={'text_field'}
                                name="editBranch"
                                value={otherViewLaboratoryData?.branch}
                                {...register1('editBranch',{required: !viewLaboratoryData?.branch})} 
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    branch:e.target.value
                                })}}
                               
                                />
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Address</FormLabel>
                                <TextField 
                                fullWidth   
                                name="editAddress"
                                className="address_field " 
                                multiline 
                                value={otherViewLaboratoryData?.address}
                                variant='outlined' 
                                rows="2" 
                                size="small"  
                                
                                {...register1('editAddress',{required: !otherViewLaboratoryData?.address})}
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    address:e.target.value
                                })}}
                                />
                                
                            </Grid>
                            <Grid item md={6}  sx={{mt:2}}>
                                <FormLabel>Discount</FormLabel>
                                <TextField 
                                name="editDiscount"
                                className={'text_field'} 
                                type="number"
                                value={otherViewLaboratoryData?.discount}
                                {...register1('editDiscount',{required: !viewLaboratoryData?.discount})}
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    discount:e.target.value
                                })}}
                                InputProps={{ 
                                    inputProps: { 
                                        max: 99,
                                        onInput: (e) => {
                                            e.target.value = Math.min(parseInt(e.target.value), 99);
                                        }
                                    }
                                }} 
                                />
                            </Grid>
                            <Grid item md={6} sx={{mt:2}}>
                                <FormLabel>State</FormLabel>
                                <Select
                                disableUnderline
                                displayEmpty
                                className='selectOptions'
                                fullWidth
                                value={Object.keys(otherViewLaboratoryData).length !== 0&&otherViewLaboratoryData?.state}
                                placeolder="Select state"
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    state:e.target.value,
                                    city:"",
                                    district:"",
                                    districtName:""
                                    })
                                    otherStateChange(e)
                                    setHandleStateChange(false);
                                    setHandleDistrictChange(false);
                                }}
                                >
                                    <MenuItem value="">
                                        <em>Select State</em>
                                    </MenuItem>
                                    {otherStateOptions}
                                </Select>
                            </Grid>
                            <Grid md={6} item sx={{mt:2}}  >
                                <FormLabel>District</FormLabel>
                                <Select 
                                disableUnderline
                                displayEmpty
                                className='selectOptions'
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={Object.keys(otherViewLaboratoryData).length !== 0&&otherViewLaboratoryData?.district}
                                placeholder="Select District"
                                {...register1('district',{required: true})}
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    district:e.target.value,
                                    city:"",
                                    })
                                    otherDistrictChange(e)
                                    setHandleDistrictChange(false);
                                    setHandleCityChange(false)
                                    getCities(e.target.value);
                                }}
                                
                                >
                                <MenuItem value="">
                                    <em>Select district</em>
                                </MenuItem>
                                {handleStateChange&&<MenuItem value={otherViewLaboratoryData.district}>
                                          {otherViewLaboratoryData.districtName}
                                        </MenuItem>}
                                {otherDistrictOptions}
                                </Select>
                            </Grid>
                            <Grid md={6} item sx={{mt:2}}  >
                                <FormLabel>City</FormLabel>
                                <Select 
                                disableUnderline
                                displayEmpty
                                className='selectOptions'
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={Object.keys(otherViewLaboratoryData).length !== 0&&otherViewLaboratoryData?.city}
                                placeholder="Select City"
                                {...register1('city',{required: true})}
                                
                                onChange={(e)=>{setOtherViewLaboratoryData({
                                    ...otherViewLaboratoryData,
                                    city:e.target.value,
                                    })
                                    otherCityChange(e)
                                    setHandleStateChange(false);
                                    setHandleCityChange(false)
                                }}
                                >
                                    
                                    <MenuItem value="">
                                        <em>Select City</em>
                                    </MenuItem>
                                    {handleDistrictChange&&<MenuItem value={otherViewLaboratoryData.city}>
                                          {otherViewLaboratoryData.cityName}
                                        </MenuItem>}
                                    {otherCityOptions}
                                </Select>
                            </Grid>
                         </Grid>
                        
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}} >
                                <Button className="customerLogin" type="submit" >Submit</Button>
                                <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loaderOpen}
                                onClick={handleClose}
                                invisible={true}
                            >
                                <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                                />
                            </Backdrop>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
  
        {/* End Edit Modal */}


    {/* approval Modal Open */}

        <Modal
      open={approvalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Approve?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(laboratoryId, 1)}>Yes</Button>
            <Button className="noButton"  onClick={approvalHandleClose}>No</Button>
          </Grid>
        </Box>
        </Modal>
        {/* Rejected Modal */}
      <Modal
        open={rejectOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Reject?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(laboratoryId, 2)}>Yes</Button>
            <Button className="noButton" onClick={rejectHandleClose}>No</Button>
          </Grid>
        </Box>
      </Modal>

       {/*START BLOCK MODAL*/}
  <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={approvalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to block ?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={()=>Block(laboratoryId, 1)}>Yes</Button>
                <Button className="noButton" onClick={handleCloseBlock}>No</Button>
            </Grid>
        </Box>
      </Modal>

    {/*END BLOCK MODAL*/}

    {/*START UNBLOCK MODAL*/}
    <Modal
        open={openunBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={approvalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to un-block ?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={()=>unBlock(laboratoryId, 2)}>Yes</Button>
                <Button className="noButton" onClick={handleCloseunBlock}>No</Button>
            </Grid>
        </Box>
    </Modal>
        

        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
       
        </>
    )
}