import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import Ambulance1 from '../../../Assets/icons/ambulance1.png'
import Typography from '@mui/material/Typography'
import DriverLocation from '../../../Assets/icons/driverlocation.svg'
import axios from 'axios'

const History=()=>{
    const [data , setData] = useState([])
    const [noBookings , setNoBookings] = useState(true)
    const [bookingDetails , setBookingDetails] = useState({
        name:"",
        ambType:"",
        ambNumber:"",
        hsptl:"",
        payMethod:"",
        price:"",
        from:"",
        to:""
    })
    const [selectedId,setSelectedId] = useState("")
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));

    useEffect(()=>{
      bookingHistory();
    },[])

    const bookingHistory = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}userBookHistory`,{userId:userlogCheck.id ,loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (response) => {
                if(response.data.status === 1){
                    const bookings = response.data.bookingData;
                    setData(bookings);
                    setNoBookings(true);
                    getBookingDetails(bookings[0]._id, bookings[0]);
                }else{
                    setNoBookings(false)
                }
            }
        ).catch((error) => {console.log(error)})
    }
    const getBookingDetails = (bookingId) => {
        setSelectedId(bookingId)
        axios.post(`${process.env.REACT_APP_BASE_URL}bookingDetails`,{bookingId:bookingId,userId:userlogCheck.id ,loginType:1 , type:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    let details = responce.data.bookingDetails;
                    if(details.hospitalName){
                        setBookingDetails({
                            ...bookingDetails,
                            name:details.driverName,
                            ambType:details.ambulanceType,
                            ambNumber:details.ambulanceNumber,
                            hsptl:details.hospitalName,
                            payMethod:details.paymentType,
                            price:details.price,
                            from:details.fromLocation,
                            to:details.toLocation,
                        })
                    }else{
                        setBookingDetails({
                            ...bookingDetails,
                            name:details.driverName,
                            ambType:details.ambulanceType,
                            ambNumber:details.ambulanceNumber,
                            hsptl:"",
                            payMethod:details.paymentType,
                            price:details.price,
                            from:details.fromLocation,
                            to:details.toLocation
                        })
                    }
                }
            }
        ).catch((error) => {console.log(error)})
    }

    const bookingBlock = data.map((row , index) => <Grid container justifyContent="space-between" alignItems="center" className={selectedId == row._id ? "cardActive":"historyCard" } key={row._id} sx={{mb:3}} onClick={()=>{getBookingDetails(row._id ,)}}>
        <Grid item md={1}>
            <img src={Ambulance1} alt="ambulance"/>
        </Grid>
        <Grid sx={{wordBreak:"break-all"}} item md={4} >
            <Typography fontWeight={700} className='bookingHeading'>{row.driverName}</Typography>
            {/* <Typography fontSize="14px" color="#979899" fontWeight={700}>ID:{row._id}</Typography> */}
        </Grid>
        <Grid item>
            <Typography color="#129547" fontWeight={700}>{row.ambulanceType}</Typography>
            <Typography fontSize="14px" color="#979899" fontWeight={700}>{row.bookTime}</Typography>
        </Grid>
    </Grid>
       
    );

    return(
    <>
    <Grid className='aboutBg' py={5}>
        <Container className="widthSet">
        <Grid sx={{mt:5,mb:3}} component="h2" className='bookingHeading'>History</Grid>
        {noBookings?
            <Grid container mt={2} mb={2}>
                <Grid item md={4} xs={12} sm={12} className="historydata" sx={{maxHeight:'367px',overflowY:"auto",paddingRight:"20px"}}>
                    {bookingBlock}
                </Grid>
                <Grid item md={8} xs={12} pl={4} className="historypage">
                    <Grid container className="databg">
                        <Grid item md={6} xs={12}>
                            <Grid container md={12} xs={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography  fontSize="14px"color="#979899" >Driver name</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px" className='bookingHeading' >{bookingDetails.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container md={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography fontSize="14px" color="#979899" >Ambulance type</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px" className='bookingHeading'>{bookingDetails.ambType}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container md={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography fontSize="14px" color="#979899">Ambulance number</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px" className='bookingHeading'>{bookingDetails.ambNumber}</Typography>
                                </Grid>
                            </Grid>
                            {bookingDetails.hsptl!==""&&<Grid container md={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography fontSize="14px" color="#979899">Hospital</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px"  className='bookingHeading'>{bookingDetails.hsptl}</Typography>
                                </Grid>
                            </Grid>}
                            {/* <Grid container md={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography fontSize="14px">Payment method</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px" color="#979899">{bookingDetails.payMethod}</Typography>
                                </Grid>
                            </Grid> */}
                            <Grid container md={12} mb={2}>
                                <Grid item md={6} xs={6}>
                                    <Typography fontSize="14px" color="#979899">Amount</Typography>
                                </Grid>
                                <Grid item md={4} xs={6} sx={{wordBreak:'break-all'}}>
                                    <Typography fontSize="14px" className='bookingHeading'>{bookingDetails.price}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Grid item container sx={{mt:1}}>
                                <Grid item md={2} xs={1} className='locationImg'>
                                    <img src={DriverLocation} alt="driver" height='100%'/>
                                </Grid>
                                <Grid item md={10} xs={11}>
                                    <Grid item >
                                        <Typography fontSize="14px" color="#979899">Pick up from</Typography>
                                        <Typography className='bookingHeading' sx={{wordBreak:'break-all'}}>{bookingDetails.from}</Typography>
                                    </Grid>
                                    <Grid item sx={{mt:2}}>
                                        <Typography fontSize="14px" color="#979899">Drop at</Typography>
                                        <Typography className='bookingHeading' sx={{wordBreak:'break-all'}}>{bookingDetails.to}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>:<Typography className='noBookingAvailable bookingHeading'>No Bookings Available</Typography>}
            
        </Container>
    </Grid>
    </>
    )
}

export default History