import React from 'react'
import {  Navigate } from "react-router-dom";

const EmployeePrivateRoutes=({component:Component , ...rest})=> {
    const user = JSON.parse(sessionStorage.getItem("userType"));
    if(user !== null && user.userType === "Employee"){
        return <Component {...rest} />
    }else if(user == null ){
        return <Navigate to="/EmployeesLogin" />
    }
}

export default EmployeePrivateRoutes