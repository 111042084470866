import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
import '../subAdmin.css'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import { useState } from "react";
import 'ui-neumorphism/dist/index.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useParams } from "react-router-dom";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';



const  SubadminCreatePassword = ()=> {
    let { email , token } = useParams();

    const [open, setOpen] = useState(false);
    const [sopen, setSopen] = React.useState(false);
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const[password , setPassword] =useState(false);
    const[neweyeIcon,setNeweyeIcon]=useState(true);
    const[checkeyeIcon,setCheckeyeIcon]=useState(true);
    const[newType,setNewType] =useState('password');
    const[checkType , setCheckType] = useState('password');
    
    function handleClose() {
        
        setOpen(false);
    }


    const  newHidePassword=()=>{
        setNeweyeIcon(false);
        setNewType('name')
    }
    const  checkHidePassword=()=>{
        setCheckeyeIcon(false);
        setCheckType('name')
    }

    const  newShowPassword=()=>{
        setNeweyeIcon(true);
        setNewType('password')
    }
    const  checkShowPassword=()=>{
        setCheckeyeIcon(true);
        setCheckType('password')
    }

    const {register,handleSubmit,formState: { errors }} = useForm();

    const navigate =  useNavigate();

    const onSubmit = async (data)=>{

        let inputValue = document.getElementById("check_password").value; 
        if(inputValue !== data.newPassword){
            setPassword(true);
        }else{
            if(password === true){
                setPassword(true);
            }
            axios.post(process.env.REACT_APP_BASE_URL+'saresetPassword/'+email+'/'+token,{password:data.newPassword},{headers:{Authorization:'4svfgbad254a1a264be4s022d9e24sd5'}}).then((res) => {
            console.log(res.data)
                let status = res.data.status;
                if(status === 1){
                    setOpen(true);
                        setTimeout(()=>{navigate('/subAdmin');}, 5000);
                        
                }else if(status === 0){
                    setSopen(true);
                }

            }).catch((error) => {console.log(error)})
         }     
        }

    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                    Reset Password
                </Typography>
            </Grid>
            <Grid container  justifyContent='center' lg={12}>
                <Grid container  justifyContent='center' lg={6}>
                     <Card className={"login_form"} >
                        <Grid container justifyContent='center'>
                            <Grid item sx={{mt:3}}  className={"parent_position"}>
                                <FormLabel sx={{color:"black"}}>New password</FormLabel>
                                <TextField type={newType} className={'text_field'} placeholder='Enter new password'{...register('newPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}> </TextField>
                                {errors.newPassword && errors.newPassword.type === "required" && <p className='error mt-0'>Please enter new password</p>}
                                {errors.newPassword && errors.newPassword.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                {neweyeIcon?<VisibilityOffOutlinedIcon onClick={newHidePassword} className={'eyeicon_position'} />:<VisibilityOutlinedIcon onClick={newShowPassword} className={'eyeicon_position'} />}
                                <Typography sx={{width: '345px', color:'#979899', fontSize:12}}>* Must contain one lowercase, uppercase, numeric and 
                                Special Characters with atleast 8 characters</Typography>
                             </Grid>
                          
                                <Grid item sx={{mt:3}} className={"parent_position"} >
                                    <FormLabel sx={{color:"black"}}>Confirm password</FormLabel>
                                    <TextField type={checkType} id="check_password" className={'text_field'} placeholder='Enter confirm password' > </TextField>
                                    {password && <p className='error mt-0'>Password missmatch</p>}
                                    {checkeyeIcon?<VisibilityOffOutlinedIcon onClick={checkHidePassword} className={'eyeicon_position'} />:<VisibilityOutlinedIcon onClick={checkShowPassword} className={'eyeicon_position'} />}
                            </Grid>
                        </Grid>
                        
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button  className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained' onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="snackBar">
                Password updated successfully
                </Alert>
            </Snackbar>
            <Snackbar open={sopen} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" className="snackError">
                something went wrong!
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default SubadminCreatePassword;