import React,{useState} from "react";
import Logo from '../../../Assets/icons/header_logo.svg'
import '../../../Styles/styles.css'
import { Container } from "@mui/system";
import { Grid,TextField, Card,FormLabel,Typography,Snackbar,Alert } from "@mui/material";
import { Button } from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from 'react-hook-form';

const LaboratoryLogin =() => {

    const[eyeIcon,seteyeIcon]=useState(true);
    const[type,setType] =useState('password');
    const[vertical] =useState('top');
    const[horizontal] =useState('right');

    const [open, setOpen] = React.useState(false);
    const {register,handleSubmit,formState: { errors }} = useForm();
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const handleClose = () => {
       setOpen(false);
    }
    const ErrorClose = () => {
        setEmessage(false)
    }
    const SuccessClose=() => {
        setSmessage(false);
    }


    const hidePassword = ()=> {
        seteyeIcon(false);
        setType('name')
    }

       const showPassword=()=>{
           seteyeIcon(true);
           setType('password')
       }

       const navigate =  useNavigate();
       
       const onSubmit = async (data)=>{
        
        axios.post(`${process.env.REACT_APP_BASE_URL}laboratoryLogin`,{email:data.email , password:data.password},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
               if(status === 1){
                setSuccessMsg(res.data.message)
                setSmessage(true)
                    let userDetails = {'userType':'Laboratory','id':res.data.resData.userId,'type':res.data.resData.type,'name':res.data.resData.name,'discount':res.data.resData.discount};
                    sessionStorage.setItem("userType", JSON.stringify(userDetails)); 
                    navigate('/laboratory/Customers');
                    
                }else if(status === 0){
                    console.log("hi")
                    setOpen(true);
                    setErrorMsg(res.data.message)
                    setEmessage(true)
                }
            }).catch((error) => {
                console.log(error)})
       }
    
    return (
        <Container className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                    Labouratory Login
                </Typography>
            </Grid>
            <Grid container item  justifyContent='center' lg={12}>
                <Grid container item  justifyContent='center' lg={6}>
                    <Card className={"login_form"}>
                        <form>
                            <Grid container justifyContent='center' sx={{mt:3}}>
                                <Grid item md={8} sx={{mt:2}}  >
                                    <Grid item>  
                                        <FormLabel sx={{color:"black"}}>Email ID</FormLabel>
                                        <TextField name="email" className={'text_field'}  {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></TextField>
                                        {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                        {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                                    </Grid>
                                    <Grid item className={"parent_position"} >
                                        <FormLabel sx={{color:"black"}}>Password</FormLabel>
                                        <TextField name="password" type={type} className={'text_field'} {...register('password',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}> </TextField>
                                        {errors.password && errors.password.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                        {errors.password && errors.password.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                        {eyeIcon?
                                        <VisibilityOffOutlinedIcon onClick={hidePassword} className={'eyeicon_position'} />:
                                        <RemoveRedEyeOutlinedIcon onClick={showPassword} className={'eyeicon_position'} />}
                                        <Typography sx={{cursor:'pointer'}}  gutterBottom component="div" color="#E11F26" >
                                            <span onClick={() => {navigate('/Laboratory_forgot_password');}}>Forgot Password?</span> 
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <Grid item sx={{my:5}} >
                                            <Button className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained' onClick={handleSubmit(onSubmit)}>Login</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
    </Container>
    )
}

export default LaboratoryLogin;