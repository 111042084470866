import React, { useEffect, useState } from "react";
import { 
    Select, 
    MenuItem, 
    Container, 
    Grid, 
    FormLabel,
    Snackbar,
    Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export const UserPharmacy = () => {
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [states , setStates] = useState([]);
    const [cities , setCities] = useState([]);
    const [districts , setDistricts] = useState([]);
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const [userPharmacyList, setUserPharmacyList] = useState([]);
    const [smessage, setSmessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [emessage, setEmessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const navigate =  useNavigate();

    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);

    const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:userlogCheck?.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
        }
    const statehandleChange = (event) => {
        setState(event.target.value);
        setDistrict("");
        setCity("");
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:userlogCheck?.id,stateId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                }else{
                    setDistricts([])
                }
            }
        ).catch((error) => {console.log(error)})
     };
     const districthandleChange = (event) => {
        setDistrict(event.target.value);
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:userlogCheck?.id,districtId:event.target.value},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setCities(responce.data.result)
                }else{
                    setCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    };
    
    const cityhandleChange = (event) => {
        setCity(event.target.value);
    };
     const stateOptions = states.map(menuItem =><MenuItem className='stateoptions' key={menuItem._id} value={menuItem._id}>{menuItem.state}</MenuItem>)
     const DistrictOptions = districts.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.district}</MenuItem>)
     const citiesOptions = cities.map(menuItem =><MenuItem key={menuItem._id} value={menuItem._id}>{menuItem.city}</MenuItem>)

     const gettingUserPharmacyList = () => {
        const payload = {
            userId: userlogCheck?.id,
        }
        if (state !== '') {
            payload.state = state
        }
        if (district !== '') {
             payload.district = district
        }
        if (city !== '') {
             payload.city = city
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}getPharmacyList`,payload, {headers:{Authorization:localStorage.getItem("Key")}})
            .then(response => {
                setUserPharmacyList(response.data.pharmaciesList);
            })
            .catch((error) => {if(error.response.status === 401){
                setErrorMessage("Your acoount was logged in from another device")
                setEmessage(true)
                setTimeout(() => {
                    userLogOut();
                }, 2000);}})
     }

     const TotalPharmacyList = userPharmacyList.map((list) => 
       <Grid container justifyContent={'space-between'} md={6} xs={12} pt={4} className="listItemBorder">
            <Grid item md={4} xs={4}>
                <Grid className="listText">{list.name}</Grid>
            </Grid>
            <Grid item md={4}  xs={4}>
                <Grid className="listText">{list.branch}</Grid>
            </Grid>
            <Grid item md={4}  xs={4}>
                <Grid className="listText">{list.address}</Grid>
            </Grid>
        </Grid>
           
       
        
    )
     const userLogOut = () =>{
        sessionStorage.removeItem("userType");
        localStorage.removeItem("latitude");
        localStorage.removeItem("longitude");
        navigate('/homepage');
        window.location.reload();
    }

     useEffect(() => {
        allStates()
    },[])
     useEffect(() => {
        gettingUserPharmacyList() 
     },[])
     useEffect(() => {
        gettingUserPharmacyList()
     },[state,district,city])
    return (
    <>
        <Grid className='aboutBg' py={5}>
            <Container className="widthSet"> 
                <Grid component={'h2'} pb={3} className="subscriptionText">Pharmacy</Grid>
                <Grid container md={6} gap={2}>
                    <Grid md={3.5}>
                        <FormLabel className="userFormLabel">State</FormLabel>
                        <Select 
                        fullWidth 
                        className='userSelectOption userSelectWidth'
                        value={state}
                        displayEmpty
                        disableUnderline
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={statehandleChange}
                        sx={{width:'100%'}}
                        >
                            <MenuItem disabled className='optoins' value="">
                                <em>Select State</em>
                            </MenuItem>
                            {stateOptions}
                        </Select>
                    </Grid>
                    <Grid md={3.5}>
                        <FormLabel className="userFormLabel" >District</FormLabel>
                        <Select 
                        fullWidth 
                        className='userSelectOption userSelectWidth'
                        value={district}
                        displayEmpty
                        disableUnderline
                        inputProps={{'aria-label': 'Without label'}}
                        onChange={districthandleChange}
                        >
                            <MenuItem value="">
                                <em>Select District</em>
                            </MenuItem>
                            {DistrictOptions}
                        </Select>
                    </Grid>
                    <Grid md={3.5}>
                        <FormLabel className="userFormLabel">City</FormLabel>
                        <Select 
                        fullWidth 
                        className='userSelectOption userSelectWidth'
                        value={city}
                        displayEmpty
                        disableUnderline
                        inputProps={{'aria-label': 'Without label'}}
                        onChange={cityhandleChange}
                        >
                            <MenuItem value="">
                                <em>Select City</em>
                            </MenuItem>
                            {citiesOptions}
                        </Select>
                    </Grid>
                </Grid>
                <Grid pt={5}>
                    <Grid component={'h4'}  pb={3} className="subscriptionText">Pharmacy List</Grid>
                    {TotalPharmacyList.length>0 ?<Grid container md={6} xs={12} justifyContent={"space-between"}>
                        <Grid item md={4} xs={4}>
                            <Grid className="listHeading">Pharmacy Name</Grid>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Grid className="listHeading">Branch</Grid>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Grid className="listHeading">Address</Grid>
                        </Grid>
                    </Grid>:<Grid container md={12} >
                        <Grid md={6} className="noDataFoundText">No Data Found</Grid>
                        </Grid>}
                    {TotalPharmacyList} 
                    
                </Grid>

                

            </Container>
        </Grid>
        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
              {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert className="snackError" onClose={ErrorClose} severity="error" >
              {errorMessage}
            </Alert>
        </Snackbar>
    </>
    )
}