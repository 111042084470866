import axios from "axios";


const savedAddressServices = {
    getSavAddresses: async (addPageNum , addPageSize ,id) => {
        return await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}getSavedLocList/${addPageNum}/${addPageSize}`,
          data:{
            userId:id
          },
          headers: {
            Authorization: localStorage.getItem("Key"),
          },
        })
          .then((response) => { 
            if (response.data.status === 1) {
              return response.data.details;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    addSavedLoc: async (Id , title ,address , latitude , longitude) => {
        return await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}addSavedLoc`,
          data:{
            userId:Id,
            title:title,
            address:address,
            latitude:latitude,
            longitude:longitude,
          },
          headers: {
            Authorization: localStorage.getItem("Key"),
          },
        })
          .then((response) => {
            if (response) {
              return response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deleteSavedLoc: async (Id , savedLocId) => {
        return await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}deleteSavedLoc`,
          data:{
            userId:Id,
            savedLocId:savedLocId,
          },
          headers: {
            Authorization: localStorage.getItem("Key"),
          },
        })
          .then((response) => {
            if (response) {
              return response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
}

export default savedAddressServices;