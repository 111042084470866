import React from "react";
import { Outlet } from "react-router-dom";

const AdminBooking=()=>{
    return(
        <>
         <Outlet/>
        </>
    )
}

export default AdminBooking