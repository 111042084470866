import React from 'react'
import {  Navigate } from "react-router-dom";

const HospitalPrivateRoutes=({component:Component , ...rest})=> {
    const user = JSON.parse(sessionStorage.getItem("userType"));
    if(user !== null && user.userType === "Hospital"){
        return <Component {...rest} />
    }else if(user == null ){
        return <Navigate to="/HospitalLogin" />
    }
}

export default HospitalPrivateRoutes