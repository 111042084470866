import React from 'react';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FooterLogo from '../../../Assets/icons/footerlogo.png';
import MailIcon from '../../../Assets/icons/mail.svg';
import PhoneIcon from '../../../Assets/icons/phone.svg';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect , useState } from 'react'

const CustomerFooter = () => {
   
    const navigate = useNavigate()  

    return(
        <>
            <Grid sx={{p:1}} className='footer_bg'>
                <Container className="widthSet">
                    <Grid container direction="row" style={{cursor:"default"}} justifyContent="space-between" alignItems="center">
                        <Grid item className='footerresponsive'>
                            <img src={FooterLogo} alt="Logo" className='footerlogo'/>
                        </Grid>
                        <Grid item>
                            <Grid>
                                <ListItem sx={{cursor:'pointer'}} component="div" disablePadding>
                                    <ListItemText align="center" className='footerList' onClick={() => {navigate('homepage')} } primary="Home"/>
                                </ListItem>
                                <ListItem sx={{cursor:'pointer'}} component="div" disablePadding>
                                    <ListItemText align="center" className='footerList' onClick={() => {navigate('AboutUs')}} primary="About Us"/>
                                </ListItem>
                                <ListItem sx={{cursor:'pointer'}} component="div" disablePadding >
                                    <ListItemText align="center" className='footerList' onClick={() => {navigate('Terms')}}  primary="Terms & Conditions"/>
                                </ListItem>
                                <ListItem sx={{cursor:'pointer'}} component="div" disablePadding>
                                    <ListItemText align="center" className='footerList' onClick={() => {navigate('PrivacyPolicy')}}  primary="Privacy & Policy"/>
                                </ListItem>
                                <ListItem sx={{cursor:'pointer'}} component="div" disablePadding>
                                    <ListItemText align="center" className='footerList' onClick={() => {navigate('ContactUs')}}  primary="Contact Us"/>
                                </ListItem>
                            </Grid>
                        </Grid>
                        <Grid item component="div"> 
                            <Grid item component="h4" sx={{fontSize:'12px'}}>Contact Us </Grid>
                            <Grid item>
                                <ListItem component="div" disablePadding>
                                    <img src={MailIcon} alt="mailIcon" style={{paddingRight:10}} />
                                    <a className='linktext' href="mailto:goambeeemergency@gmail.com">
                                        <ListItemText className='footerList' align="center" primary="goambeeemergency@gmail.com" />
                                    </a>

                                </ListItem>
                                <ListItem component="div" disablePadding>
                                    <img src={PhoneIcon} alt="phoneIcon" style={{paddingRight:10}} />
                                    <a className='linktext ' href="tel:+91 9632589512"><ListItemText className='footerList' align="center" primary="+91 9063445666"/></a>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{fontSize:'12px',m:2}} component="div" align="center">
                        Copyright © 2021 GoAmbee. All rights reserved.
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default CustomerFooter