import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
import './login.css'
import '../../../Styles/styles.css'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import { useState } from "react";
import 'ui-neumorphism/dist/index.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useParams } from "react-router-dom";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


const  AdminCreatePassword = ()=> {
    let { email , token } = useParams();

    const [open, setOpen] = useState(false);
    const [sopen, setSopen] = useState(false);
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const[password , setPassword] =useState(false);
    const[successMsg , setSuccessMsg] = useState("");
    const[errorMsg , setErrorMsg] = useState("");
    const[eyeIcon,seteyeIcon]=useState(true);
    const[neweyeIcon,setNeweyeIcon]=useState(true);
    const[type,setType] =useState('password');
    const[newType,setNewType] =useState('password');
    const[checkPassword , setCheckPassword]=useState(false);
    
    function handleClose() {
        
        setOpen(false);
    }

    const {register,handleSubmit,formState: { errors }} = useForm();

    const navigate =  useNavigate();
    const  hidePassword=()=>{
        seteyeIcon(false);
        setType('name')
    }

    const showPassword=()=>{
        seteyeIcon(true);
        setType('password')
    }

    const newHidePassword=()=>{
        setNeweyeIcon(false);
        setNewType('name')
    }

    const newShowPassword=()=>{
        setNeweyeIcon(true);
        setNewType('password')
    }

    const onSubmit = async (data)=>{

        let inputValue = document.getElementById("check_password").value; 
        if(inputValue === ""){
            setCheckPassword(true)
        }else if(inputValue !== data.newPassword){
            setCheckPassword(false)
            setPassword(true);
        }else{
                setPassword(false);
                
            axios.post(process.env.REACT_APP_BASE_URL+'resetPassword/'+email+'/'+token,{password:data.newPassword},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            console.log(res.data)
                let status = res.data.status;
                if(status === 1){
                    setSuccessMsg(res.data.message)
                    setOpen(true);
                        setTimeout(()=>{navigate('/admin');}, 5000);
                        
                }else if(status === 0){
                    setErrorMsg(res.data.message)
                    setSopen(true);
                }
                
            }).catch((error) => {console.log(error)})
         }     
        }

    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>
            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                    Reset Password
                </Typography>
            </Grid>
            <Grid container justifyContent='center' lg={12}>
                <Grid container justifyContent='center' lg={6}>
                    <Card className={"login_form"}>
            
                        <Grid container justifyContent='center'>
                            <Grid item sx={{mt:3}} className={"parent_position"} >
                                <FormLabel sx={{color:"black"}}>New password</FormLabel>
                                <TextField type={newType} className={'text_field'} placeholder='Enter new password'{...register('newPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}> </TextField>{neweyeIcon?
                                <VisibilityOutlinedIcon onClick={newHidePassword} className={'eyeicon_position'} />:
                                <VisibilityOffOutlinedIcon onClick={newShowPassword} className={'eyeicon_position'} />}
                                {errors.newPassword && errors.newPassword.type === "required" && <p className='error mt-0'>Please enter new password</p>}
                                {errors.newPassword && errors.newPassword.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                <Typography sx={{width: '345px', color:'#979899', fontSize:12}}>* Must contain one lowercase, uppercase, numeric and 
                                Special Characters with atleast 8 characters</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{mt:3}}  className={"parent_position"}>
                                <FormLabel sx={{color:"black"}}>Confirm password</FormLabel>
                                <TextField type={type} id="check_password" className={'text_field'} placeholder='Enter confirm password' > </TextField>
                                {password && <p className='error mt-0'>Password mismatch</p>}
                                {checkPassword && <p className='error mt-0'>Please enter confirm Password</p>}
                                {eyeIcon?
                                <VisibilityOutlinedIcon onClick={hidePassword} className={'eyeicon_position'}/>:
                                <VisibilityOffOutlinedIcon onClick={showPassword} className={'eyeicon_position'}/>}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:5}} >
                                <Button  className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained' onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                 {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={sopen} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMsg}
            </Alert>
        </Snackbar>
    </Container>
    )
}

export default AdminCreatePassword;