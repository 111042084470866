import React from "react";
import { Outlet } from "react-router-dom";

const Ambulance=()=>{
    return(
        <>
          <Outlet/>
        </>
    )
}

export default Ambulance