import React,{useEffect, useState} from "react"
import { Grid,Button,Typography,Snackbar,Alert } from "@mui/material"
import Container from '@mui/system/Container';
import axios from "axios";
import RazorpayPayment from "../../../Components/RazorpayPayament/razorpayPayment";
export const SubscriptionPlan = () => {
    const [planDetails, setPlanDetails] = useState([]);
    const [logCheck , setLogCheck] = useState("")
    const [logError , setLogError] = useState(false);
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const authKey = localStorage.getItem("Key");
    const userPlans = JSON.parse(sessionStorage.getItem("userSubscriptionDetails"));
    const [secureKey , setSecureKey] = useState("")
    const [vertical] =useState('top');
    const [horizontal] =useState('right');
    
    const showLogInMsg = () => {
        logCheck && setLogError(true)
    }
    const logErrorhandleClose = () => {
        setLogError(false)
    }
    const GetPlanDetails = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}planDetails`, { headers: { Authorization: process.env.REACT_APP_AUTHORIZATION } })
            .then(response => {
                setPlanDetails(response.data.data);
            })
            .catch(error => {
                console.error("Error fetching plan details:", error);
            });
    };

    const OrderBuy = (plan,type) => {
        const payment = RazorpayPayment.handlePayment(plan,type)
        return payment
    }

    const TotalPlanDetails = planDetails && planDetails.length > 0 && (
        planDetails.map((plan) => {
            // Define a function to map plan properties to background gradients
            const getBackgroundGradient = (property) => {
                switch(property) {
                    case 'GOLD':
                        return 'linear-gradient(95.47deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%)';
                    case 'SILVER':
                        return 'linear-gradient(95.47deg, #717171 -0.22%, #7B7B7B 10.66%, #CBCBCB 26.65%, #D1D1D1 78.95%, #818181 93%, #9B9B9B 97.24%)';
                    case 'BRONZE':
                        return 'linear-gradient(95.47deg, #D66D0B -0.22%, #E9770C 17.32%, #FAC39C 53.92%, #FACAA8 71.98%, #F26E0F 86.86%, #F58B40 97.24%)';
                    default:
                    return ''; 
                }
            };
            const isPlanPurchased = userPlans && userPlans.map(up => up.planName).includes(plan.planName);
            const isSilverPurchased = userPlans && userPlans.map(up => up.planName).includes('SILVER');
            const isBronzePurchased = userPlans && userPlans.map(up => up.planName).includes('BRONZE');
            return (
                <Grid item md={4} pb={5} key={plan._id}>
                    <Grid md ={12} className="AdminitemBoxShadow" >
                        <Grid container p={3} style={{background: getBackgroundGradient(plan.planName)}} alignItems={'center'}>
                            <Grid item md={6}>
                                <Typography variant="h2" className={'planName'}>{plan.planName}</Typography>
                                <Typography variant="h2" className={'planText'}>Plan</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="h2" className={'planName'} sx={{ textAlign: 'center' }}>Rs.{plan.amount}</Typography>
                            </Grid>
                        </Grid>
                        <Grid className="AdminOrderList">
                            <Grid sx={{wordBreak:'break-all',whiteSpace:'pre-wrap'}}>
                                {plan.description}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                    {logCheck ? (
                        <Button className='userBuyButton' size="medium" onClick={showLogInMsg}>Buy</Button>
                        ) : (!isPlanPurchased && !isSilverPurchased && !isBronzePurchased) ? (
                            <Button className='userBuyButton' size="medium" onClick={()=>{OrderBuy(plan ,1)}}>Buy</Button>
                        ) : (plan.planName === 'SILVER' && isSilverPurchased) ? (
                            null
                        ) : (plan.planName === 'BRONZE' && isBronzePurchased) ? (
                            null // Removing the bronze buy button
                        ) : (plan.planName === 'BRONZE' && isSilverPurchased) ? (
                            <Button className='userBuyButton buttonDisabled' size="medium" disabled>Buy</Button> 
                        ) : (plan.planName === 'BRONZE' && !isBronzePurchased) ? (
                            <Button className='userBuyButton' size="medium" onClick={()=>{OrderBuy(plan ,1)}}>Buy</Button> 
                        ) : (
                            <Button className='userBuyButton' size="medium" onClick={()=>{OrderBuy(plan ,1)}}>Buy</Button> 
                        )}
                    </Grid>
                </Grid>
            );
        })
    );
    
    const logging = () =>{
        if(userlogCheck !== null){
            setLogCheck(false);
            if(authKey !== null){
              setSecureKey(authKey);
            }
        }else{
            setLogCheck(true);
        }
    }

    useEffect(() => {
        GetPlanDetails();
        logging();
    }, []);

    
    return(
        <>
            <Grid className='aboutBg' py={5}>
                <Container className="widthSet"> 
                    <Grid component={'h2'} pb={3} className="subscriptionText">Subscription Plans</Grid>
                    <Grid container spacing={3}>
                       {planDetails ? TotalPlanDetails:
                       <Typography className="noDataText">No Subscription Plans</Typography>
                       }
                    </Grid>
                </Container>
            </Grid>
            <Snackbar open={logError} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }}   onClose={logErrorhandleClose} >
                <Alert onClose={logErrorhandleClose} severity="error" className="snackError">
                    Please login for buying
                </Alert>
            </Snackbar>
        </>
    )
}